import {Record} from 'react-admin';
import {CommunityRoleType, ICommunityMember} from '../interfaces';

export const mapCommunityMember = (record: Record): ICommunityMember => {
  const roles: CommunityRoleType[] = ['MEMBER'];
  if (record.is_admin) roles.push('COMMUNITY_ADMIN');

  return {
    ...record,
    roles,
  } as ICommunityMember;
};
