/* eslint-disable react/prop-types */
import React, {FC} from 'react';

import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

const BIC_ICON_CLASS_NAME_DICTIONARY = {
  '6px': 'w-1.5 h-1.5',
  '8px': 'w-2 h-2',
  '10px': 'w-2.5 h-2.5',
  '12px': 'w-3 h-3',
  '16px': 'w-4 h-4',
  '18px': 'w-[18px] h-[18px]',
  '20px': 'w-5 h-5',
  '22px': 'w-[22px] h-[22px]',
  '24px': 'w-6 h-6',
  '28px': 'w-7 h-7',
  '32px': 'w-8 h-8',
  '40px': 'w-10 h-10',
  '56px': 'w-14 h-14',
};

export type IconSizeProp = keyof typeof BIC_ICON_CLASS_NAME_DICTIONARY;
export type IconName = IconDefinition;

export interface IconProps
  extends Omit<
    FontAwesomeIconProps,
    'icon' | 'name' | 'size' | 'width' | 'height'
  > {
  /**
   * Name of the icon, our icon or icon from Font Awesome
   * @example
   * // use our bein icon
   * <Icon name="BeinApp" />
   *
   * @example
   * // use Font Awesome
   * import {faFontAwesome} from '@fortawesome/pro-regular-svg-icons'
   * const icon = <Icon name={faFontAwesome} />
   */
  name: IconName;

  /**
   * To change icon's size. If you want custom one, pass in `'custom'`
   */
  size?: IconSizeProp | 'custom';

  /**
   * @override
   * To change color of the icon file if needed, e.g: 'fill-neutral-80', 'stroke-neutral-80'
   * - If using FontAwesome, you can set it directly in className prop
   * - If the icon use both stroke and fill, please pass 'original' to keep the original color
   * - If it doesn't work, recheck in that SVG file
   * and remove fill or stoke in path tag
   *
   * @example
   * // Using stroke. Apply to icon in ICON_USE_STROKE
   * <Icon name="BeinApp" color="stroke-neutral-80" />
   * // Using fill
   * <Icon name="BeinApp" color="fill-neutral-80" />
   * // Using FontAwesomeIcon, both ways work
   * import {faFontAwesome} from '@fortawesome/pro-regular-svg-icons'
   * const icon0 = <Icon name={faFontAwesome} className="text-pink-500" />
   * const icon1 = <Icon name={faFontAwesome} color="text-pink-500" />
   */
  color?: string;

  /**
   * Should Icon have default padding of 1px?
   * If yes, then set to `true`
   * If no, then pass in nothing or undefined
   * Or you can set to custom tailwind class name, e.g: 'p-0.5'
   */
  padding?: true | string;
}

const Icon: FC<IconProps> = ({
  className = '',
  name,
  size = '16px',
  color,
  spin = false,
  padding = '',
  ...rest
}) => {
  return (
    <FontAwesomeIcon
      icon={name}
      className={clsx([
        size !== 'custom' && `${BIC_ICON_CLASS_NAME_DICTIONARY[size]}`,
        className,
        color,
        typeof padding === 'string' ? padding : 'p-px',
        rest.onClick && 'cursor-pointer',
      ])}
      spin={spin}
      {...rest}
    />
  );
};

export default Icon;
