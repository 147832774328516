import React from 'react';
import SortableTree from '@nosferatu500/react-sortable-tree';
import {ReactSortableTreeProps} from '@nosferatu500/react-sortable-tree/react-sortable-tree';

import {IGroupTree} from '../interfaces/IGroup';
import GroupNameField from './GroupNameField';
import CommunityNameField from '../communities/CommunityNameField';

interface GroupTreeProps extends ReactSortableTreeProps {
  clickableNode?: boolean;
  hideBorder?: boolean;
}

const GroupTree = ({clickableNode, ...props}: GroupTreeProps): JSX.Element => {
  const generateNodeProps = ({node}: {node: IGroupTree}) => {
    const NameField = node.level === 0 ? CommunityNameField : GroupNameField;

    return {
      title: (
        <div style={{fontWeight: 'normal'}}>
          <NameField record={node} clickable={clickableNode} />
        </div>
      ),
      style: {
        height: 52,
      },
    };
  };

  return <SortableTree generateNodeProps={generateNodeProps} {...props} />;
};

export default GroupTree;
