import React from 'react';
import {FieldProps} from 'react-admin';
import PropTypes from 'prop-types';

import {IGroup} from '../interfaces/IGroup';
import GroupNameField from './GroupNameField';

const GroupTitle: React.FC<FieldProps<IGroup>> = ({record}) => {
  if (!record) return null;

  return record ? <GroupNameField record={record} size={32} /> : null;
};

GroupTitle.propTypes = {
  record: PropTypes.any,
};

export default GroupTitle;
