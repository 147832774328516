import React, {ReactNode} from 'react';

import {EditorState} from 'draft-js';
import {extractLinkFromText} from './draftjsEditor';

export interface AnchorComponentProps {
  children: ReactNode;
  getEditorState: () => EditorState;
  entityKey: string;
}

export default function AnchorComponent(props: AnchorComponentProps) {
  const {children, getEditorState, entityKey} = props;

  const entity = getEditorState().getCurrentContent().getEntity(entityKey);
  const entityData = entity ? entity.getData() : undefined;
  const url = (entityData && entityData.url) || undefined;
  const verifiedUrls = extractLinkFromText(url, {fuzzyLink: true});
  const verifiedHref = verifiedUrls && verifiedUrls[0]?.url;

  if (!verifiedHref) return <span>{children}</span>;
  return (
    <a
      style={{
        color: 'rgb(0, 122, 255)',
        fontWeight: 400,
        textDecoration: 'underline',
      }}
      title={verifiedHref}
      href={verifiedHref}
      target="_blank"
      rel="noopener noreferrer">
      {children}
    </a>
  );
}
