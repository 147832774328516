/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useRef, useState} from 'react';
import {UPLOAD_INTERVAL_DURATION_IN_MILLISECONDS} from '../common/constants';
import {FAILED_COUNT_LIMIT} from '../constants';
import {UploadCsvUrl} from '../constants/upload';
import {CsvProcessStatus, CsvUploadMessageResponse} from '../interfaces';
import {importType} from '../massUpload/common';
import {httpClient} from '../utils';

export const useListenToUploadingCsvProgress = (
  type: importType,
): {
  data: CsvUploadMessageResponse[];
  progress: number;
  isListening: boolean;
  startListening: (uploadId: string) => void;
  stopListening: () => void;
  doesSupportListening: boolean;
} => {
  const [isListening, setIsListening] = useState(false);
  const [numOfFailedFetches, setNumOfFailedFetches] = useState(0);

  const [progress, setProgress] = useState(0);
  const [data, setData] = useState<CsvUploadMessageResponse[]>([]);
  const intervalListenerRef: {current: NodeJS.Timeout | null} = useRef(null);
  const doesSupportListening = ['members', 'membersToCom'].includes(type);
  const mapNewDataToState = (newData: CsvUploadMessageResponse[]) => {
    setData(newData);

    const latestRow = newData[newData.length - 1];
    const latestProgress = latestRow.processed_percent;
    setProgress(latestProgress);
  };

  const fetchUploadData = async (uploadId: string) => {
    const uploadUrl = `${UploadCsvUrl.members}/${uploadId}`;
    return httpClient(uploadUrl, {
      method: 'GET',
    })
      .then(json => {
        const newData = JSON.parse(json.body).data as
          | CsvUploadMessageResponse[]
          | undefined;
        if (newData) {
          mapNewDataToState(newData);
        }
      })
      .catch(() => setNumOfFailedFetches(prevCount => prevCount + 1));
  };

  const startListening = (uploadId: string) => {
    if (!doesSupportListening) return;
    setNumOfFailedFetches(0);
    setIsListening(true);

    // Init fetching
    fetchUploadData(uploadId);

    intervalListenerRef.current = setInterval(() => {
      fetchUploadData(uploadId);
    }, UPLOAD_INTERVAL_DURATION_IN_MILLISECONDS);
  };

  const stopListening = () => {
    setIsListening(false);
    if (intervalListenerRef.current) {
      clearInterval(intervalListenerRef.current);
      intervalListenerRef.current = null;
    }
  };

  useEffect(() => {
    /**
     * WARNING: Do not put stop listening on unmount right in the below useEffect,
     * as it stops right away some how
     */
    return () => {
      stopListening();
    };
  }, []);

  useEffect(() => {
    const latestDataRow = data[data.length - 1];
    if (latestDataRow?.status === CsvProcessStatus.DONE) {
      stopListening();
    }
  }, [data]);

  useEffect(() => {
    if (numOfFailedFetches >= FAILED_COUNT_LIMIT) {
      stopListening();
    }
  }, [numOfFailedFetches]);

  return {
    data,
    progress,
    isListening,
    startListening,
    stopListening,
    doesSupportListening,
  };
};
