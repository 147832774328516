import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {required} from 'ra-core';
import {
  Box,
  Card,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Create,
  FileField,
  FileInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import CommunityReferenceInput from '../communities/CommunityReferenceInput';
import UserReferenceInput from '../users/UserReferenceInput';
import {importType, importRecord} from './common';
import {RESOURCES} from '../common/constants';
import LinearProgressWithLabel from '../components/LinearProgressWithLabel';
import {useListenToUploadingCsvProgress} from '../hooks';
import {CsvUploadError, IAnyObject} from '../interfaces';
import {getErrorsFromCsvUploadResponse} from '../utils/upload';
import CloseIcon from '@material-ui/icons/Close';

interface UploadToolbarProps extends IAnyObject {
  disabled?: boolean;
}
const UploadToolbar = ({
  disabled,
  ...rest
}: UploadToolbarProps): ReactElement => {
  return (
    <Toolbar {...rest}>
      <SaveButton disabled={disabled || !!rest.invalid} />
    </Toolbar>
  );
};

interface Props {
  type: importType;
  title?: string;
  onSuccess?: () => void;
  navigateToListOnSuccess?: boolean;
}

const ImportCard = ({
  type,
  title = ' ',
  onSuccess,
  navigateToListOnSuccess,
}: Props): JSX.Element => {
  const notify = useNotify();
  const redirect = useRedirect();

  const [fileName, setFileName] = useState('');
  const handleAddCsvFile = (fileList: File[]) => {
    setFileName(fileList[0].name);
  };

  type ErrorType = {
    line: number;
    message: string;
  };
  const [errorsOnUploading, setErrorsOnUploading] = useState<ErrorType[]>([]);

  const [hasAnyUploadingStarted, setHasAnyUploadingStarted] = useState(false);

  const {
    data: uploadingDataResponse,
    progress,
    isListening,
    startListening,
    stopListening,
    doesSupportListening,
  } = useListenToUploadingCsvProgress(type);
  const shouldDisableFormInteraction = doesSupportListening && isListening;

  const errorsOnImporting = useMemo<CsvUploadError[]>(
    () => getErrorsFromCsvUploadResponse(uploadingDataResponse),
    [uploadingDataResponse],
  );

  const _importRecord = importRecord[type];
  const basePath = _importRecord.basePath;
  const resource = _importRecord.resource;

  const description = `You may upload new members data in a CSV file. The data within must be
  written as below.`;

  useEffect(() => {
    return () => {
      setErrorsOnUploading([]);
    };
  }, []);

  const _onSuccess = (response: {data: {upload_id: string}}) => {
    const uploadId = response.data.upload_id ?? '';
    startListening(uploadId);
    setErrorsOnUploading([]);
    if (onSuccess) {
      onSuccess();
    }

    if (navigateToListOnSuccess) {
      const path = type === 'members' ? 'groups' : type;
      redirect(`/${path}`);
    }

    const message =
      type === 'members'
        ? 'The list is being uploaded'
        : `Import ${type} from CSV successfully`;
    const messageType = type === 'members' ? 'info' : 'success';
    notify(message, messageType);
  };

  const _onFailure = (error: {
    meta: {
      message: string;
      errors: ErrorType[] | null;
    };
  }) => {
    stopListening();
    if (Array.isArray(error.meta.errors)) {
      setErrorsOnUploading([...error.meta.errors]);
    }

    let message = error?.meta?.message;

    if (!message) {
      notify('Something wrong when uploading CSV file.', 'error');
      return;
    }

    if (message.includes('CSV')) {
      message = `${message}. Please check your CSV file.`;
    }
    notify(message, 'error');
  };

  const renderSampleTable = () => {
    const sampleData = _importRecord.data;

    return (
      <TableContainer component={Paper}>
        <Table aria-label="sample-table">
          <TableHead>
            <TableRow>
              {Object.keys(sampleData).map(key => (
                <TableCell key={key}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {Object.values(sampleData).map((val, index) => (
                <TableCell key={index}>{val}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderError = () => {
    if (!errorsOnUploading || errorsOnUploading.length === 0) return <></>;

    const errorMessages: string[] = [];
    errorsOnUploading.map(err => {
      const msg = `Line ${err.line}: ${err.message}`;
      errorMessages.push(msg);
    });

    return (
      <React.Fragment>
        {errorMessages.map((errMsg, index) => (
          <Typography
            key={`error_import_${index}`}
            color="error"
            variant="body2">
            {errMsg}
          </Typography>
        ))}
      </React.Fragment>
    );
  };

  const groupImportFields = [];
  if (type === 'groups') {
    const [selectedCommunityId, setSelectedCommunityId] = useState<number>(0);

    const onChangeCommunity = (value: number) => {
      setSelectedCommunityId(value);
    };

    groupImportFields.push(
      <CommunityReferenceInput
        resettable
        validate={required()}
        label="Community"
        source="community_id"
        reference={RESOURCES.COMMUNITIES}
        onChange={onChangeCommunity}
      />,
    );
    groupImportFields.push(
      <UserReferenceInput
        resettable
        validate={required()}
        label="Admin"
        source="admin_id"
        reference={RESOURCES.COMMUNITY_MEMBERS}
        filter={{communityId: selectedCommunityId}}
        perPage={50}
      />,
    );
  }

  const shouldRenderProgressCard =
    doesSupportListening && hasAnyUploadingStarted;
  const renderProgressCard = () => {
    const progressBar = (
      <Box width="100%">
        <LinearProgressWithLabel value={progress} color="secondary" />
      </Box>
    );

    return (
      <Card style={{marginTop: '16px'}}>
        <Box padding={2}>
          <Box marginBottom={1}>
            <Typography>Adding members</Typography>
          </Box>
          {progressBar}
        </Box>
      </Card>
    );
  };

  const shouldRenderImportingErrors = errorsOnImporting.length > 0;
  const renderImportingErrors = () => {
    return (
      <Card style={{marginTop: '16px', maxHeight: '300px', overflow: 'auto'}}>
        <Box padding={2}>
          {errorsOnImporting.map((errorRow, index) => {
            return (
              <Box
                key={`importing-error-${index}`}
                display="flex"
                alignItems="center"
                color="red">
                <CloseIcon />
                <Typography style={{marginLeft: '16px'}}>
                  {errorRow.row_id}
                </Typography>
                <Typography style={{marginLeft: '48px'}}>
                  {errorRow.message}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Card>
    );
  };

  return (
    <>
      <Typography gutterBottom>{description}</Typography>
      <Typography gutterBottom>
        Or{' '}
        <Link href={_importRecord.templateDownloadUrl} underline="hover">
          click here
        </Link>{' '}
        to download CSV template.
      </Typography>
      {renderSampleTable()}
      <Create
        basePath={basePath}
        resource={resource}
        title={title}
        transform={data => {
          setHasAnyUploadingStarted(true);
          return data;
        }}
        onSuccess={_onSuccess}
        onFailure={_onFailure}>
        <SimpleForm
          submitOnEnter={false}
          toolbar={<UploadToolbar disabled={shouldDisableFormInteraction} />}>
          <FileInput
            validate={required()}
            source="file"
            label="CSV file"
            accept=".csv"
            options={{
              disabled: shouldDisableFormInteraction,
              onDrop: handleAddCsvFile,
            }}>
            {shouldDisableFormInteraction ? null : (
              <FileField source="src" title="title" />
            )}
          </FileInput>
          {shouldDisableFormInteraction ? (
            <Typography style={{marginLeft: '48px'}}>{fileName}</Typography>
          ) : null}
          {groupImportFields}
          {renderError()}
        </SimpleForm>
      </Create>
      {shouldRenderProgressCard ? renderProgressCard() : null}
      {shouldRenderImportingErrors ? renderImportingErrors() : null}
    </>
  );
};

export default ImportCard;
