import {RefObject} from 'react';

import {useEventListener} from './useEventListener';

export function useClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
  mouseEvent: 'mousedown' | 'mouseup' | 'click' = 'mousedown',
  isRequireElement = false,
): void {
  useEventListener(mouseEvent, (event: MouseEvent) => {
    const el = ref.current;

    // Do nothing when clicking outside without ref's element
    if (isRequireElement && el?.contains?.(event.target as Node)) {
      return;
    }
    // Do nothing if clicking ref's element or descendent elements
    if (!isRequireElement && (!el || el.contains?.(event.target as Node))) {
      return;
    }

    handler(event);
  });
}
