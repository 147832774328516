import { useEffect } from "react";

export function useWindowUnloadPrevention({
    enabled,
    message = '',
    onAlert,
  }: {
    enabled?: boolean;
    message?: string;
    onAlert?: () => void;
  }) {
    useEffect(() => {
      const onBrowserReload = (e: BeforeUnloadEvent) => {
        e?.preventDefault();
        if (onAlert) {
          onAlert();
        }
        e.returnValue = message;
      };
  
      if (enabled) {
        window.addEventListener('beforeunload', onBrowserReload);
      }
  
      return () => {
        window.removeEventListener('beforeunload', onBrowserReload);
      };
    }, [enabled, message, onAlert]);
  }
  