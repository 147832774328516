import {maxLength, required} from 'react-admin';
import {GROUP_VALIDATION_RULE} from '../constants';
import {minLength} from './string';

export const groupValidators = {
  name: [
    required(),
    minLength(GROUP_VALIDATION_RULE.NAME.MIN_LENGTH),
    maxLength(GROUP_VALIDATION_RULE.NAME.MAX_LENGTH),
  ],
  slug: [
    minLength(GROUP_VALIDATION_RULE.SLUG.MIN_LENGTH),
    maxLength(GROUP_VALIDATION_RULE.SLUG.MAX_LENGTH),
  ],
  adminId: [required()],
  communityId: [required()],
  description: [maxLength(GROUP_VALIDATION_RULE.DESCRIPTION.MAX_LENGTH)],
};
