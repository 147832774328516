import {DataProvider as RaDataProvider} from 'ra-core';
import {ParsedUrlQueryInput, stringify} from 'querystring';
import {GetManyResult, Identifier, Record} from 'react-admin';

import {API_ADMIN_URL, API_USER_ADMIN_URL} from '../../constants';
import {RESOURCES} from '../../common/constants';
import {httpClient} from '../../utils';
import userProvider, {UserProvider} from './userProvider';
import uploadFileProvider, {UploadFileProvider} from './uploadFileProvider';
import groupProvider, {GroupProvider, processGroupData} from './groupProvider';
import communityProvider, {CommunityProvider} from './communityProvider';

type DataProvider = RaDataProvider &
  UserProvider &
  GroupProvider &
  CommunityProvider &
  UploadFileProvider & {
    getManyGroups: (groupIds: Identifier[]) => Promise<GetManyResult<Record>>;
  };

/**
 * Implementation of dataProvider from react-admin
 * If need other APIs, please put them into the corresponding providers in this folder
 */
const dataProvider: DataProvider = {
  ...userProvider,
  ...groupProvider,
  ...communityProvider,
  ...uploadFileProvider,
  getList: (resource, params) => {
    const {filter, pagination, sort} = params;

    const {page, perPage} = pagination;
    const {field, order} = sort;

    const query = {
      ...filter,
      offset: perPage * (page - 1),
      limit: perPage,
      sort: `${field}:${order.toLowerCase()}`,
    } as ParsedUrlQueryInput;

    if (filter.key && filter.key.trim() === '') {
      delete query.key;
    }

    switch (resource) {
      case RESOURCES.COMMUNITY_MEMBERS:
        if (!params.filter.communityId) {
          return Promise.reject('No community id is set');
        }
        return dataProvider.getCommunityMembers(filter.communityId, query);

      case RESOURCES.COMMUNITY_ADMINS:
        if (!params.filter.communityId) {
          return Promise.reject('No group id is set');
        }
        query.only_community_admin = true;
        return dataProvider.getCommunityMembers(filter.communityId, query);

      case RESOURCES.COMMUNITY_JOINABLE_USERS:
        if (!params.filter.communityId) {
          return Promise.reject('No group id is set');
        }
        return dataProvider.getCommunityJoinableUsers(
          filter.communityId,
          query,
        );

      case RESOURCES.GROUP_MEMBERS:
        if (!params.filter.groupId) {
          return Promise.reject('No group id is set');
        }
        return dataProvider.getGroupMembers(filter.groupId, query);

      case RESOURCES.GROUP_ADMINS:
        if (!params.filter.groupId) {
          return Promise.reject('No group id is set');
        }
        query.only_group_admin = true;
        return dataProvider.getGroupMembers(filter.groupId, query);

      case RESOURCES.GROUP_JOINABLE_USERS:
        if (!params.filter.groupId) {
          return Promise.reject('No group id is set');
        }
        return dataProvider.getGroupJoinableUsers(filter.groupId, query);

      case RESOURCES.GROUPS:
        if (!params.filter.community_id) {
          // Resolve here since we don't need to fetch if no community, and show nothing in list rightaway
          return Promise.resolve({data: [], total: 0});
        }
        break;

      default:
        break;
    }

    const url = `${
      resource === RESOURCES.USERS ? API_USER_ADMIN_URL : API_ADMIN_URL
    }/${resource}?${stringify(query)}`;

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data,
          total: json.meta.total,
        });
      })
      .catch(err => {
        return Promise.reject(err);
      });
  },

  getOne: (resource, params) => {
    let url = `${
      resource === RESOURCES.USERS ? API_USER_ADMIN_URL : API_ADMIN_URL
    }/${resource}/${params.id}`;
    switch (resource) {
      case RESOURCES.USERS:
        url = url + '/profile';
        break;
      default:
        break;
    }

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data,
        });
      })
      .catch(err => {
        return Promise.reject(err);
      });
  },

  getMany: (resource, params) => {
    let url: string;
    /**
     * This complex if case is for handling calling to export many from GroupMembers.tsx, and other cases
     * Read the comment in the file GroupMembers.tsx to know more.
     *
     * In the params.ids, if there is groupId, the first element will be negative, and equal that groupId
     */
    const extractSpecificIdAndOthers = (ids: Identifier[]) => {
      const mutableIds = [...ids];
      const firstElement = mutableIds.shift();

      return {
        specialId:
          firstElement && +firstElement < 0 ? -firstElement : undefined,
        ids: mutableIds,
      };
    };

    // Need to declare before switch case, this is for specific resource like groups, groupMembers
    const {specialId, ids: extractedIds} = extractSpecificIdAndOthers(
      params.ids,
    );
    switch (resource) {
      case RESOURCES.GROUPS:
        // Check and fetch getManyGroups if specialId is not provided
        if (!specialId) {
          return dataProvider.getManyGroups(params.ids);
        }
        url = `${API_ADMIN_URL}/groups?${stringify({
          community_id: specialId,
          ids: extractedIds.join(','),
        })}`;
        break;
      case RESOURCES.GROUP_MEMBERS:
        if (!specialId) {
          return Promise.reject('No group id is set');
        }
        return dataProvider.getGroupMembers(specialId, {});
      case RESOURCES.USERS:
        url = `${API_USER_ADMIN_URL}/users?user_ids=${params.ids.join(',')}`;
        break;
      case RESOURCES.COMMUNITY_MEMBERS:
        // temporary return
        return Promise.resolve({data: []});
      default:
        url = `${API_ADMIN_URL}/${resource}`;
    }

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({data: json.data});
      })
      .catch(err => {
        return Promise.reject(err);
      });
  },

  getManyGroups: (groupIds: Identifier[]) => {
    const promiseList = (groupIds || []).map(groupId =>
      dataProvider
        .getOne(RESOURCES.GROUPS, {id: groupId})
        .then(result => result.data)
        .catch(error => {
          console.error('Error in getManyGroups', error);
        }),
    );

    return Promise.all(promiseList).then(results => {
      return {data: results} as GetManyResult;
    });
  },

  getManyReference: (resource, params) => {
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${API_ADMIN_URL}/${resource}?${stringify(query)}`;

    return httpClient(url)
      .then(({json}) =>
        Promise.resolve({
          data: json.data,
          total: json.data.length,
        }),
      )
      .catch(err => {
        return Promise.reject(err);
      });
  },

  update: async (resource, params) => {
    if (resource === RESOURCES.USERS) return userProvider.updateUser(params);

    if (resource === RESOURCES.COMMUNITIES || resource === RESOURCES.GROUPS) {
      params.data = await processGroupData(
        params.data,
        params.previousData,
      ).catch(err => Promise.reject(err));
    }

    const url = `${API_ADMIN_URL}/${resource}/${params.id}`;

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    })
      .then(() => Promise.resolve({data: {id: params.id, ...params.data}}))
      .catch(err => {
        const errorMessage =
          err?.body?.data?.error || err?.body?.meta?.message || undefined;
        return Promise.reject(errorMessage ? errorMessage : err);
      });
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({id: params.ids}),
    };
    return httpClient(`${API_ADMIN_URL}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    })
      .then(({json}) => Promise.resolve({data: json.data}))
      .catch(err => {
        return Promise.reject(err);
      });
  },

  create: async (resource, params) => {
    const {data} = params;

    switch (resource) {
      case RESOURCES.USERS:
        if (data.file) {
          return dataProvider.uploadUsersCSV(data.file);
        }

        delete data.confirmPassword;
        break;
      case RESOURCES.GROUPS:
        /**
         * Handling if create groups with CSV,
         * else continue to create new group
         */
        if (data.community_id && data.admin_id && data.file) {
          return dataProvider.uploadGroupsCSV(
            data.community_id,
            data.admin_id,
            data.file,
          );
        }
        break;
      case RESOURCES.MEMBERS_FOR_IMPORT:
      case RESOURCES.MEMBERS_TO_COM:
        if (!data.file) {
          return Promise.reject({
            meta: {
              message: 'File to upload is empty',
            },
          });
        }
        return dataProvider.uploadMembersToGroupsOrComCSV(data.file, resource);

      default:
        break;
    }

    return httpClient(
      `${
        resource === RESOURCES.USERS ? API_USER_ADMIN_URL : API_ADMIN_URL
      }/${resource}`,
      {
        method: 'POST',
        body: JSON.stringify(params.data),
      },
    )
      .then(({json}) => {
        const {data} = json;
        const {id, ...rest} = data;

        return Promise.resolve({
          data: {...params.data, id, ...rest},
        });
      })
      .catch(err => {
        const errorMessage =
          err?.body?.data?.error || err?.body?.meta?.message || undefined;
        return Promise.reject(errorMessage ? errorMessage : err);
      });
  },

  delete: (resource, params) =>
    httpClient(`${API_ADMIN_URL}/${resource}/${params.id}`, {
      method: 'DELETE',
    })
      .then(({json}) => Promise.resolve({data: json.data}))
      .catch(err => {
        return Promise.reject(err);
      }),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({id: params.ids}),
    };
    return httpClient(`${API_ADMIN_URL}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.ids),
    })
      .then(({json}) => Promise.resolve({data: json.data}))
      .catch(err => {
        return Promise.reject(err);
      });
  },
};

export default dataProvider;
