import * as React from 'react';
import {
  AppBar,
  AppBarProps,
  MenuItemLink,
  useGetIdentity,
  UserMenu,
  UserMenuProps,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import {makeStyles} from '@material-ui/core/styles';

const CustomUserMenu = (props: UserMenuProps): JSX.Element => {
  const {identity} = useGetIdentity();

  return (
    <UserMenu {...props}>
      <MenuItemLink
        to={`/users/${identity?.id}/show`}
        primaryText="Profile"
        leftIcon={<PersonIcon />}
        sidebarIsOpen
      />
    </UserMenu>
  );
};

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  mainTitle: {
    fontSize: 26,
  },
  spacer: {
    flex: 1,
  },
});

const CustomAppBar = (props: AppBarProps): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar {...props} elevation={3} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <div color="inherit" className={classes.mainTitle}>
        Bein Staff
      </div>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
