import React from 'react';
import {
  EmailField as RaEmailField,
  EmailFieldProps,
  useRecordContext,
} from 'react-admin';
import PropTypes from 'prop-types';

import {IUser} from '../interfaces/IUser';

const EmailField: React.FC<Omit<EmailFieldProps, 'color'>> = props => {
  const record = useRecordContext(props);

  if (!record) return null;
  const isDeactivated = (record as IUser).is_deactivated;
  const color = isDeactivated ? 'textSecondary' : 'primary';

  return <RaEmailField {...props} color={color} />;
};

EmailField.propTypes = {
  record: PropTypes.any,
};

EmailField.defaultProps = {
  source: 'email',
  addLabel: true,
  label: 'Email',
};

export default EmailField;
