import React, {FC, useState} from 'react';

import {TextInput, TextInputProps} from 'react-admin';
import {useField} from 'react-final-form';
import {InputAdornment, IconButton} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {userValidators} from '../common/validation';

export interface PasswordInputProps
  extends Omit<TextInputProps, 'type' | 'validate' | 'InputProps'> {
  source: string;
  isLocked?: boolean;
  error?: string;
}

const EmailInput: FC<PasswordInputProps> = ({
  isLocked = true,
  error,
  ...props
}: PasswordInputProps) => {
  const {meta} = useField('email');
  const hasValidationError = !!meta.error;
  const hasAnyError = hasValidationError || !!error;
  const [locked, setIsLocked] = useState(isLocked);

  const handleClick = () => {
    setIsLocked(!locked);
  };

  let helperText;
  if (hasValidationError) {
    helperText = meta.error.message;
  } else if (error && error?.trim() !== '') {
    helperText = error;
  } else if (!locked) {
    helperText = 'User must re-login with new email after changing';
  }

  return (
    <TextInput
      {...props}
      type="email"
      validate={userValidators.email}
      disabled={locked}
      helperText={helperText}
      options={{
        error: hasAnyError,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={locked ? 'Unlocked' : 'Locked'}
              onClick={handleClick}>
              {locked ? <LockIcon /> : <LockOpenIcon color="primary" />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default EmailInput;
