import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, ButtonProps, Identifier} from 'react-admin';
import {Drawer} from '@material-ui/core';
import PropTypes from 'prop-types';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import actions from '../../../store/common/actions';
import AddMemberDrawer from './AddMemberDrawer';
import {useCommon} from '../../../hooks/common';
import {ValueType} from '../AdminPickerButton';

const label = 'Add members';

interface AddMemberButtonProps extends ButtonProps {
  valueId: Identifier;
  valueType: ValueType;
}

const AddMemberButton: React.FC<AddMemberButtonProps> = ({
  valueId,
  valueType,
}) => {
  const {showAddMemberDrawer, adminIdToEditMemberList} = useCommon();
  const dispatch = useDispatch();

  const openAddMemberDrawer = () => {
    if (!adminIdToEditMemberList) {
      dispatch(actions.setShowAlertMustPickAdmin(true));
      return;
    }
    dispatch(actions.setShowAddMemberDrawer(true));
  };

  const closeAddMemberDrawer = () => {
    dispatch(actions.setShowAddMemberDrawer(false));
  };

  return (
    <React.Fragment>
      <Button label={label} onClick={openAddMemberDrawer}>
        <GroupAddIcon />
      </Button>
      <Drawer
        anchor="right"
        open={showAddMemberDrawer}
        onClose={closeAddMemberDrawer}>
        <AddMemberDrawer
          valueId={valueId}
          valueType={valueType}
          onClose={closeAddMemberDrawer}
        />
      </Drawer>
    </React.Fragment>
  );
};

AddMemberButton.propTypes = {
  valueId: PropTypes.any,
  valueType: PropTypes.any,
};

export default AddMemberButton;
