import {Record} from 'react-admin';
import {BeinStaffRole} from '../constants';

export const INIT_USER_EDIT_FIELDS = {
  id: 0,
  fullname: '',
  gender: null,
  birthday: null,
  avatar:
    'https://img.flaticon.com/icons/png/512/86/86494.png?size=1200x630f&pad=10,10,10,10&ext=png&bg=FFFFFFFF',
  background_img_url:
    'https://evolgroup.vn/wp-content/uploads/sites/18/2020/04/Thumb-EVOL.jpg',
  country_code: null,
  phone: null,
  address: null,
  city: null,
  country: null,
  language: [],
  description: null,
  relationship_status: null,
} as Record;

export const EDITABLE_USER_ATTRIBUTES = [
  'email',
  'fullname',
  'gender',
  'birthday',
  'avatar',
  'background_img_url',
  'phone',
  'address',
  'language',
  'description',
  'relationship_status',
  'country_code',
  'country',
  'city',
  'bein_staff_role',
];

export const relationshipStatusChoices = [
  {id: 'SINGLE', name: 'Single'},
  {id: 'IN_A_RELATIONSHIP', name: 'In a relationship'},
  {id: 'ENGAGED', name: 'Engaged'},
  {id: 'MARRIED', name: 'Married'},
];

export const beinStaffRoleChoices = [
  {id: 'NONE', name: 'None'},
  {id: BeinStaffRole.SUPER_ADMIN, name: 'Super Admin'},
  {id: BeinStaffRole.STAFF, name: 'Staff'},
];
