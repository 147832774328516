import * as React from 'react';
import {FC} from 'react';
import {Link, FieldProps} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';

import {IUser} from '../interfaces/IUser';
import {IGroupMember} from '../interfaces/IGroup';
import {ICommunityMember} from '../interfaces/ICommunity';
import Avatar from '../components/Avatar';
import {MAX_NAME_COLUMN_WIDTH} from '../common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

interface Props extends FieldProps<IUser | IGroupMember | ICommunityMember> {
  size?: number;
  clickable?: boolean;
}

const FullnameField: FC<Props> = ({record, size = 42, clickable = true}) => {
  const classes = useStyles();
  const Outer = clickable ? Link : Box;

  if (record) {
    const isDeactivated = (record as IUser).is_deactivated;

    return (
      <Outer to={`/users/${record.id}/show`}>
        <div className={classes.root}>
          {record.avatar ? (
            <Avatar
              record={record}
              resource="users"
              size={size}
              className={classes.avatar}
            />
          ) : null}
          <Box
            maxWidth={MAX_NAME_COLUMN_WIDTH}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            display="block"
            color={isDeactivated ? 'gray' : ''}>
            {record.fullname}
          </Box>
          {isDeactivated && (
            <Box maxWidth={MAX_NAME_COLUMN_WIDTH} marginLeft="5px" color="gray">
              {`(deactivated)`}
            </Box>
          )}
        </div>
      </Outer>
    );
  }
  return null;
};

FullnameField.propTypes = {
  record: PropTypes.any,
  size: PropTypes.number,
  clickable: PropTypes.bool,
};

FullnameField.defaultProps = {
  source: 'fullname',
  addLabel: true,
  label: 'Full name',
};

export default FullnameField;
