import {Record} from 'react-admin';
import {BASE_GROUP_ROLES} from '../common/constants';
import {IGroupMember, IMemberRole} from '../interfaces';

// TODO: Wait for BE to update custom roles, then fix this method
export const mapGroupMember = (record: Record): IGroupMember => {
  /**
   * Reuse type in roles as the id to make it easier to assign / revoke roles in the future
   */
  const roles: IMemberRole[] = [
    {
      id: BASE_GROUP_ROLES.GROUP_MEMBER.type,
      name: BASE_GROUP_ROLES.GROUP_MEMBER.name,
      type: BASE_GROUP_ROLES.GROUP_MEMBER.type,
    },
  ];

  record.roles.forEach((role: {name: string; type: string}) => {
    if (!role) return; // BE return null in this array sometime, we need to avoid reading attribute on `null`

    roles.push({
      id: role.type,
      name: role.name,
      type: role.type,
    });
  });

  return {
    ...record,
    roles,
  } as IGroupMember;
};

export const mapGroupRole = (record: {
  name: string;
  type: string;
}): IMemberRole => {
  /**
   * Reuse type in roles as the id to make it easier to assign / revoke roles in the future
   */
  return {
    id: record.type,
    name: record.name,
    type: record.type,
  };
};
