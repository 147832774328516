import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Button, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogTitle from '@material-ui/core/DialogTitle';
import { dataProvider } from '../providers';
import { useNotify, useRefresh } from 'react-admin';
import { toNumber } from 'lodash';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  containerField: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px'
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
}));

export function fakeDataTime(count: number) {
  const arr = []

  for (let i = 0; i < count; i++) arr.push(i)

  return arr
}

export const convertDateTime = (time: number) => {
  return time < 10 ? `0${time}` : time;
};

const MaintenanceComponent = (): JSX.Element => {
  const classes = useStyles()

  const defaultDateDialog = {
    isOpen: false,
    isDisable: false,
  }
  const [selectedDate, setSelectedDate] = React.useState('');
  const [selectedHour, setSelectedHour] = React.useState(0);
  const [selectedMinute, setSelectedMinute] = React.useState(0);
  const [duration, setDuration] = React.useState('');
  const [dataDialog, setDataDialog] = React.useState(defaultDateDialog);
  const [disableMaintenance, setDisableMaintenance] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleChangeHour = (value: number) =>
    setSelectedHour(value)

  const handleChangeMinute = (value: number) =>
    setSelectedMinute(value)

  const handleDateChange = (date: string) =>
    setSelectedDate(date);

  const handleDurationChange = (value: string) => {
    if (value)
      setDuration(Math.abs(toNumber(value)).toString());
    else setDuration('')
  }

  const dateMaintenance = new Date(selectedDate)

  const handleSubmit = async () => {
    setDataDialog({
      ...dataDialog,
      isDisable: true
    })

    try {
      const response = await dataProvider
        .processMaintenance({
          startDate: `${selectedDate}T${convertDateTime(selectedHour)}:${convertDateTime(selectedMinute)}:00+07:00`,
          duration: toNumber(duration),
        })

      notify(response.meta.message, 'success');
      setSelectedDate('')
      setSelectedHour(0)
      setSelectedMinute(0)
      setDuration('')
      setDataDialog(defaultDateDialog)
      setDisableMaintenance(true)
      setTimeout(() => {
        setDisableMaintenance(false)
      }, 60000)

      refresh();
    }
    catch (err: any) {
      const errorMessage = `Error: ${err?.meta?.message}`;
      notify(errorMessage, 'error');
      setDataDialog({
        isOpen: true,
        isDisable: false
      })
    }
  };

  return <div>
    <Card>
      <CardContent>
        <h1>Maintenance</h1>
        Maintenance time:
        <div className={classes.containerField}>
          <p style={{ marginRight: '34px' }}>Time: </p>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="demo-simple-select-filled-label">Hour</InputLabel>
            <Select
              value={selectedHour}
              onChange={(e) => handleChangeHour(e.target.value as number)}
            >
              {fakeDataTime(24).map((item) => <MenuItem value={item} key={item}>{item}</MenuItem>)}
            </Select>
          </FormControl>
          <div style={{ marginRight: '8px', marginLeft: '8px' }}>:</div>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="demo-simple-select-filled-label">Minute</InputLabel>
            <Select
              value={selectedMinute}
              onChange={(e) => handleChangeMinute(e.target.value as number)}
            >
              {fakeDataTime(60).map((item) => <MenuItem value={item} key={item}>{item}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <div className={classes.containerField}>
          <p style={{ marginRight: '28px' }}>Date: </p>
          <TextField
            id="date"
            type="date"
            variant="outlined"
            className={classes.textField}
            value={selectedDate}
            onChange={(e) => handleDateChange(e.target.value)}
          />
        </div>
        <div className={classes.containerField}>
          <p style={{ marginRight: '8px' }}>Duration: </p>
          <TextField
            variant="outlined"
            type="number"
            value={duration}
            onChange={(e) => handleDurationChange(e.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">minute</InputAdornment>,
              inputProps: { min: 1 }
            }}
          />
        </div>
        <Button variant="contained" color="primary" style={{ marginTop: '16px' }} disabled={!selectedDate || toNumber(duration) === 0 || disableMaintenance} onClick={() => setDataDialog({ ...dataDialog, isOpen: true })}>
          Submit
        </Button>
        <Dialog open={dataDialog.isOpen} onClose={() => setDataDialog(defaultDateDialog)}>
          <DialogTitle id="alert-dialog-title">Maintenance will start at </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ textAlign: 'center' }}>
              {convertDateTime(selectedHour)}:{convertDateTime(selectedMinute)}{'  '}-{'  '}{`${dateMaintenance.getDate()}/${dateMaintenance.getMonth() + 1}/${dateMaintenance.getFullYear()}`}
            </DialogContentText>
            <DialogContentText style={{ textAlign: 'center' }}>
              Duration {duration} {toNumber(duration) > 1 ? 'minutes' : 'minute'}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button color="secondary" onClick={() => setDataDialog(defaultDateDialog)} disabled={dataDialog.isDisable}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleSubmit} disabled={dataDialog.isDisable}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  </div>
};

export default MaintenanceComponent