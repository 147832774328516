import {
  CreateResult,
  GetManyResult,
  Identifier,
  Record,
  UpdateParams,
  UpdateResult,
} from 'react-admin';

import {IReactAdminFile} from '../../interfaces/common';
import {IUser} from '../../interfaces/IUser';
import {
  INIT_USER_EDIT_FIELDS,
  EDITABLE_USER_ATTRIBUTES,
} from '../../users/common';
import {
  httpClient,
  removeNotEditableAttributes,
  replaceEmptyAttributesWithDefaultValue,
} from '../../utils';
import {API_ADMIN_URL, API_USER_ADMIN_URL} from '../../constants';
import {getPhotoUrl} from './uploadFileProvider';
import {UploadCsvUrl} from '../../constants/upload';
import {RESOURCES} from '../../common/constants';

export type UserProvider = {
  updateUser: (params: UpdateParams) => Promise<UpdateResult<IUser>>;
  addUserToGroup: (props: {
    userIds: Identifier[];
    groupIds: Identifier[];
    adminId: Identifier;
  }) => Promise<unknown>;
  getUserJoinedCommunities: (userId: Identifier) => Promise<GetManyResult>;
  uploadUsersCSV: (file: IReactAdminFile) => Promise<CreateResult>;
  uploadMembersToGroupsOrComCSV: (
    file: IReactAdminFile,
    resource: string,
  ) => Promise<CreateResult>;
  checkUploadMembersCsvProcess: (uploadId: string) => Promise<any>;
  toggleDeactivateUser: (params: {
    userId: Identifier;
    is_deactivated: boolean;
  }) => Promise<any>;
  processMaintenance: (params: {
    startDate: string;
    duration: number;
  }) => Promise<any>;
  toggleVerifyUser: (params: {
    userId: Identifier;
    isVerified: boolean;
  }) => Promise<any>;
  postContentChangelogs: (params: {
    title: string;
    content: string;
  }) => Promise<any>;
};

const userProvider: UserProvider = {
  updateUser: async (params: UpdateParams) => {
    const data: Omit<Record, 'id'> = await processUserData(
      params.data,
      params.previousData,
    ).catch(err => Promise.reject(err));
    const url = `${API_USER_ADMIN_URL}/users/${params.id}/profile`;
    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
      .then(() =>
        Promise.resolve({
          data: {id: params.id, ...data},
        } as UpdateResult<IUser>),
      )
      .catch(err => {
        let errorMessage =
          err?.body?.meta?.message ?? 'Something went wrong when update user';
        const errorList = err?.body?.meta?.errors ?? [];
        if (errorList.length > 0) {
          errorMessage = err.body.meta?.errors
            .map((error: {message: string}) => error.message)
            .join('. ');
        }
        return Promise.reject({
          code: err?.body?.code,
          message: errorMessage,
        });
      });
  },

  addUserToGroup: ({userIds, groupIds, adminId}) => {
    const url = API_ADMIN_URL + '/users/add-users-to-groups';
    const body = {
      user_ids: userIds,
      group_ids: groupIds,
      actor_id: adminId,
    };

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then(({json}) => {
        return Promise.resolve(json);
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  getUserJoinedCommunities: (userId: Identifier) => {
    const url = API_ADMIN_URL + `/users/${userId}/communities`;

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data,
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  uploadUsersCSV: (file: IReactAdminFile) => {
    const url = UploadCsvUrl.users;
    if (!file) return Promise.reject('No file to upload');

    const formData = new FormData();
    formData.append('file', file.rawFile, file.title);

    return httpClient(url, {
      method: 'POST',
      body: formData,
    })
      .then(() => {
        /**
         * Return false data as React Admin requires return type of Record
         */
        return Promise.resolve({
          data: {
            id: 0,
          },
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  uploadMembersToGroupsOrComCSV: (file: IReactAdminFile, resource: string) => {
    const url =
      resource === RESOURCES.MEMBERS_TO_COM
        ? UploadCsvUrl.membersToCom
        : UploadCsvUrl.members;
    if (!file) return Promise.reject('No file to upload');

    const formData = new FormData();
    formData.append('file', file.rawFile, file.title);

    return httpClient(url, {
      method: 'POST',
      body: formData,
    })
      .then(json => {
        /**
         * Return false data as React Admin requires return type of Record
         */
        const responseData = {
          data: {
            id: 0,
            ...JSON.parse(json.body).data,
          },
        };
        return Promise.resolve(responseData);
      })
      .catch(err => {
        if (err.body?.data?.message) {
          return Promise.reject({
            meta: {
              message: err.body.data.message,
            },
          });
        }

        return Promise.reject(err.body);
      });
  },
  checkUploadMembersCsvProcess: (uploadId: string) => {
    const url = `${UploadCsvUrl.members}/${uploadId}`;

    return httpClient(url, {
      method: 'GET',
    });
  },

  toggleDeactivateUser: ({userId, is_deactivated}) => {
    const url = `${API_USER_ADMIN_URL}/users/${userId}/state`;

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify({is_deactivated}),
    })
      .then(json => {
        return Promise.resolve(json.json);
      })
      .catch(err => {
        if (err.body?.data?.message) {
          return Promise.reject({
            meta: {
              message: err.body.data.message,
            },
          });
        }

        return Promise.reject(err.body);
      });
  },
  processMaintenance: async ({startDate, duration}) => {
    const url = `${API_USER_ADMIN_URL}/schedule-maintenance`;

    try {
      const jsonData = await httpClient(url, {
        method: 'POST',
        body: JSON.stringify({start_at: startDate, duration: duration}),
      });

      return Promise.resolve(jsonData.json);
    } catch (err: any) {
      if (err.body?.data?.message) {
        return Promise.reject({
          meta: {
            message: err.body.data.message,
          },
        });
      }

      return Promise.reject(err.body);
    }
  },

  toggleVerifyUser: async ({userId, isVerified}) => {
    const url = `${API_USER_ADMIN_URL}/users/${userId}/${
      isVerified ? 'verify' : 'unverify'
    }`;

    try {
      const jsonData = await httpClient(url, {
        method: 'PUT',
      });

      return Promise.resolve(jsonData.json);
    } catch (err: any) {
      if (err.body?.data?.message) {
        return Promise.reject({
          meta: {
            message: err.body.data.message,
          },
        });
      }

      return Promise.reject(err.body);
    }
  },

  postContentChangelogs: async ({title, content}) => {
    const url = `${API_USER_ADMIN_URL}/changelogs`;

    try {
      const jsonData = await httpClient(url, {
        method: 'POST',
        body: JSON.stringify({title, content}),
      });

      return Promise.resolve(jsonData.json);
    } catch (err: any) {
      if (err.body?.data?.message) {
        return Promise.reject({
          meta: {
            message: err.body.data.message,
          },
        });
      }

      return Promise.reject(err.body);
    }
  },
};

const processUserData = async (record: Record, previousRecord: Record) => {
  let newRecord: Record = {
    ...INIT_USER_EDIT_FIELDS,
    ...record,
  };
  newRecord = removeNotEditableAttributes(newRecord, EDITABLE_USER_ATTRIBUTES);
  newRecord.birthday = !record.birthday ? null : record.birthday;
  newRecord.avatar = await getPhotoUrl(
    record.avatar,
    previousRecord.avatar,
    'user-avatar',
  );
  newRecord.background_img_url = await getPhotoUrl(
    record.background_img_url,
    previousRecord.background_img_url,
    'user-cover',
  );
  newRecord = replaceEmptyAttributesWithDefaultValue(
    newRecord,
    INIT_USER_EDIT_FIELDS,
  );

  return newRecord;
};

export default userProvider;
