import {Record} from 'react-admin';

export const INIT_GROUP_FIELDS = {
  id: 0,
  parent_id: null,
  name: '',
  description: null,
  owner_id: 0,
  icon: null,
  background_img_url:
    'https://evolgroup.vn/wp-content/uploads/sites/18/2020/04/Thumb-EVOL.jpg',
  privacy: 'PUBLIC',
} as Record;

export const EDITABLE_GROUP_ATTRIBUTES = [
  'name',
  'description',
  'icon',
  'background_img_url',
  'privacy',
];

export const ERROR_MOVING_GROUP_UNDER_THE_SAME_OUTER_GROUP =
  'Outer group already is a direct outer group of current group';
