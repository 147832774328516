import React from 'react';
import {
  Confirm,
  useDataProvider,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import {Box, List, ListItem, ListItemText, Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import actions from '../../../store/common/actions';
import {useDispatch} from 'react-redux';
import {useCommon} from '../../../hooks/common';

const AlertRemoveCommunityMember: React.FC = () => {
  const {alertRemoveMember, adminIdToEditMemberList} = useCommon();
  const {isOpen, valueId, valueType, member, memberInnerGroups} =
    alertRemoveMember;
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  if (!isOpen || !adminIdToEditMemberList) return null;

  const closeAlert = () => dispatch(actions.hideAlertRemoveMember());

  const removeMember = () => {
    if (!member?.id) {
      notify('No user id', 'warning');
      return;
    }

    if (!adminIdToEditMemberList) {
      notify('No admin selected', 'warning');
      return;
    }

    const apiRemove =
      valueType === 'community'
        ? 'removeUsersFromCommunity'
        : 'removeUsersFromGroup';

    dataProvider[apiRemove]({
      groupId: valueId,
      communityId: valueId,
      userIds: [member.id],
      adminId: adminIdToEditMemberList,
    })
      .then(() => {
        notify(
          `Removed "${member.fullname} from this ${valueType} successfully`,
          'success',
        );
      })
      .catch((err: {meta: {message: string}}) => {
        notify(`Error: ${err.meta.message}`, 'warning');
      })
      .finally(() => {
        unselectAll(`${valueType}Members`);
        refresh();
        closeAlert();
      });
  };

  const renderInnerGroups = () => {
    if (
      !member?.username ||
      !memberInnerGroups ||
      memberInnerGroups.length === 0
    )
      return null;

    const numberOfInnerGroups = memberInnerGroups.length;
    let desc = 'This member will also be removed from {0} other inner groups';

    if (numberOfInnerGroups === 1) {
      desc = desc.replace('{0} other inner groups', 'another inner group');
    } else {
      desc = desc.replace('{0}', `${numberOfInnerGroups}`);
    }

    const first3Groups = memberInnerGroups.slice(0, 3);

    return (
      <Box marginBottom={1}>
        <Typography>{desc}</Typography>
        <List>
          {first3Groups.map(group => (
            <ListItem key={`group_to_remove_${group.id}`}>
              <ListItemText primary={group.name} />
            </ListItem>
          ))}
          {numberOfInnerGroups > 3 && (
            <ListItem>
              <ListItemText primary="..." />
            </ListItem>
          )}
        </List>
      </Box>
    );
  };

  const renderAlert = () => {
    return (
      <>
        {renderInnerGroups()}
        <Typography>Are you sure to remove this member?</Typography>
      </>
    );
  };

  return (
    <Confirm
      isOpen={isOpen}
      title="Remove member"
      content={renderAlert()}
      confirm="Remove member"
      ConfirmIcon={DeleteIcon}
      onConfirm={removeMember}
      onClose={closeAlert}
      CancelIcon={'div'}
    />
  );
};

export default AlertRemoveCommunityMember;
