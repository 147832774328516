import {API_USER_ADMIN_URL} from '../constants';
import {IUser} from '../interfaces';
import {httpClient} from '../utils';

class AdminInfoStore {
  private info?: IUser;

  clearInfo() {
    this.info = undefined;
  }

  async fetchAndSaveAdminInfo() {
    const urlToGetAdminAuthorization = API_USER_ADMIN_URL;
    const adminInfo = await httpClient(urlToGetAdminAuthorization, {
      method: 'GET',
    }).then(json => {
      return JSON.parse(json.body).data ?? undefined;
    });

    this.info = adminInfo;
  }

  async checkAndFetchInfoIfNecessary() {
    if (!this.info) {
      await this.fetchAndSaveAdminInfo();
    }
  }

  async getInfo() {
    await this.checkAndFetchInfoIfNecessary();
    return this.info;
  }

  async isBicStaff() {
    await this.checkAndFetchInfoIfNecessary();
    return !!this.info?.bein_staff_role;
  }

  async getStaffRole() {
    await this.checkAndFetchInfoIfNecessary();
    return this.info?.bein_staff_role ?? undefined;
  }
}

const adminInfoStore = new AdminInfoStore();
export default adminInfoStore;
