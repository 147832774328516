import React, { useEffect, useState } from 'react';
import { Record, useRecordContext, useDataProvider } from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';

import GroupTree from '../groups/GroupTree';
import { IGroupTree } from '../interfaces/IGroup';
import { useDispatch } from 'react-redux';
import { useGroups } from '../hooks/groups';
import { toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';
import { setReloadGroupTree } from '../groups/redux/actions';
import { debounce } from 'lodash';
import HistoryCard from '../components/HistoryCard';

const useAsideStyles = makeStyles(theme => ({
  root: {
    width: 500,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Aside: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useAsideStyles();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { reloadGroupTree } = useGroups();

  const [error, setError] = useState<string>('');
  const [treeData, setTreeData] = useState<IGroupTree[]>([]);
  const [loadingInnerGroups, setLoadingInnerGroups] = useState(true);

  const isRecordUndefined = !record || !record.id || !record.group_id;

  const loadInnerGroup = debounce(() => {
    if (isRecordUndefined) return;

    setLoadingInnerGroups(true);
    dataProvider
      .getInnerGroups(record.group_id)
      .then((res: Record) => {
        if (!res) {
          setError(`Couldn't fetch inner groups, please reload page.`);
          return;
        }
        const rootTreeData = toggleExpandedForAll({
          treeData: [{ ...res.data }],
          expanded: true,
        }) as unknown as IGroupTree[];
        setTreeData(rootTreeData);
      })
      .finally(() => setLoadingInnerGroups(false));
  }, 300);

  useEffect(() => {
    loadInnerGroup();
    return () => {
      setTreeData([]);
      setLoadingInnerGroups(false);
    };
  }, [record]);

  useEffect(() => {
    if (reloadGroupTree) {
      loadInnerGroup();
      dispatch(setReloadGroupTree(false));
    }
  }, [reloadGroupTree]);

  if (isRecordUndefined) return <></>;

  const renderInnerGroups = () => {
    if (loadingInnerGroups)
      return (
        <>
          <Typography variant="body2">Getting inner groups</Typography>
          <CircularProgress color="inherit" size="1rem" />
        </>
      );

    const currentGroup = treeData[0];
    const hasNoChildren =
      !currentGroup?.children || currentGroup.children?.length === 0;
    if (hasNoChildren)
      return <Typography variant="body2">There is no inner group.</Typography>;

    if (error)
      return (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      );

    return (
      <Box height="50vh">
        <GroupTree
          treeData={treeData}
          canDrag={() => false}
          onChange={(treeData: IGroupTree[]) => setTreeData(treeData)}
        />
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <Box m="0 0 1em 1em">
        <HistoryCard record={record} />
      </Box>
      <Box m="0 0 0 1em" minWidth={400}>
        <Card>
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between">
              <Typography variant="h6" gutterBottom>
                Inner groups
              </Typography>
            </Box>
            {renderInnerGroups()}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Aside;
