import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import {EditorState} from 'draft-js';

import styles from '../styles/BaseEditor.module.css';

import {getMarkdownFromEditorState} from '../components/DraftJSEditor/draftjsEditor';
import {getEditorStateFromMarkdown} from '../utils/draftjsEditor';
import {MarkdownRenderer} from '../components/MarkdownRenderer/MarkdownRenderer';
import ChangelogsComposer from '../components/ChangelogsComposer/ChangelogsComposer';
import {dataProvider} from '../providers';
import {useNotify} from 'react-admin';

const useStyles = makeStyles(theme => ({
  cardContent: {
    height: '85vh',
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: '100%',
    marginBottom: '16px',
  },
  buttonConfirm: {
    textAlign: 'right',
  },
  dialogStyle: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  containerContent: {
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    height: '400px',
    borderRadius: '8px',
    overflowY: 'auto',
  },
  titleDialog: {
    backgroundColor: '#ccc',
    padding: '16px',
  },
}));

type ErrorMessage = {
  title: {
    message: string;
  };
  content: {
    message: string;
  };
};

const Changelogs = () => {
  const classes = useStyles();
  const notify = useNotify();

  const defaultContent =
    "We're excited to announce the release of version ...\nHere's a quick preview of what's new in this version:\n\nThank you for your continued support, and we hope you enjoy the latest improvements!\n**BIC Team**\n";

  const [editorState, setEditorState] = useState<EditorState>(() =>
    getEditorStateFromMarkdown(defaultContent),
  );

  const [valueTitle, setValueTitle] = useState('');

  const [error, setError] = useState<ErrorMessage>({
    title: {
      message: '',
    },
    content: {
      message: '',
    },
  });

  const [isOpenDialogPreviewChangeLog, setIsOpenDialogPreviewChangeLog] =
    useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [disableChangelogs, setDisableChangelogs] = useState(false);

  const markdownContent = getMarkdownFromEditorState(editorState);

  const isEmptyContent =
    editorState?.getCurrentContent().getPlainText().trim() === '';

  const setErrorField = (field: keyof ErrorMessage, message: string) => {
    setError({
      ...error,
      [field]: {
        message: message,
      },
    });
  };

  const onOpenDialogPreviewChangeLog = () => {
    if (valueTitle === '') setErrorField('title', 'Title must not be empty');
    else if (isEmptyContent)
      setErrorField('content', "Change log's content must not be empty");
    else setIsOpenDialogPreviewChangeLog(true);
  };

  const onCloseDialogPreviewChangeLog = () => {
    setIsOpenDialogPreviewChangeLog(false);
  };

  const onChangeValueTitle = (value: string) => {
    setValueTitle(value);
    setErrorField('title', '');
  };

  const onSubmitReviewContent = () => {
    setOpenConfirmDialog(true);
  };

  const onCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const clearStateDialog = () => {
    onCloseDialogPreviewChangeLog();
    setOpenConfirmDialog(false);
  };

  const onSubmitSendChangeLogs = async () => {
    setDisableChangelogs(true);
    try {
      const response = await dataProvider.postContentChangelogs({
        title: valueTitle,
        content: markdownContent,
      });
      notify(response.meta.message, 'success');
      clearStateDialog();
      setTimeout(() => {
        setDisableChangelogs(false);
      }, 60000);
    } catch (err: any) {
      const errorMessage = `Error: ${err?.meta?.message}`;
      notify(errorMessage, 'error');
      clearStateDialog();
      setDisableChangelogs(false);
    }
  };

  const contentPreviewChangeLog = (
    <div className={classes.containerContent}>
      <div className={classes.titleDialog}>{valueTitle}</div>
      <div
        style={{overflowY: 'auto', height: 'calc(100vh-320px)'}}
        className={styles.contentItem}>
        <MarkdownRenderer
          markdownSource={markdownContent}
          style={{padding: '16px'}}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (!isEmptyContent) {
      setErrorField('content', '');
    }
  }, [editorState, isEmptyContent]);

  return (
    <>
      <Dialog open={openConfirmDialog} onClose={onCloseConfirmDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent style={{width: '300px'}}>
          Publish this Changelog ?
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCloseConfirmDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitSendChangeLogs}
            disabled={disableChangelogs}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenDialogPreviewChangeLog}
        maxWidth="md"
        fullWidth
        onClose={onCloseDialogPreviewChangeLog}>
        <DialogTitle className={classes.dialogStyle}>
          Preview changelogs notification
        </DialogTitle>
        <DialogContent>{contentPreviewChangeLog}</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCloseDialogPreviewChangeLog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitReviewContent}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardContent className={classes.cardContent}>
          <h1>Changelogs</h1>
          <TextField
            placeholder="Write a title..."
            variant="outlined"
            value={valueTitle}
            className={classes.textField}
            onChange={e => onChangeValueTitle(e.target.value)}
            error={!!error.title.message}
            helperText={!!error.title.message && 'Title must not be empty'}
          />
          <ChangelogsComposer
            editorState={editorState}
            setEditorState={setEditorState}
          />
          {!!error.content.message && (
            <p style={{color: 'red'}}>{error.content.message}</p>
          )}
          <div className={classes.buttonConfirm}>
            <Button
              variant="contained"
              color="primary"
              onClick={onOpenDialogPreviewChangeLog}
              disabled={disableChangelogs}>
              Release & Notify User
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Changelogs;
