import React, {useEffect} from 'react';
import {AutocompleteInput, InputProps, ReferenceInput} from 'react-admin';
import {useFormState, useForm} from 'react-final-form';

import {makeStyles} from '@material-ui/core/styles';

import GroupQuickPreviewButton from './GroupQuickPreviewButton';
import {SortOrder} from '../common/constants';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface Props extends InputProps {
  reference: string;
  communityId?: number;
}

const GroupReferenceInput: React.FC<Props> = ({
  communityId,
  filter,
  ...props
}: Props) => {
  const classes = useStyles();
  const {source} = props;
  const {values} = useFormState({subscription: {values: true}});
  const filterToQuery = (searchText: string) => ({
    key: searchText,
  });
  const form = useForm();
  const clearInput = () => form.change(source, undefined);

  useEffect(() => {
    // clear input field when changing community
    clearInput();
  }, [communityId]);

  return (
    <div className={classes.root}>
      <div>
        <ReferenceInput
          filterToQuery={filterToQuery}
          filter={{
            community_id: communityId,
            ...filter,
          }}
          disabled={!communityId}
          sort={{field: 'name', order: SortOrder.ASC}}
          enabledGetChoices={() => !communityId}
          {...props}>
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
      </div>
      {!!values[source] && <GroupQuickPreviewButton id={values[source]} />}
    </div>
  );
};

export default GroupReferenceInput;
