import types from './types';
import {AnyAction} from 'redux';
import {Identifier} from 'ra-core';
import {ICommunityMember} from '../../interfaces/ICommunity';
import {IGroup, IGroupMember} from '../../interfaces/IGroup';
import {ValueType} from '../../components/Buttons/AdminPickerButton';

export interface CommonState {
  adminIdToEditMemberList: Identifier | undefined;
  showAdminPickerDrawer: boolean;
  showAddMemberDrawer: boolean;
  showDeactivateUserDrawer: boolean;
  showAlertMustPickAdmin: boolean;
  alertRemoveMember: {
    isOpen: boolean;
    valueId?: Identifier;
    valueType?: ValueType;
    member?: ICommunityMember | IGroupMember;
    memberInnerGroups: IGroup[] | [];
  };
}

const initState = {
  adminIdToEditMemberList: undefined,
  showAdminPickerDrawer: false,
  showAddMemberDrawer: false,
  showDeactivateUserDrawer: false,
  showAlertMustPickAdmin: false,
  alertRemoveMember: {
    isOpen: false,
    valueId: undefined,
    valueType: undefined,
    member: {},
    memberInnerGroups: [],
  },
} as CommonState;

function commonReducer(state = initState, action: AnyAction): CommonState {
  const {type, payload} = action;

  switch (type) {
    case types.SET_ADMIN_ID_TO_EDIT_MEMBER_LIST:
      return {
        ...state,
        adminIdToEditMemberList: payload,
      };

    case types.SET_SHOW_ADMIN_PICKER_DRAWER:
      return {
        ...state,
        showAdminPickerDrawer: payload,
      };

    case types.SET_SHOW_ADD_MEMBER_DRAWER:
      return {
        ...state,
        showAddMemberDrawer: payload,
      };

    case types.SET_SHOW_DEACTIVATE_MEMBER_DRAWER:
      return {
        ...state,
        showDeactivateUserDrawer: payload,
      };

    case types.SET_SHOW_ALERT_MUST_PICK_ADMIN:
      return {
        ...state,
        showAlertMustPickAdmin: payload,
      };

    case types.HIDE_ALERT_REMOVE_MEMBER:
      return {
        ...state,
        alertRemoveMember: initState.alertRemoveMember,
      };

    case types.SET_MEMBER_INNER_GROUPS:
      return {
        ...state,
        alertRemoveMember: {
          ...state.alertRemoveMember,
          memberInnerGroups: payload,
        },
      };

    case types.SHOW_ALERT_REMOVE_MEMBER:
      return {
        ...state,
        alertRemoveMember: {
          ...state.alertRemoveMember,
          isOpen: true,
          ...payload,
        },
      };

    default:
      return state;
  }
}

export default commonReducer;
