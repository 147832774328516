import {Identifier, useNotify, useRedirect} from 'ra-core';
import {
  Create,
  CreateProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'ra-ui-materialui';
import {useDataProvider} from 'ra-core';
import React, {useEffect} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {GetOneResult, Record} from 'react-admin';

import SectionTitle from '../components/SectionTitle';
import {communityValidators} from '../common/validation';
import {communityPrivacyChoices, RESOURCES} from '../common/constants';
import UserReferenceInput from '../users/UserReferenceInput';

const CommunityCreate: React.FC<CreateProps> = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (response: {data: {name: string; id: Identifier}}) => {
    const message = `Create new community "${response.data.name}" successfully`;
    notify(message, 'success');

    // just to make sure data is fully created on server
    setTimeout(() => {
      redirect(`/communities`);
    }, 100);
  };

  const onFailure = (error: string) => {
    notify(error, 'error');
  };

  const transform = (data: Record) => {
    // remove unnecessary `admin_id` before sending to dataProvider
    delete data.admin_id;
    const name = data.name.trim();
    return {
      ...data,
      name,
    };
  };

  return (
    <Create
      onSuccess={onSuccess}
      onFailure={onFailure}
      transform={transform}
      {...props}>
      <SimpleForm>
        <SectionTitle label="New community information" />
        <TextInput
          source="name"
          validate={communityValidators.name}
          autoFocus
          inputProps={{autoComplete: 'off'}}
        />
        <UserReferenceInput
          validate={communityValidators.ownerId}
          label="Account Owner"
          source="owner_id"
          reference={RESOURCES.USERS}
          perPage={50}
        />
        <CommunityAdmin />
        <TextInput
          source="description"
          multiline
          resettable
          validate={communityValidators.description}
        />
        <SelectInput
          source="privacy"
          choices={communityPrivacyChoices}
          initialValue={communityPrivacyChoices[0].id}
        />
      </SimpleForm>
    </Create>
  );
};

export default CommunityCreate;

const CommunityAdmin = () => {
  const form = useForm();
  const {values: formData} = useFormState({subscription: {values: true}});
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (formData?.owner_id) {
      dataProvider
        .getOne(RESOURCES.USERS, {id: formData.owner_id})
        .then((value: GetOneResult<Record>) => {
          form.change('admin_id', value.data.fullname);
        });
    }
  }, [formData?.owner_id]);

  return (
    <TextInput
      label="Community Admin"
      source="admin_id"
      disabled
      style={{width: 256, height: 71.91}}
    />
  );
};
