import React from 'react';
import {FilterList, FilterListItem, FilterLiveSearch} from 'react-admin';
import {Card, CardContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import {groupPrivacyChoices} from '../common/constants';

const useStyles = makeStyles(() => {
  const width = '16em';

  return {
    root: {
      order: -1,
      minWidth: width,
      maxWidth: width,
      marginRight: '1em',
    },
  };
});

const AsideGroupList: React.FC = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <FilterLiveSearch source="key" />
        <FilterList label="Privacy" icon={<VisibilityOutlinedIcon />}>
          {groupPrivacyChoices.map(item => (
            <FilterListItem
              key={`filter-group-${item.id}`}
              label={item.name}
              value={{
                privacy: item.id,
              }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default AsideGroupList;
