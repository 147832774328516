import React from 'react';
import {
  Button,
  ButtonProps,
  Identifier,
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import PropTypes from 'prop-types';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import actions from '../../../store/common/actions';
import {useAppDispatch} from '../../../hooks/redux';
import {useCommon} from '../../../hooks/common';
import {ValueType} from '../AdminPickerButton';
import {RESOURCES} from '../../../common/constants';

interface BulkAddMemberButtonProps extends ButtonProps {
  valueId: Identifier;
  valueType: ValueType;
}

const BulkAddMemberButton: React.FC<BulkAddMemberButtonProps> = ({
  valueId,
  valueType,
  ...props
}) => {
  const {selectedIds: selectedUserIds} = useListContext(props);
  const dataProvider = useDataProvider();
  const unselectAll = useUnselectAll();
  const dispatch = useAppDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const {adminIdToEditMemberList} = useCommon();

  const handleSuccess = () => {
    dispatch(actions.setShowAddMemberDrawer(false));
    if (valueType === 'community') {
      unselectAll(RESOURCES.COMMUNITY_JOINABLE_USERS);
    }

    if (valueType === 'group') {
      unselectAll(RESOURCES.GROUP_JOINABLE_USERS);
    }
    refresh();
  };

  const onClick = () => {
    if (!adminIdToEditMemberList) {
      dispatch(actions.setShowAlertMustPickAdmin(true));
      return;
    }

    if (valueType === 'community') {
      dataProvider
        .addUserToCommunity({
          communityId: valueId,
          userIds: selectedUserIds,
          adminId: adminIdToEditMemberList,
        })
        .then(() =>
          notify('Add users to the community successfully', 'success'),
        )
        .catch((err: {meta: {message: string}}) => {
          notify(`${err.meta.message}`, 'error');
        })
        .catch(() => {
          notify('Error when adding member', 'error');
        })
        .finally(handleSuccess);
    }

    if (valueType === 'group') {
      dataProvider
        .addUserToGroup({
          userIds: selectedUserIds,
          groupIds: [valueId],
          adminId: adminIdToEditMemberList,
        })
        .then(() => notify('Add users to the group successfully', 'success'))
        .catch((err: {meta: {message: string}}) => {
          notify(`${err.meta.message}`, 'error');
        })
        .catch(() => {
          notify('Error when adding member', 'error');
        })
        .finally(handleSuccess);
    }
  };

  return (
    <>
      <Button label="Add members" onClick={onClick}>
        <GroupAddIcon />
      </Button>
    </>
  );
};

BulkAddMemberButton.propTypes = {
  valueId: PropTypes.any,
  valueType: PropTypes.any,
};

export default BulkAddMemberButton;
