import React from 'react';
import {FieldProps} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

import {ICommunityMember} from '../../interfaces/ICommunity';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: {margin: 4},
});

const RolesField: React.FC<FieldProps<ICommunityMember>> = ({record}) => {
  const classes = useStyles();

  return record ? (
    <span className={classes.main}>
      {record.roles &&
        record.roles.map(role => {
          if (role === 'MEMBER') return null;

          return (
            <Chip
              size="small"
              color={role === 'COMMUNITY_ADMIN' ? 'primary' : 'default'}
              key={role}
              className={classes.chip}
              label={'Community Admin'}
            />
          );
        })}
    </span>
  ) : null;
};

RolesField.propTypes = {
  record: PropTypes.any,
};

RolesField.defaultProps = {
  addLabel: true,
  source: 'roles',
};

export default RolesField;
