import React, {useEffect, useState} from 'react';
import {useDataProvider, useRecordContext} from 'ra-core';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import {toggleExpandedForAll} from '@nosferatu500/react-sortable-tree';

import {IUser} from '../interfaces/IUser';
import {IGroupTree} from '../interfaces/IGroup';
import GroupTree from '../groups/GroupTree';

const JoinedGroupsTree: React.FC = () => {
  const record = useRecordContext() as IUser;
  const dataProvider = useDataProvider();
  const [treeData, setTreeData] = useState<IGroupTree[]>();
  const [loadingJoinedGroups, setLoadingJoinedGroups] = useState(true);

  useEffect(() => {
    if (!record) return;

    setLoadingJoinedGroups(true);
    dataProvider
      .getUserJoinedCommunities(record.id)
      .then((res: {data: IGroupTree[]}) => {
        if (!res) return;

        const nextTreeData = toggleExpandedForAll({
          treeData: [...res.data],
          expanded: true,
        }) as unknown as IGroupTree[];
        setTreeData(nextTreeData);
      })
      .finally(() => setLoadingJoinedGroups(false));

    return () => {
      setTreeData([]);
      setLoadingJoinedGroups(true);
    };
  }, [record]);

  if (!record) return <></>;

  const renderJoinedCommunitiesTree = () => {
    if (loadingJoinedGroups) {
      const textFetchingJoinedGroups = `Getting user's joined groups`;
      return (
        <>
          <Typography variant="body2">{textFetchingJoinedGroups}</Typography>
          <CircularProgress color="inherit" size="1rem" />
        </>
      );
    }

    if (!treeData) {
      const textNoJoinedGroup = `This user has't joined any community.`;
      return <Typography variant="body2">{textNoJoinedGroup}</Typography>;
    }

    return (
      <Box height="64vh">
        <GroupTree
          treeData={treeData}
          canDrag={() => false}
          onChange={(treeData: IGroupTree[]) => setTreeData(treeData)}
        />
      </Box>
    );
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Joined communities
        </Typography>
        {renderJoinedCommunitiesTree()}
      </CardContent>
    </Card>
  );
};

export default JoinedGroupsTree;
