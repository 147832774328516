import React from 'react';
import {
  Datagrid,
  Identifier,
  List,
  ListProps,
  TextInput,
  TextField,
} from 'react-admin';
import FullnameField from '../../../users/FullnameField';
import PropTypes from 'prop-types';
import BulkAddMemberButton from './BulkAddMemberButton';
import {ValueType} from '../AdminPickerButton';
import {BASEPATHS, RESOURCES, SortOrder} from '../../../common/constants';

const filters = [
  <TextInput key="search" label="Search" source="key" alwaysOn />,
];

interface JoinableMemberListProps extends ListProps {
  valueId: Identifier;
  valueType: ValueType;
}

const JoinableMemberList: React.FC<JoinableMemberListProps> = ({
  valueId,
  valueType,
  ...props
}) => {
  return (
    <List
      {...props}
      basePath={
        valueType === 'community'
          ? BASEPATHS.COMMUNITY_JOINABLE_USERS
          : BASEPATHS.GROUP_JOINABLE_USERS
      }
      resource={
        valueType === 'community'
          ? RESOURCES.COMMUNITY_JOINABLE_USERS
          : RESOURCES.GROUP_JOINABLE_USERS
      }
      filter={{[`${valueType}Id`]: valueId}}
      filters={filters}
      title=" "
      exporter={false}
      hasCreate={false}
      sort={{
        field: 'fullname',
        order: SortOrder.ASC,
      }}
      bulkActionButtons={
        <>
          <BulkAddMemberButton valueId={valueId} valueType={valueType} />
        </>
      }>
      <Datagrid>
        {/* FIXME: remove delete query.sort, when BE supports sorting */}
        <FullnameField sortable={false} />
        <TextField source="username" />
      </Datagrid>
    </List>
  );
};

JoinableMemberList.propTypes = {
  valueId: PropTypes.any,
  valueType: PropTypes.any,
};

export default JoinableMemberList;
