import React, {useRef, useState} from 'react';

import {faAngleDown} from '@fortawesome/pro-regular-svg-icons';
import {faText} from '@fortawesome/pro-solid-svg-icons';
import {EditorState, RichUtils} from 'draft-js';

import {Button, makeStyles} from '@material-ui/core';

import {EDITOR_BLOCK_STYLE_MAP} from '../../constants/draftjsEditor';
import {useClickOutside} from './useClickOutside';
import Icon, {IconName} from '../Icon/Icon';

const useStyles = makeStyles(theme => ({
  blockStyleButton: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '0.25rem',
    paddingRight: '0.25rem',
    paddingLeft: '0.5rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.06) !important',
    },
  },
  buttonNormal: {
    display: 'flex',
    padding: '0.5rem',
    alignItems: 'center',
    borderRadius: '0.375rem',
    borderWidth: '1px',
    width: '200px',
    justifyContent: 'space-between',
    borderColor: 'rgb(227, 230, 242)',
    borderStyle: 'solid',
  },
  labelButtonNormal: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconButtonNormal: {
    display: 'flex',
    padding: '0.25rem',
    width: '1.25rem',
    height: '1.25rem',
  },
  containerShowPopup: {
    position: 'absolute',
    marginTop: '10px',
    display: 'flex',
    left: '1.5rem',
    padding: '0.5rem',
    backgroundColor: '#ffffff',
    flexDirection: 'column',
    borderRadius: '0.5rem',
    width: '185px',
    boxShadow: `rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px`,
    zIndex: 100,
  },
}));

export function BlockStyleButton({
  toggleStyleControl,
  active = false,
  label,
  icon,
  style,
}: {
  toggleStyleControl: (blockType: string) => void;
  active: boolean;
  label: string;
  icon: IconName;
  style: string;
}) {
  const classes = useStyles();

  return (
    <Button
      onMouseDown={e => {
        e.preventDefault();
        toggleStyleControl(style);
      }}
      className={classes.blockStyleButton}
      style={{
        backgroundColor: active ? 'rgba(0, 0, 0, 0.06)' : '#fff',
      }}>
      <Icon name={icon} size="16px" color="text-neutral-80" />
      <span
        style={{
          flex: '1',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 400,
          textAlign: 'left',
          marginLeft: '0.5rem',
        }}>
        {label}
      </span>
    </Button>
  );
}

export default function BlockStyleControls({
  editorState,
  setEditorState,
  styleMap = EDITOR_BLOCK_STYLE_MAP,
}: {
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
  styleMap?: any;
}) {
  const classes = useStyles();

  const popupBlockControlRef = useRef(null);
  const [isShowPopup, setShowPopup] = useState<boolean>(false);

  useClickOutside(popupBlockControlRef, () => setShowPopup(false));

  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const onToggle = (type: string) => {
    const newState = RichUtils.toggleBlockType(editorState, type);
    setEditorState(newState);
  };

  const toggleStyleControl = (style: string) => {
    onToggle(style);
    setShowPopup(false);
  };

  const defaultIcon = faText;
  const defaultTranslationKey = 'Normal';

  return (
    <div ref={popupBlockControlRef} style={{width: 'fit-content'}}>
      <Button
        className={classes.buttonNormal}
        variant="outlined"
        onMouseDown={e => {
          e.preventDefault();
          setShowPopup(!isShowPopup);
        }}>
        <Icon
          name={styleMap[blockType]?.icon || defaultIcon}
          size="16px"
          color="text-neutral-40"
        />
        <span className={classes.labelButtonNormal}>
          {styleMap[blockType]?.label || defaultTranslationKey}
        </span>
        <span className={classes.iconButtonNormal}>
          <Icon name={faAngleDown} size="12px" color="text-neutral-40" />
        </span>
      </Button>
      {isShowPopup && (
        <div className={classes.containerShowPopup}>
          {Object.keys(styleMap).map(style => {
            const active = style === blockType;
            const translationKey =
              styleMap[style]?.label || defaultTranslationKey;
            const icon = styleMap[style]?.icon || defaultIcon;

            return (
              <BlockStyleButton
                key={style}
                active={active}
                // @ts-ignore
                label={translationKey}
                toggleStyleControl={toggleStyleControl}
                style={style}
                icon={icon}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
