// @ts-ignore
import UilChannel from '@iconscout/react-unicons/icons/uil-channel';

import CommunityList from './CommunityList';
import CommunityShow from './CommunityShow';
import CommunityCreate from './CommunityCreate';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

const resource = {
  list: CommunityList,
  show: CommunityShow,
  create: CommunityCreate,
  iconAdd: GroupAddIcon,
  icon: UilChannel,
};

export default resource;
