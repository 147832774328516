import {memoize, isEmpty} from 'lodash';

/**
 * Custom minLength based on react-admin > minLength
 * To not count the spaces at the start and the end of the string by using trim
 *
 * @example
 * // Will return error if value is '   ' or 'a   ' or '   b'
 * // Will return true if value is 'a b' or 'a  b'
 * <SomeInputField validate={[minLength(3)]} />
 */
export const minLength = memoize(
  (min, message = 'ra.validation.minLength') =>
    (value: string) =>
      !isEmpty(value) && value.trim().length < min
        ? {
            message,
            args: {min},
          }
        : undefined,
);
