import {Box} from '@material-ui/core';
import React, {useState} from 'react';
import {
  ImageField,
  ImageInput as RAImageInput,
  ImageInputProps as RAImageInputProps,
} from 'react-admin';

const maxImgSize = 10000000; // 10MB

interface ImageInputProps extends RAImageInputProps {
  defaultImageLink?: string;
}

/**
 * As the RNImageInput doesn't accept the file input is string or null,
 * we handle it by checking the value and wrap it inside an object
 */
const formatImage = (value: unknown) => {
  if (!value || typeof value === 'string') return {url: value};

  return value;
};

const ImageInput: React.FC<ImageInputProps> = ({
  defaultImageLink,
  ...props
}: ImageInputProps) => {
  const {source} = props;
  const [image, setImage] = useState(defaultImageLink || '');

  const handleAddImage = (fileObj: Array<File>) => {
    setImage(fileObj[0].name);
  };

  return (
    <Box overflow="hidden">
      <RAImageInput
        accept="image/*"
        maxSize={maxImgSize}
        format={formatImage}
        options={{
          onDrop: handleAddImage,
          onRemove: () => setImage(''),
        }}
        {...props}>
        <ImageField source="src" />
      </RAImageInput>
      {!image && <ImageField source={source} />}
    </Box>
  );
};

export default ImageInput;
