import {EditorState} from 'draft-js';
import React from 'react';
import BlockStyleControls from '../DraftJSEditor/EditorBlockStyleControls';
import InlineStyleControls from '../DraftJSEditor/EditorInlineStyleControls';
import AddLinkButton from '../DraftJSEditor/AddLinkButton';
import BaseEditor from '../DraftJSEditor/BaseEditor';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  },
  styleControls: {
    display: 'flex',
    position: 'relative',
    columnGap: '1rem',
  },
  baseEditor: {
    overflowY: 'auto',
    height: '50vh',
  },
}));

interface ChangelogsComposerProps {
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
}

export default function ChangelogsComposer({
  editorState,
  setEditorState,
}: ChangelogsComposerProps): JSX.Element {
  const classes = useStyles();

  return (
    <div
      className="modal-post"
      style={{
        display: 'flex',
        backgroundColor: '#ffffff',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        rowGap: '1rem',
        flex: 1,
      }}>
      {/* Edit screen */}
      <div className={classes.container}>
        <div className={classes.styleControls}>
          <BlockStyleControls
            editorState={editorState}
            setEditorState={setEditorState}
          />
          <InlineStyleControls
            editorState={editorState}
            setEditorState={setEditorState}
          />
          <AddLinkButton
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </div>
        <div className={classes.baseEditor}>
          <BaseEditor
            editorState={editorState}
            setEditorState={setEditorState}
            readOnly={false}
            placeholder="Write a logs..."
          />
        </div>
      </div>
    </div>
  );
}
