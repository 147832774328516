import {Record} from 'react-admin';

export const replaceEmptyAttributesWithDefaultValue = (
  record: Record,
  defaultRecord: Record,
): Record => {
  const newRecord = {...record};

  Object.keys(newRecord).map(key => {
    if (newRecord[key] === '') {
      newRecord[key] = defaultRecord[key];
    }
  });

  return newRecord;
};

export const removeNotEditableAttributes = (
  record: Record,
  editableFields: string[],
): Record => {
  const newRecord = {...record};

  Object.keys(record).map(key => {
    if (!editableFields.includes(key)) {
      delete newRecord[key];
    }
  });

  return newRecord;
};
