import {stringify} from 'querystring';
import {Identifier, GetListResult, Record} from 'react-admin';

import {IQuery} from '../../interfaces/common';
import {ICommunityMember} from '../../interfaces/ICommunity';
import {httpClient, mapCommunityMember} from '../../utils';
import {API_ADMIN_URL} from '../../constants';

export type CommunityProvider = {
  getCommunityMembers: <RecordType extends Record = ICommunityMember>(
    communityId: Identifier,
    query: IQuery,
  ) => Promise<GetListResult<RecordType>>;
  getCommunityJoinableUsers: <RecordType extends Record = ICommunityMember>(
    communityId: Identifier,
    query: IQuery,
  ) => Promise<GetListResult<RecordType>>;
  assignCommunityAdmin: (
    communityId: number,
    userIds: number[],
  ) => Promise<unknown>;
  revokeCommunityAdmin: (
    communityId: number,
    userIds: number[],
  ) => Promise<unknown>;
  addUserToCommunity: (props: {
    communityId: Identifier;
    userIds: Identifier[];
    adminId: Identifier;
  }) => Promise<unknown>;
  removeUsersFromCommunity: (props: {
    communityId: Identifier;
    userIds: Identifier[];
    adminId: Identifier;
  }) => Promise<unknown>;
};

const communityProvider: CommunityProvider = {
  getCommunityMembers: (communityId: Identifier, query: IQuery) => {
    /**
     * Somehow there is field groupId right in the queryString when stringify(query)
     * although that field was not there. Therefore, we need to remove it.
     */
    const queryString = stringify(query).replace(/(communityId=)\d+&/g, '');
    const url =
      API_ADMIN_URL + `/communities/${communityId}/members?${queryString}`;

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data.map(mapCommunityMember),
          total: json.meta.total,
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },
  getCommunityJoinableUsers: (communityId: Identifier, query: IQuery) => {
    delete query.communityId;

    // FIXME: remove delete query.sort, when BE supports sorting
    delete query.sort;

    const url =
      API_ADMIN_URL +
      `/communities/${communityId}/joinable-users?${stringify(query)}`;

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data,
          total: json.meta.total,
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },
  assignCommunityAdmin: (communityId: number, userIds: number[]) => {
    const url = API_ADMIN_URL + `/communities/${communityId}/assign-admin`;

    const body = {
      user_ids: [...userIds],
    };

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(({json}) => {
        return Promise.resolve(json);
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },
  revokeCommunityAdmin: (communityId: number, userIds: number[]) => {
    const url = API_ADMIN_URL + `/communities/${communityId}/revoke-admin`;

    const body = {
      user_ids: [...userIds],
    };

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(({json}) => {
        return Promise.resolve(json);
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },
  addUserToCommunity: ({communityId, userIds, adminId}) => {
    const url = API_ADMIN_URL + `/communities/${communityId}/members`;
    const body = {
      user_ids: [...userIds],
      actor_id: adminId,
    };

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then(({json}) => {
        return Promise.resolve(json);
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },
  removeUsersFromCommunity: ({communityId, userIds, adminId}) => {
    const url = API_ADMIN_URL + `/communities/${communityId}/members`;
    const body = {
      user_ids: userIds,
      admin_id: adminId,
    };

    return httpClient(url, {
      method: 'DELETE',
      body: JSON.stringify(body),
    })
      .then(({json}) => {
        return Promise.resolve(json);
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },
};

export default communityProvider;
