import React, { useEffect, useState } from 'react';
import {
  BulkExportButton,
  Datagrid,
  ExportButton,
  FieldProps,
  Identifier,
  List,
  ListProps,
  Loading,
  TextInput,
  TopToolbar,
  useDataProvider,
  useListContext,
  useRecordContext,
} from 'react-admin';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import { IGroupRole } from '../../interfaces/IGroup';
import FullnameField from '../../users/FullnameField';
import RolesField from './RolesField';
import AddMemberButton from '../../components/Buttons/AddMemberButton';
import { useCommon } from '../../hooks/common';
import { IUser } from '../../interfaces/IUser';
import { BASEPATHS, RESOURCES, SortOrder } from '../../common/constants';

const GroupMembers: React.FC<FieldProps> = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { adminIdToEditMemberList } = useCommon();
  const [numOfMembers, setNumOfMembers] = useState(0);
  const [groupRoles, setGroupRoles] = useState<IGroupRole[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentSelectedAdmin, setCurrentSelectedAdmin] = useState<
    IUser | undefined
  >();

  const { id: groupId } = record;

  useEffect(() => {
    if (!record) return;

    dataProvider
      .getGroupMembers(groupId)
      .then((res: { total: number }) => setNumOfMembers(res.total ?? 0))
      .catch((err: { message: string }) => setError(err.message));

    dataProvider
      .getGroupRoles(groupId)
      .then((res: { data: [] }) => setGroupRoles(res.data))
      .catch((err: { message: string }) => setError(err.message))
      .finally(() => setLoading(false));
  }, [record]);

  useEffect(() => {
    if (!adminIdToEditMemberList) {
      setCurrentSelectedAdmin(undefined);
      return;
    }

    dataProvider
      .getOne(RESOURCES.USERS, { id: adminIdToEditMemberList })
      .then(response => {
        const adminRecord = { ...response?.data } as unknown as IUser;
        setCurrentSelectedAdmin(adminRecord);
      });
  }, [adminIdToEditMemberList]);

  if (!record) return null;

  if (loading) return <Loading />;
  if (error)
    return <Typography>Error when get member list, {error}</Typography>;
  if (numOfMembers === 0)
    return <Typography>There is no member in this group.</Typography>;

  const renderAdminPicker = () => {
    if (numOfMembers === 0) return <></>;

    const textPickingAdmin = adminIdToEditMemberList
      ? 'You are editing member list under the name of this admin.'
      : '';

    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1">{textPickingAdmin}</Typography>
        </Box>
        {adminIdToEditMemberList && (
          <FullnameField record={currentSelectedAdmin} />
        )}
      </>
    );
  };

  return (
    <>
      <Box m="1rem 0">{renderAdminPicker()}</Box>
      <Typography variant="body1">Number of members: {numOfMembers}</Typography>
      <GroupMembersList groupId={groupId} groupRoles={groupRoles} />
    </>
  );
};

GroupMembers.propTypes = {
  record: PropTypes.any,
};

const groupMembersListFilter = [
  // the q filter triggers a full-text search on all fields
  <TextInput
    key="search"
    label="Search"
    placeholder="Enter name or email"
    source="key"
    alwaysOn
    style={{ width: 400 }}
  />,
];

interface groupIdProp {
  groupId: Identifier;
}

interface CustomBulkActionProps extends groupIdProp {
  selectedIds: Identifier[];
}

const BulkActionsButtons = ({ groupId, selectedIds }: CustomBulkActionProps) => {
  /**
   * Need to add dummy requiredGroupId, in order to fetch the right members list for the current group.
   * Because BulkExportButton only use selectedIds to fetch via getMany function.
   * We put an if case to handle this one in dataProvider.getMany.
   *
   * The requiredGroupId will be negative for checking in getMany
   */
  const requiredGroupId = -groupId;
  const customSelectedIds = [requiredGroupId, ...selectedIds];

  return (
    <React.Fragment>
      <BulkExportButton selectedIds={customSelectedIds} />
    </React.Fragment>
  );
};

const GroupMembersListActions = ({ groupId }: groupIdProp) => (
  <TopToolbar>
    <ExportButton
      basePath={BASEPATHS.GROUP_MEMBERS}
      resource={RESOURCES.GROUP_MEMBERS}
    />
  </TopToolbar>
);

GroupMembersListActions.propTypes = {
  groupId: PropTypes.any,
};

const GroupMembersListEmpty: React.FC<groupIdProp> = ({
  groupId,
}: groupIdProp) => <AddMemberButton valueId={groupId} valueType="group" />;

GroupMembersListEmpty.propTypes = {
  groupId: PropTypes.any,
};

interface GroupMembersProps extends ListProps {
  groupId: Identifier;
  groupRoles?: IGroupRole[];
}

const GroupMembersList: React.FC<GroupMembersProps> = ({
  groupId,
  groupRoles,
  ...props
}: GroupMembersProps) => {
  const { selectedIds } = useListContext(props);

  return (
    <List
      {...props}
      filters={groupMembersListFilter}
      filter={{ groupId }}
      actions={<GroupMembersListActions groupId={groupId} />}
      title=" "
      bulkActionButtons={
        <BulkActionsButtons groupId={groupId} selectedIds={selectedIds} />
      }
      sort={{
        field: 'roles',
        order: SortOrder.DESC,
      }}
      empty={<GroupMembersListEmpty groupId={groupId} />}>
      <Datagrid>
        <FullnameField />
        <RolesField />
      </Datagrid>
    </List>
  );
};

GroupMembersList.propTypes = {
  groupId: PropTypes.any,
  groupRoles: PropTypes.array,
};

GroupMembersList.defaultProps = {
  basePath: BASEPATHS.GROUP_MEMBERS,
  resource: RESOURCES.GROUP_MEMBERS,
};

export default GroupMembers;
