import {
  email,
  minLength as RAMinLength,
  maxLength,
  number,
  regex,
  required,
  Validator,
} from 'react-admin';
import {isOnProduction} from '../../utils';
import {USER_VALIDATION_RULE} from '../constants';
import {minLength} from './string';

export const passwordRegex = (): Validator => {
  if (isOnProduction()) {
    return regex(
      USER_VALIDATION_RULE.PASSWORD.STRICT_REGEX,
      `Your password must have at least one lowercase letter, at least one uppercase letter, at least one number, and at least one symbol (e.g. "~!@#$%^&*()")`,
    );
  }

  return regex(
    USER_VALIDATION_RULE.PASSWORD.EASY_REGEX,
    `Whitespace is not allowed in your password`,
  );
};

const SPECIAL_CHARACTERS_REGEX =
  USER_VALIDATION_RULE.PASSWORD.SPECIAL_CHARACTERS_REGEX;

const passwordShortValidate =
  (message = 'Passwords must be at least 8-20 characters') =>
  (value: any) =>
    value && value.length < USER_VALIDATION_RULE.PASSWORD.MIN_LENGTH
      ? message
      : undefined;

const passwordLongValidate =
  (message = 'Passwords can not exceed 20 characters') =>
  (value: any) =>
    value && value.length > USER_VALIDATION_RULE.PASSWORD.MAX_LENGTH
      ? message
      : undefined;

const lowerCaseValidate =
  (message = 'Please use at least one lower case letter (a-z)') =>
  (value: any) =>
    value && !/[a-z]/.test(value) ? message : undefined;

const upperCaseValidate =
  (message = 'Please use at least one lower case letter (A-Z)') =>
  (value: any) =>
    value && !/[A-Z]/.test(value) ? message : undefined;

const digitsValidate =
  (message = 'Please use at least one digit (0-9)') =>
  (value: any) =>
    value && !/[0-9]/.test(value) ? message : undefined;

const specialCharactersValidate =
  (
    message = 'Please use at least one special character, including common punctuation, symbols, or spaces',
  ) =>
  (value: any) =>
    value && !SPECIAL_CHARACTERS_REGEX.test(value) ? message : undefined;

export const userValidators = {
  fullname: [
    required(),
    minLength(USER_VALIDATION_RULE.FULLNAME.MIN_LENGTH),
    maxLength(USER_VALIDATION_RULE.FULLNAME.MAX_LENGTH),
  ],
  username: [
    required(),
    minLength(USER_VALIDATION_RULE.USERNAME.MIN_LENGTH),
    maxLength(USER_VALIDATION_RULE.USERNAME.MAX_LENGTH),
    regex(
      /**
       * Accept alphabets, number, and dot
       * Only start with an alphabet
       */
      /^([A-Za-z])[A-Za-z0-9.]*$/,
      'Username only allows alphabets, numbers, dot and starts with an alphabet',
    ),
  ],
  email: [
    email('Wrong email format'),
    required(),
    maxLength(USER_VALIDATION_RULE.EMAIL.MAX_LENGTH),
  ],
  password: [
    required(),
    /**
     * Passwords must be between 8 and 20 characters long.
     */
    passwordShortValidate(),
    /**
     * Passwords can not exceed 20 characters
     */
    passwordLongValidate(),
    /**
     * Lower case letter (a-z)
     */
    lowerCaseValidate(),
    /**
     * Lower case letter (A-Z)
     */
    upperCaseValidate(),
    /**
     * Lower case letter (0-9)
     */
    digitsValidate(),
    /**
     * Special characters including common punctuation, symbols, and spacing
     * Common punctuation: . , ; : ! ?
     * Symbols: @ # $ % ^ & * ( ) _ + - = { } [ ] | \ < >
     * Spacing: (blank space character)
     */
    specialCharactersValidate(),
  ],
  phone: [
    number(),
    RAMinLength(USER_VALIDATION_RULE.PHONE.MIN_LENGTH),
    maxLength(USER_VALIDATION_RULE.PHONE.MAX_LENGTH),
  ],
  address: [maxLength(125)],
  description: [maxLength(USER_VALIDATION_RULE.DESCRIPTION.MAX_LENGTH)],
};
