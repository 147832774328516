import React, { useEffect, useState } from 'react';
import {
  FieldProps,
  useDataProvider,
  useRecordContext,
  Loading,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { IUser } from '../../interfaces/IUser';
import CommunityMembersList from './CommunityMembersList';
import FullnameField from '../../users/FullnameField';
import { useCommon } from '../../hooks/common';
import { RESOURCES } from '../../common/constants';

const CommunityMembers: React.FC<FieldProps> = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [numOfMembers, setNumOfMembers] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentSelectedAdmin, setCurrentSelectedAdmin] = useState<
    IUser | undefined
  >();
  const { adminIdToEditMemberList } = useCommon();

  const { id: communityId } = record;

  useEffect(() => {
    if (!record) return;

    dataProvider
      .getCommunityMembers(communityId)
      .then((res: { total: number }) => setNumOfMembers(res.total ?? 0))
      .catch((err: { message: string }) => setError(err.message))
      .finally(() => setLoading(false));
  }, [record]);

  useEffect(() => {
    if (!adminIdToEditMemberList) {
      setCurrentSelectedAdmin(undefined);
      return;
    }

    dataProvider
      .getOne(RESOURCES.USERS, { id: adminIdToEditMemberList })
      .then(response => {
        const adminRecord = { ...response?.data } as unknown as IUser;
        setCurrentSelectedAdmin(adminRecord);
      });
  }, [adminIdToEditMemberList]);

  if (!record) return null;

  if (loading) return <Loading />;
  if (error)
    return <Typography>Error when get member list, {error}</Typography>;
  if (numOfMembers === 0)
    return <Typography>There is no member in this community.</Typography>;

  const renderAdminPicker = () => {
    if (numOfMembers === 0) return <></>;

    const textPickingAdmin = adminIdToEditMemberList
      ? 'You are editing member list under the name of this admin.'
      : '';

    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1">{textPickingAdmin}</Typography>
        </Box>
        {adminIdToEditMemberList && (
          <FullnameField record={currentSelectedAdmin} />
        )}
      </>
    );
  };

  return (
    <>
      <Box m="1rem 0">{renderAdminPicker()}</Box>
      <Typography variant="body1">Number of members: {numOfMembers}</Typography>
      <CommunityMembersList communityId={communityId} />
    </>
  );
};

CommunityMembers.propTypes = {
  record: PropTypes.any,
};

export default CommunityMembers;
