import React from 'react';
import {FieldProps} from 'react-admin';
import {Typography} from '@material-ui/core';
import PropTypes from 'prop-types';

import {IUser} from '../interfaces/IUser';

interface Props extends FieldProps<IUser> {
  size?: number;
  clickable?: boolean;
}

const PhoneField: React.FC<Props> = ({record}) => {
  if (!record) return null;

  return (
    <Typography component="span" variant="body2">
      (+{record.country_code}) {record.phone}
    </Typography>
  );
};

PhoneField.displayName = 'PhoneField';

PhoneField.defaultProps = {
  label: 'Phone',
  addLabel: true,
};

PhoneField.propTypes = {
  record: PropTypes.any,
};

export default PhoneField;
