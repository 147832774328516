import React, { useState, useEffect } from 'react'
import Switch from '@material-ui/core/Switch';
import { Typography, makeStyles } from '@material-ui/core';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { useDispatch } from 'react-redux';
import * as actions from './redux/actions';
import { dataProvider } from '../providers';
import { useUsers } from '../hooks';

const useStyles = makeStyles(theme => ({
    verifyUser: {
        display: 'flex',
        marginRight: theme.spacing(2),
        alignItems: 'center',
    },
}));

export const SwitchVerifyUser = () => {
    const classes = useStyles()
    const record = useRecordContext()
    const dispatch = useDispatch();
    const notify = useNotify()
    const refresh = useRefresh();
    const { setClosePopupWindow } = useUsers();

    const [verifyUser, setVerifyUser] = useState(false)
    const [isProcess, setIsProcess] = useState(false)

    useEffect(() => {
        if (record)
            setVerifyUser(record.is_verified)
    }, [record])

    const callApiVerifyUser = async (verify: boolean) => {
        await dataProvider
            .toggleVerifyUser({ userId: record.id, isVerified: verify })
            .then(() => {
                setIsProcess(false)
                refresh()
            })
            .catch((err: any) => {
                const errorMessage = `Error: ${err?.meta?.message}`
                notify(errorMessage, 'error')
                setVerifyUser((prevUndo) => !prevUndo)
                setIsProcess(false)
            });
    }

    const undoVerifyHandler = () => {
        setIsProcess(false)
        setVerifyUser((prevUndo) => !prevUndo)
    }

    const changeVerifyUserHandler = () => {
        if (isProcess || setClosePopupWindow) return

        setIsProcess(true)
        setVerifyUser((prevUndo) => !prevUndo)
        dispatch(actions.openToastUndo({
            message: verifyUser ? `Un-verifying user's profile` : `Verifying user's profile`,
            onUndo: () => undoVerifyHandler(),
            onCloseWithoutUndoClicked: () => callApiVerifyUser(!verifyUser),
        }));
    }

    return (
        <div className={classes.verifyUser}>
            <Switch
                checked={verifyUser}
                onChange={changeVerifyUserHandler}
                color="primary"
            />
            <Typography style={{ fontSize: '13px', fontWeight: 500 }} color='primary'>
                VERIFY THIS USER
            </Typography>
        </div>
    )
}
