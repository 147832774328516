/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState, useCallback } from 'react'

import CloseIcon from '@material-ui/icons/Close';

import { Button, IconButton, Snackbar } from '@material-ui/core'
import { useUsers } from '../hooks';
import { useWindowUnloadPrevention } from '../common/common';
import { useDispatch } from 'react-redux';
import * as actions from '../users/redux/actions';

export interface ToastProps {
    duration?: number;
    message: string;
    onUndo: () => void;
    onCloseWithoutUndoClicked: () => void;
}

const ToastUndoController: React.FC = () => {
    const dispatch = useDispatch();

    const { visibleToastUndo, setClosePopupWindow, toastProps } = useUsers();

    const [remainingTime, setRemainingTime] = useState<number>(3)
    const [isUndoClicked, setIsUndoClicked] = useState(false)

    const countDownRemainingTimeRef = useRef<NodeJS.Timer | null>(null)

    const startCountDownRemainingTime = () => {
        countDownRemainingTimeRef.current = setInterval(() => {
            setRemainingTime((remain) => remain - 1)
        }, 1000)
    }

    const stopCountDown = () => {
        if (countDownRemainingTimeRef.current) {
            clearInterval(countDownRemainingTimeRef.current)
        }
    }

    const onClose = () => {
        if (isUndoClicked) return

        dispatch(actions.closeToastUndo());
    }

    const startNotify = useCallback(() => {
        setRemainingTime(toastProps?.duration || 3)
        setIsUndoClicked(false)
        startCountDownRemainingTime()
    }, [toastProps?.duration])

    const onUndoToastHandler = () => {
        setIsUndoClicked(true)
        toastProps?.onUndo()
        dispatch(actions.closeToastUndo());
        stopCountDown()
    }

    useWindowUnloadPrevention({
        enabled: visibleToastUndo,
        onAlert: () => {
            toastProps?.onCloseWithoutUndoClicked()
            dispatch(actions.setClosePopupWindowToastUndo());
        }
    })

    useEffect(() => {
        if (remainingTime === 0) {
            if (setClosePopupWindow)
                dispatch(actions.setClosePopupWindowToastUndo());
            else toastProps?.onCloseWithoutUndoClicked()
            dispatch(actions.closeToastUndo());
            stopCountDown()
        }
    }, [remainingTime])

    useEffect(() => {
        if (visibleToastUndo) {
            startNotify()
        }
    }, [visibleToastUndo])

    return <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        open={visibleToastUndo}
        message={toastProps?.message}
        action={
            <React.Fragment>
                <Button color="secondary" size="small" onClick={() => onUndoToastHandler()}>
                    UNDO ({remainingTime})
                </Button>
                <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
        }
    />
}

export default ToastUndoController
