import React from 'react';
import {Identifier} from 'react-admin';
import {Box, CardContent, IconButton, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import JoinableMemberList from './JoinableMemberList';
import {ValueType} from '../AdminPickerButton';
import {BASE_DRAWER_WIDTH_HAVING_LIST} from '../../../common/constants';

interface AddMemberDrawerProps {
  valueId: Identifier;
  valueType: ValueType;
  onClose?: () => void;
}

const AddMemberDrawer: React.FC<AddMemberDrawerProps> = ({
  valueId,
  valueType,
  onClose,
}) => {
  return (
    <CardContent
      style={{
        minWidth: BASE_DRAWER_WIDTH_HAVING_LIST,
        paddingTop: 0,
        paddingLeft: 16,
      }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="h5">{`Add members to this ${valueType}`}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography>Pick one or more users below to add</Typography>
      <JoinableMemberList valueId={valueId} valueType={valueType} />
    </CardContent>
  );
};

AddMemberDrawer.propTypes = {
  valueId: PropTypes.any,
  valueType: PropTypes.any,
  onClose: PropTypes.any,
};

export default AddMemberDrawer;
