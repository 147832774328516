import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {
  Button,
  Identifier,
  Record,
  Title,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Box, makeStyles, Typography} from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import UndoIcon from '@material-ui/icons/Undo';
import SaveIcon from '@material-ui/icons/Save';
import {toggleExpandedForAll} from '@nosferatu500/react-sortable-tree';
import {isEqual} from 'lodash';

import GroupTree from '../GroupTree';
import {IGroupTree} from '../../interfaces/IGroup';
import {setReloadGroupTree} from '../redux/actions';
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel';
import {ERROR_MOVING_GROUP_UNDER_THE_SAME_OUTER_GROUP} from '../common';
import {RESOURCES} from '../../common/constants';

const styles = makeStyles(() => ({
  editGroupButton: {
    marginLeft: '0.5rem',
  },
}));

type StepType = {
  targetGroupId: Identifier;
  targetGroupName: string;
  newOuterGroupId: Identifier;
  newOuterGroupName: string;
};

type OnMoveNodeParamsType = {
  node: IGroupTree;
  nextParentNode: IGroupTree;
  nextPath: number[];
};

export interface EditGroupTreeProps {
  mode: 'community' | 'group';
}

const EditGroupTree = ({mode}: EditGroupTreeProps): JSX.Element => {
  const classes = styles();
  const {id} = useParams() as {id: string};
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const notify = useNotify();

  const [treeData, setTreeData] = useState<IGroupTree[]>([]);
  const [treeDataStack, setTreeDataStack] = useState<Array<IGroupTree[]>>([]);
  const [savingNewGroupTree, setSavingNewGroupTree] = useState(false);
  const [progress, setProgress] = useState(0);

  const [steps, setSteps] = useState<StepType[]>([]);

  const header = 'Move the below groups to edit group tree';

  const fetchAndSetTreeData = (groupId: Identifier) => {
    dataProvider
      .getInnerGroups(groupId)
      .then((res: Record) => {
        const rootTreeData = toggleExpandedForAll({
          treeData: [{...res.data}],
          expanded: true,
        }) as unknown as IGroupTree[];
        setTreeData(rootTreeData);
        setTreeDataStack([rootTreeData]);
      })
      .catch(() => {
        notify('Something wrong while retrieving tree', 'error');
        setTreeData([]);
        setTreeDataStack([]);
      });
  };

  const preProcessData = async () => {
    /**
     * As this component is used for both community and group,
     * we need to use this one to map community to groupId
     */
    let groupId = id;
    if (mode === 'community') {
      const communityData = await dataProvider.getOne(RESOURCES.COMMUNITIES, {
        id,
      });
      groupId = communityData.data.group_id;
    }
    fetchAndSetTreeData(groupId);
  };

  useEffect(() => {
    preProcessData();
    return () => {
      setTreeData([]);
      setTreeDataStack([]);
      setSteps([]);
      setProgress(0);
    };
  }, [id]);

  const onChangeTreeData = (nextTreeData: IGroupTree[]) => {
    if (isEqual(treeData, nextTreeData)) {
      return;
    }

    const nextTreeDataStack = [...treeDataStack];
    nextTreeDataStack.push(treeData);
    setTreeDataStack(nextTreeDataStack);

    setTreeData(nextTreeData);
  };

  const isMoveValid = (onMoveNodeParams: OnMoveNodeParamsType) => {
    const {node, nextParentNode, nextPath} = onMoveNodeParams;

    if (nextPath.length === 1) {
      notify('Do not move a group to the root level', 'warning');
      return false;
    }

    /**
     * Check still under the same parent
     * NOTE: not using parent_id as react-sortable-tree auto change it into parentId
     */
    if (node.parentId === nextParentNode.id) {
      return false;
    }

    return true;
  };

  const onMoveNode = (onMoveNodeParams: OnMoveNodeParamsType) => {
    const {node, nextParentNode} = onMoveNodeParams;
    if (!isMoveValid(onMoveNodeParams)) {
      undoTreeData();
      return;
    }

    const nextStep = {
      targetGroupId: node.id,
      targetGroupName: node.name,
      newOuterGroupId: nextParentNode.id,
      newOuterGroupName: nextParentNode.name,
    } as unknown as StepType;

    setSteps([...steps, nextStep]);
  };

  const resetTreeData = () => {
    const rootTreeData = treeDataStack[0];
    setTreeData(rootTreeData);
    setTreeDataStack([rootTreeData]);
  };

  const undoTreeData = () => {
    if (treeDataStack.length === 2) {
      resetTreeData();
      return;
    } else if (treeDataStack.length === 1) return;

    const nextTreeDataStack = [...treeDataStack];
    const prevTreeData = nextTreeDataStack.pop() as IGroupTree[];
    setTreeData(prevTreeData);
    setTreeDataStack(nextTreeDataStack);

    const nextSteps = [...steps];
    nextSteps.pop();
    setSteps(nextSteps);
  };

  const moveGroupToNewIndex = (step: StepType) => {
    const {targetGroupId, newOuterGroupId} = step;

    return new Promise<unknown>((resolve, reject) => {
      dataProvider
        .moveGroup(targetGroupId, newOuterGroupId)
        .then((res: unknown) => resolve(res))
        .catch((err: unknown) => reject(err));
    });
  };

  const saveEditing = async () => {
    if (isEqual(treeDataStack[0], treeData)) {
      return;
    }

    setSavingNewGroupTree(true);

    try {
      let newProgress = 0;
      setProgress(newProgress);

      for (let index = 0; index < steps.length; index++) {
        const step = steps[index];
        await moveGroupToNewIndex(step).catch(error => {
          /**
           * Ignore this error instead of checking the group is moved under the same group,
           * as the other approach may skip an important one among the needed steps
           */
          if (
            error.meta.message === ERROR_MOVING_GROUP_UNDER_THE_SAME_OUTER_GROUP
          ) {
            return;
          }

          const {targetGroupName, newOuterGroupName} = step;
          const errorMessage = `Error moving "${targetGroupName}" to under "${newOuterGroupName}". ${error.meta.message}.`;
          throw new Error(errorMessage);
        });

        newProgress = ((index + 1) * 100) / steps.length;
        setProgress(newProgress);
      }

      notify(`Update tree successfully`, 'success');
      const redirectUrl =
        mode === 'community' ? `/communities/${id}/show` : `/groups/${id}/show`;

      // Wait for a while before redirect to show progress status is 100%
      setTimeout(() => {
        redirect(redirectUrl);
        dispatch(setReloadGroupTree(true));
      }, 500);
    } catch (error) {
      let errorMessage = 'Something went wrong when update group tree';
      if (error instanceof Error) errorMessage = error.message;

      notify(errorMessage, 'error');
      resetTreeData();
    }

    setSavingNewGroupTree(false);
  };

  const renderProgress = () => {
    if (!savingNewGroupTree && progress === 0) return null;

    return (
      <Box>
        <LinearProgressWithLabel value={progress} color="secondary" />
      </Box>
    );
  };

  return (
    <Card>
      <Title title="Edit Group Tree" />
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h6" gutterBottom>
            {header}
          </Typography>
          <Box>
            <Button
              label="Undo"
              disabled={treeDataStack.length <= 1 || savingNewGroupTree}
              variant="contained"
              color="inherit"
              onClick={undoTreeData}>
              <UndoIcon />
            </Button>
            <Button
              label="Reset"
              className={classes.editGroupButton}
              disabled={savingNewGroupTree}
              variant="contained"
              color="inherit"
              onClick={resetTreeData}>
              <RestoreIcon />
            </Button>
            <Button
              label="Save"
              disabled={treeDataStack.length <= 1 || savingNewGroupTree}
              className={classes.editGroupButton}
              variant="contained"
              color="primary"
              onClick={saveEditing}>
              <SaveIcon />
            </Button>
          </Box>
        </Box>
        {renderProgress()}
        <Box height="80vh">
          <GroupTree
            treeData={treeData}
            onChange={onChangeTreeData}
            clickableNode={false}
            canDrag={({path}) => {
              if (path.length === 1) return false;

              return !savingNewGroupTree;
            }}
            onMoveNode={onMoveNode}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default EditGroupTree;
