import React from 'react';
import {Confirm} from 'react-admin';
import {useDispatch} from 'react-redux';
import SecurityIcon from '@material-ui/icons/Security';

import actions from '../../../store/common/actions';
import {useCommon} from '../../../hooks/common';

const AlertMustPickAdmin: React.FC = () => {
  const {showAlertMustPickAdmin} = useCommon();
  const dispatch = useDispatch();

  const closeAlert = () => dispatch(actions.setShowAlertMustPickAdmin(false));

  const onConfirm = () => {
    closeAlert();
    dispatch(actions.setShowAdminPickerDrawer(true));
  };

  return (
    <Confirm
      isOpen={showAlertMustPickAdmin}
      title="Admin not set"
      content="You must select an admin to perform this action"
      confirm="Pick admin"
      ConfirmIcon={SecurityIcon}
      onConfirm={onConfirm}
      onClose={closeAlert}
      CancelIcon={'div'}
    />
  );
};

export default AlertMustPickAdmin;
