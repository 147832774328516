import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export default (): JSX.Element => (
  <Card>
    <CardContent>
      <h1>Welcome to Bein Staff</h1>
      Trang Admin cho BIC Staffs trên Web
    </CardContent>
  </Card>
);
