import {maxLength, required} from 'react-admin';
import {COMMUNITY_VALIDATION_RULE} from '../constants';
import {minLength} from './string';

export const communityValidators = {
  name: [
    required(),
    minLength(COMMUNITY_VALIDATION_RULE.NAME.MIN_LENGTH),
    maxLength(COMMUNITY_VALIDATION_RULE.NAME.MAX_LENGTH),
  ],
  slug: [
    minLength(COMMUNITY_VALIDATION_RULE.SLUG.MIN_LENGTH),
    maxLength(COMMUNITY_VALIDATION_RULE.SLUG.MAX_LENGTH),
  ],
  ownerId: [required()],
  description: [maxLength(COMMUNITY_VALIDATION_RULE.DESCRIPTION.MAX_LENGTH)],
};
