import React, {Component, ReactNode, useCallback, useRef} from 'react';
import 'draft-js/dist/Draft.css';

import Editor, {EditorPlugin} from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import {
  ContentState,
  convertFromHTML,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js';
import {
  handleDraftEditorPastedText,
  onDraftEditorCopy,
  onDraftEditorCut,
  // @ts-ignore
} from 'draftjs-conductor';
import {filterBlockTypes} from 'draftjs-filters';

import styles from '../../styles/BaseEditor.module.css';

import {
  customStyleMap,
  EditorSupportedBlockStyles,
} from '../../constants/draftjsEditor';
import AnchorComponent from './AnchorComponent';
import {anchorDecoratorStrategy} from './draftjsEditor';

export interface EditorProps {
  className?: string;
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
  forwardedRef?: any;
  children?: ReactNode;
  onFilesChange?: (
    fileList?: Blob[] | File[] | FileList | null,
  ) => void | Promise<void>;
}

export interface BaseEditorProps extends EditorProps {
  readOnly?: boolean;
  placeholder?: string;
}

export interface BaseEditorWithoutPluginsProps extends BaseEditorProps {
  plugins?: EditorPlugin[];
}

export function BaseEditorWithoutPlugin({
  editorState,
  setEditorState,
  readOnly,
  placeholder,
  plugins,
}: BaseEditorWithoutPluginsProps) {
  /**
   * Editor Ref
   */
  const editor = useRef<Editor>(null);
  const onChange = useCallback(
    (value: EditorState) => {
      setEditorState(value);
    },
    [setEditorState],
  );
  /**
   * Handle Editor focus state
   */
  const focusEditor = () => {
    editor.current!.focus();
    /** If editor loose focus, when it refocused move cursor to end */
    const currentSelection = editorState.getSelection();
    if (!currentSelection.getHasFocus()) {
      setEditorState(EditorState.moveFocusToEnd(editorState));
    }
  };
  /**
   * Handle keyboard command on Editor
   */
  const handleKeyCommand = useCallback(
    (command, _) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
      return 'not-handled';
    },
    [editorState, setEditorState],
  );
  /**
   * Handle pasted text event on Editor
   */
  const handlePastedText = useCallback(
    (_text: string, html?: string) => {
      /**
       * If user pasted plain text, then we just let draftjs handle it
       */
      if (!html) return 'not-handled';
      /**
       * If user pasted from another DraftJS instance, then we let draftjs-conductor
       * handle the conversion to draftjs editor state.
       */
      let newEditorState = handleDraftEditorPastedText(html, editorState);
      /**
       * handleDraftEditorPastedText will return new editor state if it recognized
       * that the pasted text is from DraftJS, otherwise it will return false.
       * Reference: https://github.com/thibaudcolas/draftjs-conductor/blob/af0760577abce1c0ed561e5310b6ee9a5a37d936/src/lib/api/copypaste.ts#L197
       */
      if (newEditorState) {
        setEditorState(newEditorState);
        return 'handled';
      }
      /**
       * If user pasted from other rich editor/html source,
       * we need to filter out unsupported block types,
       * those which not supported will be converted to 'unstyled'
       *
       * 1. Get content from pasted html
       */
      const blockFromHtml = convertFromHTML(html);
      const contentFromHtml = ContentState.createFromBlockArray(
        blockFromHtml.contentBlocks,
        blockFromHtml.entityMap,
      );
      /**
       * 2. Filter out unsupported block types from pasted content state
       */
      const filteredContent = filterBlockTypes(
        EditorSupportedBlockStyles,
        contentFromHtml,
      );
      /**
       * 3. Append filtered content right after cursor position (selection state)
       */
      const newContentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        filteredContent.getBlockMap(),
      );
      /**
       * 4. Use EditorState.push to create new editor state and preserve undo stack
       */
      newEditorState = EditorState.push(
        editorState,
        newContentState,
        'insert-fragment',
      );
      /**
       * 5. Finally set that to our new editor state
       */

      setEditorState(newEditorState);
      return 'handled';
    },
    [editorState, setEditorState],
  );

  return (
    <div className={styles.contentItem} onClick={focusEditor}>
      <Editor
        customStyleMap={customStyleMap}
        editorState={editorState}
        handlePastedText={handlePastedText}
        onCopy={onDraftEditorCopy}
        onCut={onDraftEditorCut}
        handleKeyCommand={handleKeyCommand}
        onChange={onChange}
        ref={editor}
        plugins={plugins}
        readOnly={readOnly}
        placeholder={placeholder}
      />
    </div>
  );
}

export default class BaseEditor extends Component<
  BaseEditorProps,
  {editorKey: number}
> {
  constructor(props: BaseEditorProps) {
    super(props);

    this.state = {editorKey: 0};

    this.linkifyPlugin = createLinkifyPlugin({
      target: '_blank',
      component: linkProps => (
        <a
          {...linkProps}
          style={{
            color: 'rgb(0, 122, 255)',
            fontWeight: 400,
            textDecoration: 'underline',
          }}
        />
      ),
    });

    this.anchorDecorator = {
      decorators: [
        {
          strategy: anchorDecoratorStrategy,
          component: AnchorComponent,
        },
      ],
    };
  }

  // Adding type notation to avoid type error:
  private linkifyPlugin: ReturnType<typeof createLinkifyPlugin>;

  private anchorDecorator: EditorPlugin;

  // force update editor when decorator is added
  componentDidUpdate(): void {
    // return early if decorator is already added
    if (this.props.editorState.getDecorator() !== null) return;
    // otherwise force update editor
    this.setState(({editorKey}) => ({editorKey: editorKey + 1}));
  }

  render = () => (
    <BaseEditorWithoutPlugin
      key={this.state.editorKey}
      {...this.props}
      plugins={[this.linkifyPlugin, this.anchorDecorator]}
    />
  );
}
