import {EditorState, convertFromRaw} from 'draft-js';
import {DraftToMarkdownOptions} from '../components/DraftJSEditor/draftjsEditor';
// @ts-ignore
import {markdownToDraft} from '@beincomm/markdown-draft-js';
import {remarkableNewLinePlugin} from '../components/MarkdownRenderer/MarkdownRenderer';

export const getEditorStateFromMarkdown = (
  markdown: string,
  convertOptions?: DraftToMarkdownOptions,
): EditorState => {
  const {remarkableDisableOptions = {}} = convertOptions || {};

  return EditorState.createWithContent(
    convertFromRaw(
      markdownToDraft(markdown, {
        blockStyles: {
          ins_open: 'UNDERLINE',
        },
        remarkableOptions: {
          enable: {
            inline: 'ins',
            block: ['table'],
          },
          disable: remarkableDisableOptions,
        },
        remarkablePlugins: [remarkableNewLinePlugin],
      }),
    ),
  );
};
