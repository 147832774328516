import {CsvUploadError, CsvUploadMessageResponse} from '../interfaces';

export const getErrorsFromCsvUploadResponse = (
  data: CsvUploadMessageResponse[],
): CsvUploadError[] => {
  const errors = data
    .filter(row => !!row.error)
    .map(row => row.error) as CsvUploadError[];
  return errors;
};
