import React from 'react';
import {FieldProps} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

import {IGroupMember} from '../../interfaces/IGroup';
import {BASE_GROUP_ROLES} from '../../common/constants';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: {margin: 4},
});

const RolesField: React.FC<FieldProps<IGroupMember>> = ({record}) => {
  const classes = useStyles();

  return record ? (
    <span className={classes.main}>
      {record.roles &&
        record.roles.map(role => {
          if (role.type === BASE_GROUP_ROLES.GROUP_MEMBER.type) return null;

          return (
            <Chip
              size="small"
              color={
                role.type === BASE_GROUP_ROLES.GROUP_ADMIN.type
                  ? 'primary'
                  : 'default'
              }
              key={role.id}
              className={classes.chip}
              label={role.name || role.type}
            />
          );
        })}
    </span>
  ) : null;
};

RolesField.propTypes = {
  record: PropTypes.any,
};

RolesField.defaultProps = {
  addLabel: true,
  source: 'roles',
};

export default RolesField;
