import React, {
  CSSProperties,
  ReactHTML,
  ReactNode,
  forwardRef,
  createElement,
} from 'react';

// @ts-ignore
import RemarkableReactRenderer from 'remarkable-react';

import {isArray, escapeRegExp, get} from 'lodash';
import {Remarkable} from 'remarkable';
import {linkify} from 'remarkable/linkify';

import {
  MENTION_TRIGGER,
  REGEX_MENTION_PLUGIN,
} from '../../constants/draftjsEditor';

export interface RemarkableRendererComponentsOptions {
  disabledRules?: Array<
    | 'a'
    | 'blockquote'
    | 'br'
    | 'code'
    | 'del'
    | 'em'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'html'
    | 'img'
    | 'ins'
    | 'li'
    | 'mark'
    | 'ol'
    | 'p'
    | 'pre'
    | 'strong'
    | 'sub'
    | 'sup'
    | 'table'
    | 'tbody'
    | 'td'
    | 'th'
    | 'thead'
    | 'tr'
    | 'ul'
  >;
}

export const remarkableOptions: Remarkable.Options = {
  html: false, // Disable HTML tags in source
  breaks: true, // convert `/n` to `<br />`
  linkTarget: '_blank', // Add target="_blank" to links
};

export interface Actor {
  id: string;
  username: string;
  is_deactivated?: boolean;
  name?: string;
  avatar?: string;
  fullname?: string;
  email?: string;
  is_verified?: boolean;
}

export type MentionObject = Record<string, Actor>;

interface RemarkableParserOptions {
  mention?: MentionObject;
}

const DEACTIVATED_USER_MARKER = 'deactivated';

export const mentionParser = (
  state: Remarkable.StateInline,
  silent: boolean,
  options?: RemarkableParserOptions,
) => {
  const {mention = undefined} = options || {};
  // it is surely not our rule, so we could stop early
  if (!state.src) {
    return false;
  }

  if (state.src[state.pos] !== MENTION_TRIGGER) {
    return false;
  }
  const match = new RegExp(
    `(\\s|^)${escapeRegExp(MENTION_TRIGGER)}${REGEX_MENTION_PLUGIN}*`,
    'g',
  ).exec(state.src.slice(state.pos));

  if (!match) {
    return false;
  }

  // in silent mode it shouldn't output any tokens or modify pending
  if (!silent && match[0]) {
    const matchedString = match[0];

    const userName = matchedString
      .replace(/^@/, '') // remove the mention trigger from the beginning of the string
      .replace(/[.|?|,|!|$|%|*|)|(|#]+$/, ''); // Remove special characters from the end of the string

    const remainingString = matchedString.replace(`@${userName}`, '');

    let fullName: string | undefined;
    let deactivated = '';
    if (mention && !isArray(mention) && mention[userName]) {
      fullName = mention[userName]?.fullname;
      deactivated = mention[userName]?.is_deactivated
        ? DEACTIVATED_USER_MARKER
        : '';
    }

    if (fullName) {
      state.push({
        type: 'mention_open',
        content: `${userName}@${fullName}@${deactivated}`,
        level: state.level,
      });

      state.push({
        type: 'text',
        content: `@${userName}`,
        level: state.level + 1,
      });

      state.push({
        type: 'mention_close',
        level: state.level,
      });

      if (remainingString) {
        state.push({
          type: 'text',
          content: remainingString,
          level: state.level,
        });
      }
    } else {
      state.push({
        type: 'text',
        content: matchedString,
        level: state.level,
      });
    }
  }

  // every rule should set state.pos to a position after token"s contents
  // eslint-disable-next-line no-param-reassign
  state.pos += match[0] ? match[0].length : 0;

  return true;
};

export const emojiParser = (state: Remarkable.StateInline, silent: boolean) => {
  const {src, pos, level} = state;
  // it is surely not our rule, so we could stop early
  if (!src) {
    return false;
  }

  const begin = src.substring(pos).indexOf(':');
  const end = src.substring(pos + begin + 1).indexOf(':');
  if (!(src.substring(pos).includes(':bic_') || end > 1)) {
    return false;
  }

  const match = src.substring(
    pos,
    pos + src.substring(pos + 1).indexOf(':') + 2,
  );

  const emoji = match.substring(1, match.length - 1);
  if (!/^[+_a-zA-Z0-9]*$/.test(emoji)) {
    return false;
  }

  // in silent mode it shouldn't output any tokens or modify pending
  if (!silent) {
    state.push({
      type: 'emoji_open',
      content: emoji,
      level,
    });

    state.push({
      type: 'text',
      content: emoji,
      level: level + 1,
    });

    state.push({
      type: 'emoji_close',
      level,
    });
  }
  Object.assign(state, {pos: pos + match.length});

  return true;
};

/**
 * Default remarkable break token,
 * which will be rendered as <br />
 */
const breakToken: Remarkable.HardbreakToken = {type: 'hardbreak', level: 0};
/**
 * Manually adding break tokens to parser to prevent it from being omit by default
 */
export const newLinesParser = (state: Remarkable.StateInline) => {
  let previousBlockEndingLine = 0;
  const tokensWithEmptyLines: Remarkable.ContentToken[] = [];

  state.tokens.forEach(token => {
    /** Token type eg: `paragraph_open`, `bullet_list_open`  */
    const itemType = token.type;
    /** Token startLine index */
    const startLine = get(token, 'lines[0]');
    /** Token endLine index */
    const endLine = get(token, 'lines[1]');
    /** Whether we should consider adding new lines to this token type,
     * Probably not really proud of this solution.
     * But, the only way to know where or how much new lines will be added is
     * keep tracking the start and end lines of the these token type:
     */
    if (
      itemType.indexOf('_open') !== -1 ||
      itemType === 'fence' ||
      itemType === 'hr' ||
      itemType === 'htmlblock'
    ) {
      const totalEmptyParagraphsToCreate = startLine
        ? startLine - previousBlockEndingLine
        : 0;
      for (let i = 0; i < totalEmptyParagraphsToCreate; i += 1) {
        tokensWithEmptyLines.push(breakToken);
      }
      previousBlockEndingLine = endLine ?? previousBlockEndingLine;
    }
    /** Reserve 1 line after closing lists tokens */
    if (
      itemType === 'bullet_list_close' ||
      itemType === 'ordered_list_close' ||
      itemType === 'blockquote_close'
    ) {
      previousBlockEndingLine += 1;
    }
    /** Addling empty lines tokens with the previous tokens */
    tokensWithEmptyLines.push(token);
  });

  // eslint-disable-next-line no-param-reassign
  state.tokens = tokensWithEmptyLines;
  return true;
};

export const remarkableEmojiPlugin = (remarkable: Remarkable) => {
  remarkable.inline.ruler.push('emoji', emojiParser, remarkableOptions);
};

export const remarkableNewLinePlugin = (remarkable: Remarkable) => {
  remarkable.core.ruler.push('new_lines', newLinesParser, remarkableOptions);
};

export const createRemarkableParser = ({mention}: RemarkableParserOptions) => {
  const mdParser = new Remarkable(remarkableOptions)
    .use(linkify) // Linkify URLs
    .use(remarkableEmojiPlugin)
    .use(remarkableNewLinePlugin); // Preserve empty lines

  mdParser.inline.ruler.enable([
    'ins', // md.render('++underline++') // => '<p><ins>underline</ins></p>'
    'mark', // md.render('==marked==') // => '<p><mark>marked</mark></p>'
  ]);

  return mdParser;
};

const createParagraphComponent = () => ({
  p: ({children}: {children: ReactNode[]}) => {
    const array = children.map((child: any, index: number) => {
      if (child?.type === 'br')
        return <p key={`remark_p_${index}`} className="h-3.5" />;
      return child;
    });
    if (Array.isArray(array) && array.some(child => child?.type === 'img')) {
      return <div className="-mx-4">{children}</div>;
    }

    return <p>{array}</p>;
  },
});

const createBasicBlockComponents = () => ({
  /** Turn all <ins> into <u> because <ins> is not supported in DraftJS */
  ins: ({children}: {children: ReactNode}) => <u>{children}</u>,
  mark: ({children}: {children: ReactNode}) => (
    <mark className="m-0 rounded-md bg-yellow-20 p-0">{children}</mark>
  ),
  code: ({children}: {children: ReactNode}) => (
    <code
      style={{
        whiteSpace: 'pre-wrap',
      }}>
      {children}
    </code>
  ),
  blockquote: ({children}: {children: ReactNode}) => (
    <blockquote style={{padding: 0}}>{children}</blockquote>
  ),
});

const createHeadingComponents = ({
  disabledRules,
}: RemarkableRendererComponentsOptions) => {
  return {
    h1: ({children}: {children: string}) =>
      disabledRules?.includes('h1') ? (
        <p>{children?.length > 0 ? `# ${children}` : <br />}</p>
      ) : (
        <h1>{children?.length > 0 ? children : <br />}</h1>
      ),
    h2: ({children}: {children: string}) =>
      disabledRules?.includes('h2') ? (
        <p>{children?.length > 0 ? `## ${children}` : <br />}</p>
      ) : (
        <h2>{children?.length > 0 ? children : <br />}</h2>
      ),
    h3: ({children}: {children: string}) =>
      disabledRules?.includes('h3') ? (
        <p>{children?.length > 0 ? `### ${children}` : <br />}</p>
      ) : (
        <h3>{children?.length > 0 ? children : <br />}</h3>
      ),
    h4: ({children}: {children: string}) =>
      disabledRules?.includes('h4') ? (
        <p>{children?.length > 0 ? `#### ${children}` : <br />}</p>
      ) : (
        <h4>{children?.length > 0 ? children : <br />}</h4>
      ),
    h5: ({children}: {children: string}) =>
      disabledRules?.includes('h5') ? (
        <p>{children?.length > 0 ? `##### ${children}` : <br />}</p>
      ) : (
        <h5>{children?.length > 0 ? children : <br />}</h5>
      ),
    h6: ({children}: {children: string}) =>
      disabledRules?.includes('h6') ? (
        <p>{children?.length > 0 ? `###### ${children}` : <br />}</p>
      ) : (
        <h6>{children?.length > 0 ? children : <br />}</h6>
      ),
  };
};

export interface RemarkableRendererComponentsOptions {
  disabledRules?: Array<
    | 'a'
    | 'blockquote'
    | 'br'
    | 'code'
    | 'del'
    | 'em'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'html'
    | 'img'
    | 'ins'
    | 'li'
    | 'mark'
    | 'ol'
    | 'p'
    | 'pre'
    | 'strong'
    | 'sub'
    | 'sup'
    | 'table'
    | 'tbody'
    | 'td'
    | 'th'
    | 'thead'
    | 'tr'
    | 'ul'
  >;
}

export const createRemarkableRendererComponents = (
  options: RemarkableRendererComponentsOptions,
) => {
  return {
    /**
     * Warning: Please check your render function to get rid of any use of
     * tailwind arbitrary values: https://tailwindcss.com/docs/adding-custom-styles#using-arbitrary-values
     * Because it would not work properly
     */
    components: {
      ...createParagraphComponent(),
      ...createBasicBlockComponents(),
      ...createHeadingComponents(options),
    },
    tokens: {
      mention_open: 'mention',
      emoji_open: 'emoji',
    },
  };
};

export interface MarkdownRendererProps {
  markdownSource?: string | null;
  className?: string;
  mention?: MentionObject;
  disabledRules?: RemarkableRendererComponentsOptions['disabledRules'];
  style?: CSSProperties;
  as?: keyof ReactHTML;
}

export const MarkdownRenderer = forwardRef<
  HTMLDivElement,
  MarkdownRendererProps
  // eslint-disable-next-line react/prop-types
>(({markdownSource, mention, disabledRules, as = 'div', ...rest}, ref) => {
  const mdParser = createRemarkableParser({mention});
  /**
   * Rewrite default remarkable renderer so that it export as component
   */
  mdParser.renderer = new RemarkableReactRenderer(
    createRemarkableRendererComponents({disabledRules}),
  );
  /**
   * Empty content result in response of markdownSource is null
   */
  if (!markdownSource) return <div ref={ref} {...rest} />;
  return createElement(as, {ref, ...rest}, mdParser.render(markdownSource));
});

MarkdownRenderer.displayName = 'MarkdownRenderer';
