import {
  ContentBlock,
  ContentState,
  EditorState,
  EntityInstance,
  Modifier,
  RichUtils,
  convertToRaw,
} from 'draft-js';

import linkifyIt from 'linkify-it';
// @ts-ignore
import {draftToMarkdown} from '@beincomm/markdown-draft-js';

import tlds from 'tlds';
import _ from 'lodash';

export interface ConvertOptions {
  mention?: any;
}

export interface DraftToMarkdownOptions extends ConvertOptions {
  remarkableDisableOptions?: any;
}

export type MarkdownToHtmlOptions = ConvertOptions;

interface TableCellData {
  key: string;
  value: string;
}

type THeadData = TableCellData;

interface TRowData {
  key: string;
  value: TableCellData[];
}
export interface TableData {
  columns: THeadData[];
  rows: TRowData[];
}

export function createLinkAtSelection(
  editorState: EditorState,
  url: string,
): EditorState {
  const contentState = editorState
    .getCurrentContent()
    .createEntity('LINK', 'MUTABLE', {url});
  const entityKey = contentState.getLastCreatedEntityKey();
  const withLink = RichUtils.toggleLink(
    editorState,
    editorState.getSelection(),
    entityKey,
  );
  return EditorState.forceSelection(withLink, editorState.getSelection());
}

export function getCurrentEntityKey(editorState: EditorState): string {
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const contentState = editorState.getCurrentContent();
  const anchorBlock = contentState.getBlockForKey(anchorKey);
  const offset = selection.getAnchorOffset();
  const index = selection.getIsBackward() ? offset - 1 : offset;
  return anchorBlock.getEntityAt(index);
}

export function getCurrentEntity(
  editorState: EditorState,
): EntityInstance | null {
  const contentState = editorState.getCurrentContent();
  const entityKey = getCurrentEntityKey(editorState);
  return entityKey ? contentState.getEntity(entityKey) : null;
}

export function hasEntity(
  editorState: EditorState,
  entityType: string,
): boolean {
  const entity = getCurrentEntity(editorState);
  return Boolean(entity && entity.getType() === entityType);
}

export const hasTextAtCurrentSelection = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const focusOffset = selection.getFocusOffset();
  const anchorOffset = selection.getAnchorOffset();
  return focusOffset !== anchorOffset;
};

export function pushLinkAtSelection(
  editorState: EditorState,
  url: string,
  text?: string | null,
): EditorState {
  const newContentState = editorState
    .getCurrentContent()
    .createEntity('LINK', 'MUTABLE', {url});
  const contentWitLinkEntity = Modifier.insertText(
    newContentState,
    editorState.getSelection(),
    text || url,
    undefined,
    newContentState.getLastCreatedEntityKey(),
  );
  return EditorState.push(
    editorState,
    contentWitLinkEntity,
    'insert-characters',
  );
}

export function removeLinkAtSelection(editorState: EditorState): EditorState {
  const selection = editorState.getSelection();
  return RichUtils.toggleLink(editorState, selection, null);
}

export const anchorDecoratorStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  if (!contentState) return;
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const extractLinkFromText = (
  text?: string,
  schemas: linkifyIt.SchemaRules | linkifyIt.Options = {fuzzyLink: false}, // Only supports url begin with http(s)://
  options?: linkifyIt.Options,
) => {
  if (!text) return null;
  return linkifyIt(schemas, options).tlds(tlds).match(text);
};

export const getMarkdownFromEditorState = (
  editorState?: EditorState,
): string => {
  if (!editorState) return '';

  const contentState = editorState.getCurrentContent();

  return draftToMarkdown(convertToRaw(contentState), {
    preserveNewlines: true,
    styleItems: {
      UNDERLINE: {
        // => '++underline++'
        open: () => '++',
        close: () => '++',
      },
      blockquote: {
        open: () => ' >',
        close: () => '\n',
      },
    },
  });
};
