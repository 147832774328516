import {fetchUtils} from 'react-admin';
import {IObject} from '../interfaces/common';

import {getToken} from './auth';

export const httpClient = async (
  url: string,
  options: fetchUtils.Options = {},
): Promise<{
  status: number;
  headers: Headers;
  body: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: IObject<any>;
}> => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }

  const token = await getToken();
  // @ts-ignore
  options.headers.set('Authorization', token);

  return fetchUtils.fetchJson(url, options);
};

export default httpClient;
