import React from 'react';
import {Card, CardContent, Typography, Box, CardProps} from '@material-ui/core';
import {DateField, Record} from 'react-admin';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';

interface HistoryCardProps {
  record?: Record;
  cardProps?: CardProps;
}

const HistoryCard: React.FC<HistoryCardProps> = ({
  record,
  cardProps,
}: HistoryCardProps) => {
  return (
    <Card {...cardProps}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          History
        </Typography>
        <Box display="flex" flexWrap="wrap">
          <Box display="flex" mb="1em" flexGrow={1}>
            <Box mr="0.25em">
              <AccessTimeIcon fontSize="small" color="disabled" />
            </Box>
            <Box flexGrow={1}>
              <Typography>Created date</Typography>
              <DateField record={record} source="created_at" />
            </Box>
          </Box>
          <Box display="flex" mb="1em" flexGrow={1}>
            <Box mr="0.25em">
              <AccessTimeIcon fontSize="small" color="disabled" />
            </Box>
            <Box flexGrow={1}>
              <Typography>Last update</Typography>
              <DateField record={record} source="updated_at" />
            </Box>
          </Box>
        </Box>
        {record?.deleted_at && (
          <Box display="flex" mb="1em" flexGrow={1}>
            <Box mr="0.25em">
              <DeleteIcon fontSize="small" color="disabled" />
            </Box>
            <Box flexGrow={1}>
              <Typography>Deleted date</Typography>
              <DateField record={record} source="deleted_at" />
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default HistoryCard;
