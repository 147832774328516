export const OPEN_SHOW_ADD_TO_GROUP_DRAWER = 'users/open_add_to_group_drawer';
export const CLOSE_SHOW_ADD_TO_GROUP_DRAWER = 'users/close_add_to_group_drawer';
export const SET_SHOW_ADD_TO_GROUP_DRAWER =
  'users/set_show_add_to_group_drawer';

export const SET_PREVIOUS_SELECTED_GROUP_IDS = 'users/set_selected_ids_holder';

export const OPEN_SHOW_TOAST_UNDO = 'users/open_toast_undo';
export const SET_CLOSE_POPUP_WINDOW_TOAST_UNDO =
  'users/set_close_popup_window_toast_undo';
export const CLOSE_SHOW_TOAST_UNDO = 'users/close_toast_undo';
