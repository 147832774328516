import { AuthProvider } from 'react-admin';
import { Auth } from 'aws-amplify';
import { getToken } from '../utils';
import adminInfoStore from '../store/adminInfoStore';

const authProvider: AuthProvider = {
  // authentication
  login: ({ username, password }) => {
    const valueTrimUserName = username.trim()

    return Auth.signIn(valueTrimUserName, password).then(async response => {
      const sessionData = response.signInUserSession;
      const permissionError = `You don't have permission to access this page.`;
      if (!sessionData) return Promise.reject();

      await adminInfoStore.fetchAndSaveAdminInfo();
      const isStaff = await adminInfoStore.isBicStaff();
      if (!isStaff) {
        Auth.signOut();
        return Promise.reject(permissionError);
      }

      return Promise.resolve();
    })
  },

  logout: () => Auth.signOut(),

  checkAuth: async error => {
    const token = await getToken();
    const isBicStaff = await adminInfoStore.isBicStaff();
    if (!token || !isBicStaff)
      return Promise.reject({ message: 'Login required' });

    const status = error?.status;
    const hasError = status === 401 || status === 403;

    return hasError
      ? Promise.reject({ message: 'Your session has expired' })
      : Promise.resolve();
  },

  checkError: error => {
    const status = error?.status;
    const message =
      error?.meta?.message || error?.message || 'Something went wrong';
    const code = error?.code;

    const messageExpired = ['jwt expired', 'Not a valid JWT token'];
    const isJwtExpired = messageExpired.includes(message);

    if (
      status === 401 ||
      status === 403 ||
      code === 'api.unauthorized' ||
      message === 'Failed to fetch' ||
      isJwtExpired
    ) {
      Auth.signOut();
      return Promise.reject({ message });
    }

    return Promise.resolve();
  },

  // authorization
  getPermissions: async () => {
    const adminInfo = await adminInfoStore.getInfo();

    return adminInfo?.bein_staff_role
      ? Promise.resolve(adminInfo.bein_staff_role)
      : Promise.reject();
  },

  getIdentity: async () => {
    try {
      const token = getToken();
      const userInfo = await adminInfoStore.getInfo();
      if (!token || !userInfo)
        return Promise.reject({ message: 'Login required' });

      return Promise.resolve(userInfo);
    } catch {
      return Promise.reject('Could not get your identity, please log in again');
    }
  },
};

export default authProvider;
