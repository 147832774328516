import * as types from './types';
import {AnyAction} from 'redux';

export interface GroupsState {
  reloadGroupTree: boolean;
}

const initState = {
  reloadGroupTree: false,
} as GroupsState;

function groupsReducer(state = initState, action: AnyAction): GroupsState {
  const {type, payload} = action;

  switch (type) {
    case types.SET_RELOAD_GROUP_TREE:
      return {
        ...state,
        reloadGroupTree: payload,
      };

    default:
      return state;
  }
}

export default groupsReducer;
