import {
  faItalic,
  faStrikethrough,
  faUnderline,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBold,
  faH1,
  faH2,
  faH3,
  faListOl,
  faListUl,
  faQuoteLeft,
  faText,
} from '@fortawesome/pro-solid-svg-icons';
import {IconName} from '../components/Icon/Icon';

export type EditorStyleButton = {
  label: string;
  translationKey?: string;
  icon: IconName;
};

export type EditorStyleMap = {
  [key: string]: EditorStyleButton;
};

export const EDITOR_BLOCK_STYLE_MAP: EditorStyleMap = {
  unstyled: {
    label: 'Normal',
    icon: faText,
  },
  'header-one': {
    label: 'Heading 1',
    icon: faH1,
  },
  'header-two': {
    label: 'Heading 2',
    icon: faH2,
  },
  'header-three': {
    label: 'Heading 3',
    icon: faH3,
  },
  'ordered-list-item': {
    label: 'Numbered list',
    icon: faListOl,
  },
  'unordered-list-item': {
    label: 'Bulleted list',
    icon: faListUl,
  },
  blockquote: {
    label: 'Quote',
    icon: faQuoteLeft,
  },
};

export const EditorSupportedBlockStyles = Object.keys(EDITOR_BLOCK_STYLE_MAP);

export const EDITOR_INLINE_STYLE_MAP: EditorStyleMap = {
  BOLD: {
    label: 'Bold',
    translationKey: 'editor.inline.bold',
    icon: faBold,
  },
  ITALIC: {
    label: 'Italic',
    translationKey: 'editor.inline.italic',
    icon: faItalic,
  },
  UNDERLINE: {
    label: 'Underline',
    translationKey: 'editor.inline.underline',
    icon: faUnderline,
  },
  STRIKETHROUGH: {
    label: 'Strikethrough',
    translationKey: 'editor.inline.strikethrough',
    icon: faStrikethrough,
  },
};

Object.freeze(EDITOR_INLINE_STYLE_MAP);

export const EDITOR_INLINE_STYLE_LIST_MAP: EditorStyleMap = {
  'ordered-list-item': {
    label: 'Numbered list',
    translationKey: 'editor.block.ordered-list-item',
    icon: faListOl,
  },
  'unordered-list-item': {
    label: 'Bulleted list',
    translationKey: 'editor.block.unordered-list-item',
    icon: faListUl,
  },
  blockquote: {
    label: 'Quote',
    translationKey: 'editor.block.blockquote',
    icon: faQuoteLeft,
  },
};

Object.freeze(EDITOR_INLINE_STYLE_LIST_MAP);

export const customStyleMap = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
};

export const MENTION_TRIGGER = '@';
export const REGEX_MENTION_PLUGIN =
  '[' +
  '\\w-' +
  // Dot notation
  '.' +
  // Latin-1 Supplement (letters only) - https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin-1_Supplement
  '\u00C0-\u00D6' +
  '\u00D8-\u00F6' +
  '\u00F8-\u00FF' +
  // Latin Extended-A (without deprecated character) - https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_Extended-A
  '\u0100-\u0148' +
  '\u014A-\u017F' +
  // Cyrillic symbols: \u0410-\u044F - https://en.wikipedia.org/wiki/Cyrillic_script_in_Unicode
  '\u0410-\u044F' +
  // Symbols that are sometimes used in Japanese names: \u3005-\u3006
  '\u3005-\u3006' +
  // hiragana (japanese): \u3040-\u309F - https://gist.github.com/ryanmcgrath/982242#file-japaneseregex-js
  '\u3040-\u309F' +
  // katakana (japanese): \u30A0-\u30FF - https://gist.github.com/ryanmcgrath/982242#file-japaneseregex-js
  '\u30A0-\u30FF' +
  // For an advanced explaination about Hangul see https://github.com/draft-js-plugins/draft-js-plugins/pull/480#issuecomment-254055437
  // Hangul Jamo (korean): \u3130-\u318F - https://en.wikipedia.org/wiki/Korean_language_and_computers#Hangul_in_Unicode
  // Hangul Syllables (korean): \uAC00-\uD7A3 - https://en.wikipedia.org/wiki/Korean_language_and_computers#Hangul_in_Unicode
  '\u3130-\u318F' +
  '\uAC00-\uD7A3' +
  // common chinese symbols: \u4e00-\u9eff - http://stackoverflow.com/a/1366113/837709
  // extended to \u9fa5 https://github.com/draft-js-plugins/draft-js-plugins/issues/1888
  '\u4e00-\u9fa5' +
  // Arabic https://en.wikipedia.org/wiki/Arabic_(Unicode_block)
  '\u0600-\u06ff' +
  // Vietnamese http://vietunicode.sourceforge.net/charset/
  '\u00C0-\u1EF9' +
  ']';

export const POST_EDITOR_MAX_ITEM_LIST_DEPTH = 1; // 2 levels
export const COMMENT_EDITOR_MAX_ITEM_LIST_DEPTH = 1; // 2 levels
