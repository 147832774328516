import {AnyAction} from 'redux';
import {Identifier} from 'react-admin';
import types from './types';
import {IGroup, IGroupMember} from '../../interfaces/IGroup';
import {ICommunityMember} from '../../interfaces/ICommunity';
import {ValueType} from '../../components/Buttons/AdminPickerButton';

const setShowAdminPickerDrawer = (payload: boolean): AnyAction => ({
  type: types.SET_SHOW_ADMIN_PICKER_DRAWER,
  payload,
});

const setAdminIdToEditMemberList = (
  payload: Identifier | undefined,
): AnyAction => ({
  type: types.SET_ADMIN_ID_TO_EDIT_MEMBER_LIST,
  payload,
});

const setShowAlertMustPickAdmin = (payload: boolean): AnyAction => ({
  type: types.SET_SHOW_ALERT_MUST_PICK_ADMIN,
  payload,
});

const setMemberInnerGroups = (payload: IGroup[] | []): AnyAction => ({
  type: types.SET_MEMBER_INNER_GROUPS,
  payload,
});

const showAlertRemoveMember = (payload: {
  valueId: Identifier;
  valueType: ValueType;
  member: IGroupMember | ICommunityMember;
  memberInnerGroups?: IGroup[] | [];
}): AnyAction => ({
  type: types.SHOW_ALERT_REMOVE_MEMBER,
  payload,
});

const hideAlertRemoveMember = (): AnyAction => ({
  type: types.HIDE_ALERT_REMOVE_MEMBER,
});

const setShowAddMemberDrawer = (payload: boolean): AnyAction => ({
  type: types.SET_SHOW_ADD_MEMBER_DRAWER,
  payload,
});

const setShowDeactivateUserDrawer = (payload: boolean): AnyAction => ({
  type: types.SET_SHOW_DEACTIVATE_MEMBER_DRAWER,
  payload,
});

export default {
  setShowAdminPickerDrawer,
  setAdminIdToEditMemberList,
  setShowAddMemberDrawer,
  setShowDeactivateUserDrawer,
  setShowAlertMustPickAdmin,
  setMemberInnerGroups,
  showAlertRemoveMember,
  hideAlertRemoveMember,
};
