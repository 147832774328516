import React, {MouseEvent, useState} from 'react';

import {faLink} from '@fortawesome/pro-regular-svg-icons';
import {EditorState} from 'draft-js';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

import {
  createLinkAtSelection,
  getCurrentEntityKey,
  hasEntity,
  hasTextAtCurrentSelection,
  pushLinkAtSelection,
  removeLinkAtSelection,
} from './draftjsEditor';
import {makeStyles} from '@material-ui/core';
import Icon from '../Icon/Icon';

type LinkInputMode = 'textOnly' | 'urlOnly' | 'textAndUrl';

type FormAddLink = {
  text?: string;
  url?: string;
};

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.375rem',
    borderWidth: '1px',
    cursor: 'pointer',
    width: '36px',
    borderStyle: 'solid',
    borderColor: 'rgb(227, 230, 242)',
  },
  dialogContainer: {
    fontWeight: 400,
  },
  inputTextDialog: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '16px',
  },
  textDialog: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontWeight: 400,
  },
  inputUtl: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export interface AddLinkButtonProps {
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
}
export default function AddLinkButton({
  editorState,
  setEditorState,
}: AddLinkButtonProps) {
  const classes = useStyles();

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [inputMode, setInputMode] = useState<LinkInputMode>('textAndUrl');
  const defaultValues: FormAddLink = {text: 'link', url: ''};

  const [dataValuesDialog, setDataValuesDialog] =
    useState<FormAddLink>(defaultValues);

  const addEmbedLinkToFormUrl = () => {
    const entityKey = getCurrentEntityKey(editorState);
    const entity = editorState.getCurrentContent().getEntity(entityKey);
    const entityData = entity ? entity.getData() : undefined;
    const embedUrl = (entityData && entityData.url) ?? '';
    setDataValuesDialog({...dataValuesDialog, url: embedUrl});
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const hasSelectedText = hasTextAtCurrentSelection(editorState);

    const hasSelectedLink = hasEntity(editorState, 'LINK');

    let newInputMode: LinkInputMode = 'textAndUrl';
    if (hasSelectedLink) {
      addEmbedLinkToFormUrl();
      newInputMode = 'urlOnly';
    } else if (hasSelectedText) {
      newInputMode = 'urlOnly';
    }

    setInputMode(newInputMode);
    setIsDialogVisible(true);
  };

  const handleInputBothTextAndUrl = () => {
    const text = dataValuesDialog.text;
    const url = dataValuesDialog.url;
    if (!url) throw new Error('Url is undefined!');
    const stateWithLink = pushLinkAtSelection(editorState, url, text);
    setEditorState(stateWithLink);
  };

  const handleInputUrlOnly = () => {
    const url = dataValuesDialog.url;
    if (!url) throw new Error('Url is undefined!');
    setEditorState(createLinkAtSelection(editorState, url));
  };

  const handleInputTextOnly = () => {
    const newUrl = dataValuesDialog.url;
    if (newUrl) {
      setEditorState(createLinkAtSelection(editorState, newUrl));
    } else {
      setEditorState(removeLinkAtSelection(editorState));
    }
  };

  const handleAddHyperLink = () => {
    switch (inputMode) {
      case 'textOnly':
        handleInputTextOnly();
        break;

      case 'urlOnly':
        handleInputUrlOnly();
        break;

      case 'textAndUrl':
      default:
        handleInputBothTextAndUrl();
        break;
    }
  };

  const closeLinkDialog = () => {
    setIsDialogVisible(false);
    setDataValuesDialog(defaultValues);
  };

  const onOkClick = () => {
    handleAddHyperLink();
    closeLinkDialog();
  };

  const textChangeHandler = (text: string) => {
    setDataValuesDialog({...dataValuesDialog, text});
  };

  const urlChangeHandler = (url: string) => {
    setDataValuesDialog({...dataValuesDialog, url});
  };

  const shouldShowInputText =
    inputMode === 'textAndUrl' || inputMode === 'textOnly';
  const shouldShowInputUrl =
    inputMode === 'textAndUrl' || inputMode === 'urlOnly';

  const dialogContent = (
    <div className={classes.dialogContainer}>
      {shouldShowInputText && (
        <div className={classes.inputTextDialog}>
          <span className={classes.textDialog}>Text</span>
          <TextField
            placeholder="Text to display"
            variant="outlined"
            style={{marginTop: '8px'}}
            value={dataValuesDialog.text}
            onChange={e => textChangeHandler(e.target.value)}
          />
        </div>
      )}
      {shouldShowInputUrl && (
        <div className={classes.inputUtl}>
          <span className={classes.textDialog}>Address</span>
          <TextField
            placeholder="Link to a web page"
            variant="outlined"
            style={{marginTop: '8px'}}
            value={dataValuesDialog.url}
            onChange={e => urlChangeHandler(e.target.value)}
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      <div onClick={handleClick} className={classes.icon}>
        <Icon name={faLink} size="16px" color="text-neutral-40" />
      </div>
      <Dialog
        open={isDialogVisible}
        title="Add a Hyperlink"
        onClose={closeLinkDialog}
        maxWidth="sm"
        fullWidth>
        <DialogTitle>Add a Hyperlink</DialogTitle>
        <DialogContent dividers>{dialogContent}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeLinkDialog} color="primary">
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={onOkClick}
            disabled={!dataValuesDialog.url?.trim()}
            color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
