import React from 'react';
import {DateField as RADateField, DateFieldProps} from 'react-admin';

/**
 * Just use this instead of DateField from react-admin
 * as to set default date format to dd/MM/YYY
 */
const DateField = ({...props}: DateFieldProps): JSX.Element => {
  return <RADateField locales="vi-VN" {...props} />;
};

export default DateField;
