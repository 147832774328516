import {BASEPATHS, RESOURCES} from '../common/constants';

const START_PATH = '/import';

export type importType = 'users' | 'groups' | 'members' | 'membersToCom';

export const importRecord = {
  users: {
    basePath: BASEPATHS.USERS,
    resource: RESOURCES.USERS,
    path: `${START_PATH}/users`,
    data: {
      fullname: 'string',
      username: 'string',
      email: 'string',
      password: 'string',
    },
    templateDownloadUrl: process.env.REACT_APP_USERS_CSV_TEMPLATE,
  },
  groups: {
    basePath: BASEPATHS.GROUPS,
    resource: RESOURCES.GROUPS,
    path: `${START_PATH}/groups`,
    data: {
      group_temporary_id: 'number',
      name: 'string',
      description: 'string',
      outer_group_temporary_id: "outer group's temporary id",
      privacy: `"PUBLIC" | "OPEN" | "PRIVATE" | "SECRET"`,
    },
    templateDownloadUrl: process.env.REACT_APP_GROUPS_CSV_TEMPLATE,
  },
  members: {
    basePath: BASEPATHS.MEMBERS_FOR_IMPORT,
    resource: RESOURCES.MEMBERS_FOR_IMPORT,
    path: `${START_PATH}/members`,
    data: {
      user_email: 'string',
      group_ids: 'uuid: abc-123 | abc-123;def-456',
      role: 'MEMBER | GROUP_ADMIN',
    },
    templateDownloadUrl: process.env.REACT_APP_MEMBERS_TO_GROUPS_CSV_TEMPLATE,
  },
  membersToCom: {
    basePath: BASEPATHS.MEMBERS_TO_COM,
    resource: RESOURCES.MEMBERS_TO_COM,
    path: `${START_PATH}/members-to-com`,
    data: {
      user_email: 'string',
      community_id: 'uuid: f54db29a-6c30-4f7d-a722-a84d781a7fd3',
    },
    templateDownloadUrl: process.env.REACT_APP_MEMBERS_TO_COM_CSV_TEMPLATE,
  },
};
