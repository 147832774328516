import React from 'react';
import {AutocompleteInput, InputProps, ReferenceInput} from 'react-admin';
import {useFormState} from 'react-final-form';

import {makeStyles} from '@material-ui/core/styles';

import CommunityQuickPreviewButton from './CommunityQuickPreviewButton';
import {SortOrder} from '../common/constants';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface Props extends InputProps {
  reference: string;
}

const CommunityReferenceInput: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {source} = props;
  const {values} = useFormState({subscription: {values: true}});
  const filterToQuery = (searchText: string) => ({
    key: searchText,
  });

  return (
    <div className={classes.root}>
      <div>
        <ReferenceInput
          filterToQuery={filterToQuery}
          sort={{field: 'name', order: SortOrder.ASC}}
          {...props}>
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
      </div>
      {!!values[source] && <CommunityQuickPreviewButton id={values[source]} />}
    </div>
  );
};

export default CommunityReferenceInput;
