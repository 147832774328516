import {useCallback} from 'react';
import {useUnselectAll} from 'react-admin';
import {useDispatch} from 'react-redux';
import {RESOURCES} from '../common/constants';
import actions from '../store/common/actions';
import {CommonState} from '../store/common/reducer';
import {useAppSelector} from './redux';

export const useCommon = (): CommonState => {
  return useAppSelector(state => state.entities.common);
};

/**
 * Hook for clear selected admin from redux store and react admin resouruces
 *
 * @example
 * const clearSelectedCommAdmin = useClearSelectedAdmin();
 * clearSelectedCommAdmin('community');
 */
export const useClearSelectedAdmin = (): ((
  type: 'community' | 'group',
) => void) => {
  const dispatch = useDispatch();
  const unselectAll = useUnselectAll();
  return useCallback(
    (type: 'community' | 'group') => {
      const resource =
        type === 'community'
          ? RESOURCES.COMMUNITY_ADMINS
          : RESOURCES.GROUP_ADMINS;
      dispatch(actions.setAdminIdToEditMemberList(undefined));
      unselectAll(resource);
    },
    [dispatch],
  );
};
