import * as types from './types';
import {AnyAction} from 'redux';
import {Identifier} from 'react-admin';

export interface UsersState {
  showAddToGroupDrawer: boolean;
  previousSelectedGroupIds: Identifier[]; // Just for holding selectedIds temporarily
  visibleToastUndo: boolean;
  setClosePopupWindow: boolean;
  toastProps: {[key: string]: any} | null;
}

const initState = {
  showAddToGroupDrawer: false,
  previousSelectedGroupIds: [],
  visibleToastUndo: false,
  setClosePopupWindow: false,
  toastProps: {},
};

function usersReducer(state = initState, action: AnyAction): UsersState {
  const {type} = action;
  switch (type) {
    case types.SET_SHOW_ADD_TO_GROUP_DRAWER:
      return {
        ...state,
        showAddToGroupDrawer: action.payload,
      };
    case types.SET_PREVIOUS_SELECTED_GROUP_IDS:
      return {
        ...state,
        previousSelectedGroupIds: [...action.payload],
      };
    case types.OPEN_SHOW_TOAST_UNDO:
      return {
        ...state,
        visibleToastUndo: true,
        toastProps: {...state.toastProps, ...action.payload},
      };
    case types.SET_CLOSE_POPUP_WINDOW_TOAST_UNDO:
      return {
        ...state,
        setClosePopupWindow: !state.setClosePopupWindow,
      };
    case types.CLOSE_SHOW_TOAST_UNDO:
      return {
        ...state,
        visibleToastUndo: false,
      };
    default:
      return state;
  }
}

export default usersReducer;
