import React, {useState} from 'react';
import {
  DateInput,
  Edit,
  EditProps,
  FormWithRedirect,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
  Record,
  FormWithRedirectProps,
} from 'react-admin';
import {Box, CardContent, Typography} from '@material-ui/core';

import UserTitle from './UserTitle';
import Aside from './Aside';
import ImageInput from '../components/ImageInput';
import {HttpClientResponse} from '../interfaces/common';
import {beinStaffRoleChoices} from './common';
import {RESOURCES} from '../common/constants';
import {userValidators} from '../common/validation';
import {BeinStaffRole, ErrorCodes} from '../constants';
import EmailInput from './EmailInput';

interface UserFormErrors {
  email?: string;
}

const UserEdit: React.FC<EditProps> = ({permissions, ...props}: EditProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [errors, setErrors] = useState<UserFormErrors>({
    email: undefined,
  });

  const onSuccess = (response: HttpClientResponse) => {
    notify('Update user successfully', 'success');
    redirect(`/users/${response.data.id}/show`);
    refresh();
  };

  const onFailure = (response: {code: string; message: string}) => {
    if (response.code === ErrorCodes.User.EMAIL_REGISTERED) {
      setErrors(state => ({
        ...state,
        email: response.message,
      }));
    }
    notify(response.message, 'error');
  };

  const transform = (data: Record) => {
    // Checking if bein staff is 'NONE' => set to null
    const newRole =
      data['bein_staff_role'] === 'NONE' ? null : data['bein_staff_role'];

    return {
      ...data,
      bein_staff_role: newRole,
    };
  };

  return (
    <Edit
      {...props}
      title={<UserTitle />}
      aside={<Aside />}
      transform={transform}
      onSuccess={onSuccess}
      onFailure={onFailure}
      undoable={false}>
      <UserForm
        permissions={permissions}
        errors={errors}
        setErrors={setErrors}
      />
    </Edit>
  );
};

const UserForm = ({
  permissions,
  errors,
  setErrors,
  ...props
}: Omit<FormWithRedirectProps, 'render'> &
  Pick<EditProps, 'permissions'> & {
    errors?: UserFormErrors;
    setErrors?: React.Dispatch<React.SetStateAction<UserFormErrors>>;
  }): JSX.Element => {
  const canEditStaffRole = permissions === BeinStaffRole.SUPER_ADMIN;

  return (
    <FormWithRedirect
      {...props}
      // eslint-disable-next-line
      render={(formProps: any) => (
        <form style={{flex: 1}}>
          <CardContent>
            <Box display={{md: 'block', lg: 'flex'}} width="95%">
              <Box flex={2} mr={{md: 0, lg: '1em'}}>
                <Typography variant="h6" gutterBottom>
                  Identity
                </Typography>
                <TextInput
                  source="fullname"
                  resource={RESOURCES.USERS}
                  validate={userValidators.fullname}
                  fullWidth
                />
                <Box display="flex">
                  {canEditStaffRole && (
                    <>
                      <SelectInput
                        source="bein_staff_role"
                        choices={beinStaffRoleChoices}
                      />
                      <Box mr="1rem" />
                    </>
                  )}
                  <EmailInput
                    source="email"
                    resource={RESOURCES.USERS}
                    fullWidth
                    error={errors?.email}
                    onChange={() => {
                      setErrors?.(state => ({
                        ...state,
                        email: undefined,
                      }));
                    }}
                  />
                </Box>
                <Box display="flex" flex={2} mr={'1rem'}>
                  <SelectInput
                    source="country_code"
                    resource={RESOURCES.USERS}
                    choices={[
                      {id: '84', name: 'Vietnamese (+84)'},
                      {id: '1', name: 'United States (+1)'},
                      {id: '65', name: 'Singapore (+65)'},
                    ]}
                  />
                  <Box width="1rem" />
                  <TextInput
                    source="phone"
                    resource={RESOURCES.USERS}
                    validate={userValidators.phone}
                  />
                </Box>
                <Box display="flex">
                  <SelectInput
                    source="gender"
                    choices={[
                      {id: 'MALE', name: 'Male'},
                      {id: 'FEMALE', name: 'Female'},
                    ]}
                  />
                  <Box flex={1} ml="1em">
                    <DateInput source="birthday" resource={RESOURCES.USERS} />
                  </Box>
                </Box>
                <TextInput
                  source="address"
                  fullWidth
                  validate={userValidators.address}
                />
                {/*TODO: Need to update 2 fields below when there are requirements bout these fields*/}
                {/*<Box display="flex">*/}
                {/*  <Box mr="1em">*/}
                {/*    <AutocompleteArrayInput*/}
                {/*      source="language"*/}
                {/*      choices={[*/}
                {/*        {id: 'vi', name: 'Vietnamese'},*/}
                {/*        {id: 'en', name: 'English'},*/}
                {/*      ]}*/}
                {/*    />*/}
                {/*  </Box>*/}
                {/*  <TextInput source="relationship_status" />*/}
                {/*</Box>*/}
                <TextInput
                  source="description"
                  fullWidth
                  multiline
                  resettable
                  validate={userValidators.description}
                />
              </Box>
              <Box
                flex={1}
                ml={{xs: 0, lg: '1em'}}
                mt={{xs: '1em', lg: 0}}
                width={225}>
                <Typography variant="h6" gutterBottom>
                  Images
                </Typography>
                <Box mb="3em">
                  <ImageInput source="avatar" />
                </Box>
                {/* <ImageInput
                  label="Background image"
                  source="background_img_url"
                  accept="image/*"
                  maxSize={maxImgSize}
                  options={{
                    onDrop: handleAddNewBackgroundImage,
                    onRemove: handleRemoveNewBackgroundImage,
                  }}>
                  <ImageField source="src" />
                </ImageInput>
                {!newBackgroundImg && (
                  <ImageField source="background_img_url" />
                )} */}
                <ImageInput source="background_img_url" />
              </Box>
            </Box>
          </CardContent>
          <Toolbar
            record={formProps.record}
            basePath={formProps.basePath}
            invalid={formProps.invalid}
            handleSubmit={formProps.handleSubmit}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            saving={formProps.saving}
            resource={RESOURCES.USERS}>
            <SaveButton />
          </Toolbar>
        </form>
      )}
    />
  );
};

export default UserEdit;
