import {stringify} from 'querystring';
import {
  CreateResult,
  GetListResult,
  GetManyResult,
  GetOneResult,
  Identifier,
  Record,
} from 'react-admin';

import {IQuery, IReactAdminFile} from '../../interfaces/common';
import {
  IGroup,
  IGroupMember,
  IGroupRole,
  IGroupTree,
} from '../../interfaces/IGroup';
import {httpClient, mapGroupMember, mapGroupRole} from '../../utils';
import {API_ADMIN_URL} from '../../constants';
import {getPhotoUrl} from './uploadFileProvider';

export type GroupProvider = {
  getCommunityOfGroup: (groupId: Identifier) => Promise<GetOneResult<IGroup>>;
  moveGroup: (
    groupId: Identifier,
    outerGroupId: Identifier,
  ) => Promise<unknown>;
  getInnerGroups: <RecordType extends Record = IGroupTree>(
    groupId: Identifier,
  ) => Promise<GetOneResult<RecordType>>;
  getGroupMembers: <RecordType extends Record = IGroupMember>(
    groupId: Identifier,
    query: IQuery,
  ) => Promise<GetListResult<RecordType>>;
  getGroupJoinableUsers: <RecordType extends Record = IGroupMember>(
    groupId: Identifier,
    query: IQuery,
  ) => Promise<GetListResult<RecordType>>;
  removeUsersFromGroup: (props: {
    groupId: Identifier;
    userIds: Identifier[];
    adminId: Identifier;
  }) => Promise<unknown>;
  getGroupRoles: <RecordType extends Record = IGroupRole>(
    groupId: Identifier,
  ) => Promise<GetManyResult<RecordType>>;
  assignGroupAdmin: (groupId: number, userIds: number[]) => Promise<unknown>;
  revokeGroupAdmin: (groupId: number, userIds: number[]) => Promise<unknown>;
  getUserInnerGroups: <RecordType extends Record>(
    groupId: Identifier,
    username: string,
  ) => Promise<GetOneResult<RecordType>>;
  uploadGroupsCSV: (
    communityId: number,
    adminId: number,
    file: IReactAdminFile,
  ) => Promise<CreateResult>;
};

const groupProvider: GroupProvider = {
  getCommunityOfGroup: (groupId: Identifier) => {
    const url = API_ADMIN_URL + `/groups/${groupId}/community`;
    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data,
        });
      })
      .catch(err => {
        return Promise.reject(err);
      });
  },

  moveGroup: (groupId: Identifier, outerGroupId: Identifier) => {
    const url = API_ADMIN_URL + `/groups/${groupId}/move`;
    const body = {
      outer_group_id: outerGroupId,
    };

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(({json}) => {
        return Promise.resolve(json);
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  getInnerGroups: (groupId: Identifier) => {
    const url = API_ADMIN_URL + `/groups/${groupId}/tree`;

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({data: json.data});
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  getGroupMembers: (groupId: Identifier, query: IQuery) => {
    /**
     * Somehow there is field groupId right in the queryString when stringify(query)
     * although that field was not there. Therefore, we need to remove it.
     */
    const queryString = stringify(query).replace(/(groupId=)\d+&/g, '');
    const url = API_ADMIN_URL + `/groups/${groupId}/users?${queryString}`;

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data.map(mapGroupMember),
          total: json.meta.total,
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  getGroupJoinableUsers: (groupId: Identifier, query: IQuery) => {
    delete query.groupId;

    // FIXME: remove delete query.sort, when BE supports sorting
    delete query.sort;

    const url =
      API_ADMIN_URL + `/groups/${groupId}/joinable-users?${stringify(query)}`;

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data,
          total: json.meta.total,
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  removeUsersFromGroup: ({groupId, userIds, adminId}) => {
    const url = API_ADMIN_URL + `/groups/${groupId}/users/remove`;
    const body = {
      user_ids: userIds,
      actor_id: adminId,
    };

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(({json}) => {
        return Promise.resolve({
          data: json.data,
          total: json.meta.total,
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  getGroupRoles: (groupId: Identifier) => {
    const url = API_ADMIN_URL + `/groups/${groupId}/roles`;

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data.map(mapGroupRole),
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  assignGroupAdmin: (groupId: number, userIds: number[]) => {
    const url = API_ADMIN_URL + `/groups/${groupId}/assign-admin`;

    const body = {
      user_ids: [...userIds],
    };

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(({json}) => {
        return Promise.resolve(json);
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },
  revokeGroupAdmin: (groupId: number, userIds: number[]) => {
    const url = API_ADMIN_URL + `/groups/${groupId}/revoke-admin`;

    const body = {
      user_ids: [...userIds],
    };

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(({json}) => {
        return Promise.resolve(json);
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  getUserInnerGroups: (groupId: Identifier, username: string) => {
    const query = {
      username,
    };
    const url =
      API_ADMIN_URL + `/groups/${groupId}/inner-groups?${stringify(query)}`;

    return httpClient(url)
      .then(({json}) => {
        return Promise.resolve({
          data: json.data.inner_groups,
          total: json.data.inner_groups.length,
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },

  uploadGroupsCSV: (
    communityId: number,
    adminId: number,
    file: IReactAdminFile,
  ) => {
    const url = API_ADMIN_URL + '/groups/build-tree-from-csv';
    if (!file) return Promise.reject('No file to upload');

    const formData = new FormData();
    formData.append('community_id', communityId.toString());
    formData.append('admin_id', adminId.toString());
    formData.append('file', file.rawFile, file.title);

    return httpClient(url, {
      method: 'POST',
      body: formData,
    })
      .then(({json}) => {
        /**
         * Add id as this will be used in dataProvider.create(),
         * which requires return in Promise<{data: {id: number,}}>
         */
        return Promise.resolve({
          data: {
            id: communityId,
            message: json.meta.message,
          },
        });
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },
};

export const processGroupData = async (
  record: Record,
  previousRecord: Record,
): Promise<Record> => {
  const newRecord: Record = {
    ...record,
  };

  newRecord.icon = await getPhotoUrl(
    record.icon,
    previousRecord.icon,
    'group-avatar',
  );
  newRecord.background_img_url = await getPhotoUrl(
    record.background_img_url,
    previousRecord.background_img_url,
    'group-cover',
  );

  return newRecord;
};

export default groupProvider;
