import React from 'react';
import {
  Datagrid,
  ExportButton,
  Identifier,
  List,
  ListProps,
  TextInput,
  TopToolbar,
} from 'react-admin';
import PropTypes from 'prop-types';

import FullnameField from '../../users/FullnameField';
import RolesField from './RolesField';
import { BASEPATHS, RESOURCES, SortOrder } from '../../common/constants';
import { Typography } from '@material-ui/core';

const communityMembersListFilter = [
  <TextInput
    key="search"
    label="Search"
    placeholder="Enter name"
    source="key"
    alwaysOn
    style={{ width: 400 }}
  />,
];

interface communityIdProp {
  communityId: Identifier;
}

const CommunityMembersListActions = () => (
  <TopToolbar>
    <ExportButton
      basePath={BASEPATHS.COMMUNITY_MEMBERS}
      resource={RESOURCES.COMMUNITY_MEMBERS}
    />
  </TopToolbar>
);

CommunityMembersListActions.propTypes = {
  communityId: PropTypes.any,
};

interface CommunityMembersProps extends ListProps {
  communityId: Identifier;
}

const CommunityMembersListEmpty: React.FC<communityIdProp> = () => (
  <Typography color="error">
    No members found. Please, contact BIC team to initiate a new member list
  </Typography>
);

CommunityMembersListEmpty.propTypes = {
  communityId: PropTypes.any,
};

const CommunityMembersList: React.FC<CommunityMembersProps> = ({
  communityId,
  ...props
}: CommunityMembersProps) => {
  return (
    <List
      {...props}
      filters={communityMembersListFilter}
      filter={{ communityId }}
      actions={<CommunityMembersListActions communityId={communityId} />}
      empty={<CommunityMembersListEmpty communityId={communityId} />}
      sort={{
        field: 'roles',
        order: SortOrder.DESC,
      }}
      title=" ">
      <Datagrid>
        <FullnameField />
        <RolesField />
      </Datagrid>
    </List>
  );
};

CommunityMembersList.propTypes = {
  communityId: PropTypes.any,
};

CommunityMembersList.defaultProps = {
  basePath: BASEPATHS.COMMUNITY_MEMBERS,
  resource: RESOURCES.COMMUNITY_MEMBERS,
};

export default CommunityMembersList;
