import React from 'react';
import {
  BulkExportButton,
  Datagrid,
  ExportButton,
  List,
  ListProps,
  TextInput,
  TopToolbar,
  CreateButton,
  BooleanInput,
} from 'react-admin';
import DateField from '../components/DateField';
import FullnameField from './FullnameField';
import { Box, Typography } from '@material-ui/core';
import ButtonImport from '../massUpload/ButtonImport';
import ImportCard from '../massUpload/ImportCard';
import { BulkActionButtonsProps } from '../interfaces/common';
import { SortOrder } from '../common/constants';
import EmailField from './EmailField';

const userFilters = [
  <TextInput
    key="search"
    label="Search"
    placeholder="Enter name or email"
    source="key"
    alwaysOn
    style={{ width: 300 }}
  />,
  <BooleanInput
    key="include_deactivated"
    label="Show inactive accounts"
    source="include_deactivated" // Do not change: BE defined this query
    alwaysOn
  />,
];

const UserListActions = () => (
  <TopToolbar>
    <ButtonImport type="users" />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const UserBulkActionButtons = (props: BulkActionButtonsProps) => (
  <React.Fragment>
    <BulkExportButton {...props} />
  </React.Fragment>
);

const Empty = () => (
  <>
    <Box marginTop={2} marginBottom={2}>
      <Typography>
        No user in the database, to create new one, please press
        <span style={{ marginRight: 8, marginLeft: 8 }}>
          <CreateButton />
        </span>
      </Typography>
    </Box>
    <ImportCard type="users" />
  </>
);

const UserList = (props: ListProps): JSX.Element => (
  <List
    {...props}
    filters={userFilters}
    actions={<UserListActions />}
    title="Users list"
    sort={{
      field: 'fullname',
      order: SortOrder.ASC,
    }}
    empty={<Empty />}
    bulkActionButtons={<UserBulkActionButtons />}>
    <Datagrid rowClick="show" size="medium">
      <FullnameField />
      <EmailField source="email" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export default UserList;
