import React from 'react';
import {Record} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import MaterialAvatar, {AvatarProps} from '@material-ui/core/Avatar';
import {MainResources} from '../interfaces/common';
import {RESOURCES} from '../common/constants';

const avatarStyles = makeStyles(() => ({
  fallback: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F9F9F9',
  },
}));

interface Props extends AvatarProps {
  record?: Record;
  resource?: MainResources;
  className?: string;
  size?: number;
}

const Avatar = ({
  src,
  record,
  resource,
  size = 36,
  className,
}: Props): JSX.Element => {
  const classes = avatarStyles();
  let _src;

  switch (resource) {
    case RESOURCES.USERS:
      _src = record?.avatar;
      break;
    case RESOURCES.COMMUNITIES:
    case RESOURCES.GROUPS:
      _src = record?.icon;
      break;
    default:
      _src = src;
      break;
  }

  const renderFallback = () => {
    return <div className={classes.fallback} />;
  };

  return (
    <MaterialAvatar
      src={_src}
      style={{width: size, height: size}}
      className={className}>
      {!_src && renderFallback()}
    </MaterialAvatar>
  );
};

export default Avatar;
