export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum PrivacyTypes {
  OPEN = 'OPEN',
  PRIVATE = 'PRIVATE',
  SECRET = 'SECRET',
  CLOSED = 'CLOSED',
}

export const communityPrivacyChoices = [
  {id: PrivacyTypes.OPEN, name: 'Open'},
  {id: PrivacyTypes.PRIVATE, name: 'Private'},
];

export const groupPrivacyChoices = [
  ...communityPrivacyChoices,
  {id: PrivacyTypes.CLOSED, name: 'Closed'},
  {id: PrivacyTypes.SECRET, name: 'Secret'},
];

export const RESOURCES = {
  // Main resources
  USERS: 'users',
  COMMUNITIES: 'communities',
  GROUPS: 'groups',

  // Extended resources
  COMMUNITY_MEMBERS: 'communityMembers',
  COMMUNITY_ADMINS: 'communityAdmins',
  COMMUNITY_JOINABLE_USERS: 'communityJoinableUsers',
  GROUP_MEMBERS: 'groupMembers',
  GROUP_ADMINS: 'groupAdmins',
  GROUP_JOINABLE_USERS: 'groupJoinableUsers',
  MEMBERS_FOR_IMPORT: 'membersForImport',
  MEMBERS_TO_COM: '/membersToCom',
};

export const BASEPATHS = {
  // Main resources
  USERS: '/users',
  COMMUNITIES: '/communities',
  GROUPS: '/groups',

  // Extended resources
  COMMUNITY_MEMBERS: '/communityMembers',
  COMMUNITY_ADMINS: '/communityAdmins',
  COMMUNITY_JOINABLE_USERS: '/communityJoinableUsers',
  GROUP_MEMBERS: '/groupMembers',
  GROUP_ADMINS: '/groupAdmins',
  GROUP_JOINABLE_USERS: '/groupJoinableUsers',
  MEMBERS_FOR_IMPORT: '/membersForImport',
  MEMBERS_TO_COM: '/membersToCom',
};

export const USER_VALIDATION_RULE = {
  FULLNAME: {
    MIN_LENGTH: 1,
    MAX_LENGTH: 64,
  },
  PASSWORD: {
    MIN_LENGTH: 8,
    MAX_LENGTH: 20,
    /**
     * Allow anything but whitespace
     */
    EASY_REGEX: /^[\S]+.*[\S]+$/,
    /**
     * At least one of each case below:
     * - Lower case letter (a-z)
     * - Upper case letter (A-Z)
     * - Digits (0-9)
     * - Special characters including common punctuation and symbols
     * @example
     * @dMin123, B3iNc0mm^333
     */
    STRICT_REGEX:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()])[A-Za-z\d~!@#$%^&*()]*$/,

    SPECIAL_CHARACTERS_REGEX: /[.,;:!?@#$%^&*()\-_+={}[\]|\\<> ]/,
  },
  EMAIL: {
    MAX_LENGTH: 128,
  },
  USERNAME: {
    MIN_LENGTH: 5,
    MAX_LENGTH: 50,
  },
  DESCRIPTION: {
    MAX_LENGTH: 250,
  },
  PHONE: {
    MIN_LENGTH: 7,
    MAX_LENGTH: 12,
  },
};

export const GROUP_VALIDATION_RULE = {
  NAME: {
    MIN_LENGTH: 1,
    MAX_LENGTH: 64,
  },
  SLUG: {
    MIN_LENGTH: 3,
    MAX_LENGTH: 64,
  },
  DESCRIPTION: {
    MAX_LENGTH: 1024,
  },
};

export const COMMUNITY_VALIDATION_RULE = {
  NAME: {
    MIN_LENGTH: 3,
    MAX_LENGTH: 64,
  },
  SLUG: {
    MIN_LENGTH: 3,
    MAX_LENGTH: 64,
  },
  DESCRIPTION: {
    MAX_LENGTH: 255,
  },
};

export const BASE_GROUP_ROLES = {
  GROUP_ADMIN: {
    name: 'Admin',
    type: 'GROUP_ADMIN',
  },
  GROUP_MEMBER: {
    name: 'Member',
    type: 'GROUP_MEMBER',
  },
};

export const UPLOAD_INTERVAL_DURATION_IN_MILLISECONDS = 500;

export const BASE_DRAWER_WIDTH_HAVING_LIST = 800;

export const MAX_NAME_COLUMN_WIDTH = 500;
