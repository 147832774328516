const types = {
  SET_ADMIN_ID_TO_EDIT_MEMBER_LIST: 'common/set_admin_id_to_edit_member_list',
  SET_SHOW_ADMIN_PICKER_DRAWER: 'common/set_show_admin_picker_drawer',
  SET_SHOW_ADD_MEMBER_DRAWER: 'common/set_show_add_member_drawer',
  SET_SHOW_DEACTIVATE_MEMBER_DRAWER: 'common/set_show_deactivate_member_drawer',
  SET_SHOW_ALERT_MUST_PICK_ADMIN: 'common/set_show_alert_must_pick_admin',
  SET_MEMBER_INNER_GROUPS: 'common/set_member_inner_groups',
  SHOW_ALERT_REMOVE_MEMBER: 'common/show_alert_remove_member',
  HIDE_ALERT_REMOVE_MEMBER: 'common/hide_alert_remove_member',
};

export default types;
