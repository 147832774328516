import React from 'react';
import { createHashHistory } from 'history';
import { Admin, Resource } from 'react-admin';
import { Amplify } from 'aws-amplify';
import '@nosferatu500/react-sortable-tree/style.css';

import rootSaga from './store/sagas';
import awsExports from './aws-exports';
import { dataProvider, authProvider } from './providers';
import { Layout } from './layout';
import { Dashboard } from './dashboard';
import NotFound from './NotFound';
import { lightTheme } from './layout/theme';
import users from './users';
import communities from './communities';
import groups from './groups';
import entities from './store/entities';
import routes from './routes';
import AlertRemoveMember from './components/Buttons/RemoveMemberButton/AlertRemoveMember';
import AlertMustPickAdmin from './components/Buttons/AdminPickerButton/AlertMustPickAdmin';
import { RESOURCES } from './common/constants';
import ToastUndoController from './components/ToastUndoController';

Amplify.configure(awsExports);
// dependency injection
const history = createHashHistory();

function App(): JSX.Element {
  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      customReducers={{ entities }}
      customSagas={[rootSaga]}
      history={history}
      title="Bein Staff"
      dashboard={Dashboard}
      // loginPage={Login}
      customRoutes={routes}
      layout={Layout}
      theme={lightTheme}
      catchAll={NotFound}>
      <Resource name={RESOURCES.USERS} {...users} />
      <Resource name={RESOURCES.COMMUNITIES} {...communities} />
      <Resource name={RESOURCES.GROUPS} {...groups} />
      {/*
      Extended resources so we do not need to swap in and out
      when selecting records
       */}
      <Resource name={RESOURCES.COMMUNITY_MEMBERS} />
      <Resource name={RESOURCES.COMMUNITY_ADMINS} />
      <Resource name={RESOURCES.COMMUNITY_JOINABLE_USERS} />
      <Resource name={RESOURCES.GROUP_MEMBERS} />
      <Resource name={RESOURCES.GROUP_ADMINS} />
      <Resource name={RESOURCES.GROUP_JOINABLE_USERS} />
      <Resource name={RESOURCES.MEMBERS_FOR_IMPORT} />
      {/*
      Put alert remove member here to avoid it being
      render multiple times when put within Show
      */}
      <AlertRemoveMember />
      <AlertMustPickAdmin />
      <ToastUndoController />
    </Admin>
  );
}

export default App;
