import React, {useEffect, useState} from 'react';
import {
  BulkExportButton,
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  Identifier,
  List,
  ListProps,
  Record,
  ReferenceField,
  TextField,
  TopToolbar,
  useDataProvider,
} from 'react-admin';

import DateField from '../components/DateField';
import GroupNameField from './GroupNameField';
import {Box, Typography} from '@material-ui/core';
import ButtonImport from '../massUpload/ButtonImport';
import ImportCard from '../massUpload/ImportCard';
import GroupListAside from './GroupListAside';
import CommunityReferenceInput from '../communities/CommunityReferenceInput';
import {BulkActionButtonsProps} from '../interfaces/common';
import {RESOURCES, SortOrder} from '../common/constants';

const GroupListActions = () => (
  <TopToolbar>
    <ButtonImport type="groups" />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const GroupsBulkActionButtons = (props: BulkActionButtonsProps) => (
  <React.Fragment>
    <BulkExportButton {...props} />
  </React.Fragment>
);

const Empty = () => (
  <>
    <Box marginTop={2} marginBottom={2}>
      <Typography>
        No group in the database, to create new one, please press
        <span style={{marginRight: 8, marginLeft: 8}}>
          <CreateButton />
        </span>
      </Typography>
    </Box>
    <ImportCard type="groups" />
  </>
);

const groupFilters = [
  <CommunityReferenceInput
    key="suggestions"
    source="community_id"
    label="Community"
    reference={RESOURCES.COMMUNITIES}
    allowEmpty={false}
    alwaysOn
  />,
];

const GroupList: React.FC<ListProps> = props => {
  const renderDescription = (record?: Record) => {
    if (!record?.description) return null;
    const desc = record.description;
    const limitChar = 50;

    return desc.length < limitChar ? desc : desc.substr(0, 50) + '...';
  };

  const dataProvider = useDataProvider();

  type DefaultFilter = {
    community_id?: Identifier;
  };
  const [defaultFilter, setDefaultFilter] = useState<DefaultFilter>({
    commmunity_id: undefined,
  } as DefaultFilter);

  useEffect(() => {
    const newDefaultFilter: DefaultFilter = {
      community_id: undefined,
    };

    // Fetch and set the first community id into the default filter
    dataProvider
      .getList(RESOURCES.COMMUNITIES, {
        pagination: {page: 1, perPage: 1},
        sort: {field: 'id', order: SortOrder.ASC},
        filter: {},
      })
      .then(({data}) => {
        newDefaultFilter.community_id = data[0]?.id;
      })
      .catch(error => {
        console.error('[DEBUG] error in group list', {error});
      })
      .finally(() => setDefaultFilter(newDefaultFilter));
  }, []);

  return (
    <List
      {...props}
      filters={groupFilters}
      filterDefaultValues={defaultFilter}
      empty={<Empty />}
      actions={<GroupListActions />}
      aside={<GroupListAside />}
      title="Groups list"
      sort={{
        field: 'name',
        order: SortOrder.ASC,
      }}
      bulkActionButtons={<GroupsBulkActionButtons />}>
      <Datagrid rowClick="show" size="medium">
        <GroupNameField />
        <ReferenceField
          label="Direct outer"
          reference={RESOURCES.GROUPS}
          source="parent_id"
          link={false} // turn off auto wrapping content in <a></a>
          emptyText="N/A">
          <GroupNameField />
        </ReferenceField>
        <FunctionField label="Description" render={renderDescription} />
        <TextField source="user_count" />
        <TextField source="level" />
        <TextField source="privacy" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Datagrid>
    </List>
  );
};

export default GroupList;
