import React, {useState, useEffect} from 'react';
import {DashboardMenuItem, MenuItemLink, MenuProps} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Settings from '@material-ui/icons/Settings';
import LoopIcon from '@material-ui/icons/Loop';

import users from '../users';
import communities from '../communities';
import groups from '../groups';
import SubMenu from './SubMenu';
import {importRecord} from '../massUpload/common';
import {authProvider} from '../providers';
import {BeinStaffRole} from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

const Menu: React.FC<MenuProps> = ({dense = false}: MenuProps) => {
  const [isMassUploadOpen, setIsMassUploadOpen] = useState(false);
  const [permissions, setPermissions] = useState(BeinStaffRole.STAFF);

  const classes = useStyles();

  useEffect(() => {
    const getInfoLogin = async () => {
      const response = await authProvider.getPermissions({});

      setPermissions(response);
    };

    getInfoLogin();
  }, []);

  return (
    <div className={classes.root}>
      <DashboardMenuItem />
      <MenuItemLink
        to="/users"
        primaryText="Users"
        leftIcon={<users.icon />}
        dense={dense}
        replace
      />
      <MenuItemLink
        to="/communities"
        primaryText="Communities"
        leftIcon={<communities.icon />}
        dense={dense}
        replace
      />
      <MenuItemLink
        to="/groups"
        primaryText="Groups"
        leftIcon={<groups.icon />}
        dense={dense}
        replace
      />
      <SubMenu
        handleToggle={() => setIsMassUploadOpen(!isMassUploadOpen)}
        isOpen={isMassUploadOpen}
        name="Mass Upload"
        dense={dense}
        icon={<CloudUploadIcon />}>
        <MenuItemLink
          to={importRecord.users.path}
          primaryText="Users"
          leftIcon={<users.iconAdd />}
          dense={dense}
          replace
        />
        <MenuItemLink
          to={importRecord.groups.path}
          primaryText="Groups"
          leftIcon={<groups.iconAdd />}
          dense={dense}
          replace
        />
        <MenuItemLink
          to={importRecord.members.path}
          primaryText="Import group members"
          leftIcon={<LibraryAddIcon />}
          dense={dense}
          replace
        />
        <MenuItemLink
          to={importRecord.membersToCom.path}
          primaryText="Import com members"
          leftIcon={<LibraryAddIcon />}
          dense={dense}
          replace
        />
      </SubMenu>
      {permissions === BeinStaffRole.SUPER_ADMIN && (
        <>
          <MenuItemLink
            to="/maintenance"
            primaryText="Maintenance"
            leftIcon={<Settings />}
            dense={dense}
            replace
          />
          <MenuItemLink
            to="/change-logs"
            primaryText="Changelogs"
            leftIcon={<LoopIcon />}
            dense={dense}
            replace
          />
        </>
      )}
    </div>
  );
};

export default Menu;
