import React from 'react';
import {Typography} from '@material-ui/core';

const SectionTitle = ({label}: {label: string}): JSX.Element => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

export default SectionTitle;
