import {Identifier, Record, useNotify, useRedirect} from 'ra-core';
import {
  Create,
  CreateProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'ra-ui-materialui';
import React, {useState} from 'react';
import {groupPrivacyChoices, RESOURCES} from '../common/constants';
import {groupValidators} from '../common/validation';

import CommunityReferenceInput from '../communities/CommunityReferenceInput';
import SectionTitle from '../components/SectionTitle';
import UserReferenceInput from '../users/UserReferenceInput';
import GroupReferenceInput from './GroupReferenceInput';

const GroupCreate: React.FC<CreateProps> = props => {
  const [selectedCommunityId, setSelectedCommunityId] = useState<number>(0);

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (response: {data: {name: string; id: Identifier}}) => {
    const message = `Create new group "${response.data.name}" successfully`;
    notify(message, 'success');

    //just to make sure data is fully created on server
    setTimeout(() => {
      redirect(`/groups/${response.data.id}/show`);
    }, 100);
  };

  const onChangeCommunity = (value: number) => {
    setSelectedCommunityId(value);
  };

  const transform = (data: Record) => {
    const name = data.name.trim();
    return {
      ...data,
      name,
    };
  };

  return (
    <Create onSuccess={onSuccess} transform={transform} {...props}>
      <SimpleForm>
        <SectionTitle label="New group information" />
        <TextInput
          source="name"
          label="Group name"
          validate={groupValidators.name}
          autoFocus
          inputProps={{autoComplete: 'off'}}
        />
        <CommunityReferenceInput
          source="community_id"
          label="Community"
          validate={groupValidators.communityId}
          reference={RESOURCES.COMMUNITIES}
          resettable
          perPage={50}
          onChange={onChangeCommunity}
        />
        <GroupReferenceInput
          source="parent_id"
          label="Outer Group"
          reference={RESOURCES.GROUPS}
          communityId={selectedCommunityId}
          resettable
          perPage={50}
        />
        <UserReferenceInput
          validate={groupValidators.adminId}
          label="Group Admin"
          source="admin_id"
          reference={RESOURCES.COMMUNITY_MEMBERS}
          filter={{communityId: selectedCommunityId}}
          disabled={!selectedCommunityId}
          resettable
          perPage={50}
        />
        <TextInput
          source="description"
          validate={groupValidators.description}
          multiline
          resettable
        />
        <SelectInput
          source="privacy"
          choices={groupPrivacyChoices}
          initialValue={groupPrivacyChoices[0].id}
        />
      </SimpleForm>
    </Create>
  );
};

export default GroupCreate;
