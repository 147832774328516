import React from 'react';
import {Route} from 'react-router-dom';

import ImportCard from './massUpload/ImportCard';
import {importRecord} from './massUpload/common';
import EditGroupTree from './groups/EditGroupTree/EditGroupTree';
import {Maintenance} from './maintenance';
import {Changelogs} from './Changelogs';

export default [
  <Route
    key={`import-${importRecord.users.resource}`}
    exact
    path={importRecord.users.path}
    render={() => (
      <ImportCard type="users" navigateToListOnSuccess title="Import users" />
    )}
  />,
  <Route
    key={`import-${importRecord.groups.resource}`}
    exact
    path={importRecord.groups.path}
    render={() => (
      <ImportCard type="groups" navigateToListOnSuccess title="Import groups" />
    )}
  />,
  <Route
    key={`import-${importRecord.members.resource}`}
    exact
    path={importRecord.members.path}
    render={() => <ImportCard type="members" title="Import users to Group" />}
  />,
  <Route
    key={`import-${importRecord.membersToCom.resource}`}
    exact
    path={importRecord.membersToCom.path}
    render={() => (
      <ImportCard type="membersToCom" title="Import users to Community" />
    )}
  />,
  <Route key={'edit-community-tree'} path={'/communities/:id/edit-tree'}>
    <EditGroupTree mode="community" />
  </Route>,
  <Route key={'edit-group-tree'} path={'/groups/:id/edit-tree'}>
    <EditGroupTree mode="group" />
  </Route>,
  <Route key={'maintenance'} path={'/maintenance'}>
    <Maintenance />
  </Route>,
  <Route key={'change-logs'} path={'/change-logs'}>
    <Changelogs />
  </Route>,
];
