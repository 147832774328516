import React, {memo} from 'react';

import {makeStyles} from '@material-ui/core';
import Icon, {IconName} from '../Icon/Icon';

const useStyles = makeStyles(theme => ({
  buttonStyle: {
    display: 'flex',
    overflow: 'hidden',
    width: '36px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgb(227, 230, 242)',
  },
}));

function InlineStyleButton({
  onToggle,
  active = false,
  icon,
  style = '',
}: {
  onToggle?: (blockType: string) => void;
  active?: boolean;
  label?: string;
  icon: IconName;
  style?: string;
  className?: string;
}) {
  const classes = useStyles();

  const contentNode = (
    <div
      onMouseDown={e => {
        e.preventDefault();
        onToggle?.(style);
      }}
      className={classes.buttonStyle}
      style={{backgroundColor: active ? 'rgba(0, 0, 0, 0.06)' : '#fff'}}>
      <Icon name={icon} size="16px" color="text-neutral-40" />
    </div>
  );

  return contentNode;
}

export default memo(InlineStyleButton);
