import {combineReducers} from 'redux';
import groupsReducer from '../groups/redux/reducer';
import usersReducer from '../users/redux/reducer';
import commonReducer from './common/reducer';

const entities = combineReducers({
  users: usersReducer,
  groups: groupsReducer,
  common: commonReducer,
});

export default entities;
