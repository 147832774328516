import {put, takeEvery} from 'redux-saga/effects';
import * as types from './types';
import * as actions from './actions';

export default function* usersSaga(): Generator {
  yield takeEvery(types.OPEN_SHOW_ADD_TO_GROUP_DRAWER, openAddToGroupDrawer);
  yield takeEvery(types.CLOSE_SHOW_ADD_TO_GROUP_DRAWER, closeAddToGroupDrawer);
}

function* openAddToGroupDrawer() {
  try {
    yield put(actions.setShowAddToGroupDrawer(true));
  } catch (err) {
    console.error('Error while opening addToGroupDrawer', err);
  }
}

function* closeAddToGroupDrawer() {
  try {
    yield put(actions.setShowAddToGroupDrawer(false));
  } catch (err) {
    console.error('Error while closing addToGroupDrawer', err);
  }
}
