import React, {useEffect} from 'react';
import {Layout, LayoutProps} from 'react-admin';
import Menu from './Menu';
import AppBar from './AppBar';
import adminInfoStore from '../store/adminInfoStore';
import {Hub} from 'aws-amplify';
import {HubCallback} from '@aws-amplify/core';

export default (props: LayoutProps): JSX.Element => {
  useEffect(function listenToAuth() {
    const listener: HubCallback = capsule => {
      const handleSignOut = () => {
        adminInfoStore.clearInfo();
      };

      switch (capsule.payload.event) {
        case 'signOut':
          handleSignOut();
          break;

        default:
          break;
      }

      Hub.listen('auth', listener);
    };
    return () => {
      Hub.remove('auth', listener);
    };
  }, []);

  return <Layout {...props} appBar={AppBar} menu={Menu} />;
};
