import React, {useEffect} from 'react';
import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  ReferenceField,
  ShowProps,
  ImageField,
  useUnselectAll,
} from 'react-admin';
import {Box} from '@material-ui/core';

import Aside from './Aside';
import FullnameField from '../users/FullnameField';
import GroupMembers from './GroupMembers/GroupMembers';
import GroupTitle from './GroupTitle';
import {RESOURCES} from '../common/constants';
import {useClearSelectedAdmin} from '../hooks';
import {useParams} from 'react-router-dom';

const GroupShow: React.FC<ShowProps> = props => {
  const unselectAll = useUnselectAll();
  const clearSelectedAdmin = useClearSelectedAdmin();
  const {id: groupId} = useParams() as {id: string};

  useEffect(() => {
    unselectAll(RESOURCES.GROUP_MEMBERS);
    unselectAll(RESOURCES.GROUP_JOINABLE_USERS);
    clearSelectedAdmin('group');
  }, [groupId]);

  return (
    <Show {...props} title={<GroupTitle />} aside={<Aside />}>
      <TabbedShowLayout>
        <Tab label="Detail">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          <NumberField source="level" />
          <ReferenceField source="owner_id" reference={RESOURCES.USERS}>
            <FullnameField clickable={false} />
          </ReferenceField>
          <ImageField source="background_img_url" label="Background image" />
          <TextField source="privacy" />
          <NumberField source="user_count" />
        </Tab>
        <Tab label="Members" path="members">
          <Box marginBottom="1em">
            <GroupMembers />
          </Box>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default GroupShow;
