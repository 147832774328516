import React from 'react';
import {Link, FieldProps} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {IGroup} from '../interfaces/IGroup';
import Avatar from '../components/Avatar';
import {Box} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

interface Props extends FieldProps {
  record?: IGroup;
  size?: number;
  clickable?: boolean;
}

const CommunityNameField = ({
  record,
  size = 42,
  clickable = true,
}: Props): JSX.Element => {
  const classes = useStyles();
  const Outer = clickable ? Link : Box;
  const communityId = record?.community_id || record?.id;

  if (record) {
    return (
      <Outer to={`/communities/${communityId}/show`}>
        <div className={classes.root}>
          <Avatar
            record={record}
            resource="communities"
            size={size}
            className={classes.icon}
          />
          {record.name}
        </div>
      </Outer>
    );
  }

  return <></>;
};

CommunityNameField.propTypes = {
  record: PropTypes.any,
  size: PropTypes.number,
};

CommunityNameField.defaultProps = {
  source: 'name',
  addLabel: true,
};

export default CommunityNameField;
