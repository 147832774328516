import React from 'react';
import {useRecordContext} from 'ra-core';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {IUser} from '../interfaces/IUser';
import HistoryCard from '../components/HistoryCard';
import JoinedGroupsTree from './JoinedGroupsTree';

const useAsideStyles = makeStyles(theme => ({
  root: {
    width: 500,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Aside: React.FC = () => {
  const classes = useAsideStyles();
  const record = useRecordContext() as IUser;

  return (
    <div className={classes.root}>
      <Box m="0 0 1em 1em">
        <HistoryCard record={record} />
      </Box>
      <Box m="0 0 0 1em">
        <JoinedGroupsTree />
      </Box>
    </div>
  );
};

export default Aside;
