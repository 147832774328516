import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserShow from './UserShow';

const resource = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: UserShow,
  icon: PersonIcon,
  iconAdd: PersonAddIcon,
};

export default resource;
