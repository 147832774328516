import React, {useState} from 'react';
import {
  Button,
  NumberField,
  SimpleShowLayout,
  ReferenceField,
  TextField,
  useGetOne,
} from 'react-admin';
import {Box, CardContent, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import FullnameField from '../users/FullnameField';
import {IGroup} from '../interfaces/IGroup';
import GroupNameField from './GroupNameField';
import {BASEPATHS, RESOURCES} from '../common/constants';

const useStyles = makeStyles({
  field: {
    // These styles will ensure our drawer don't fully cover our
    // application when teaser or title are very long
    '& span': {
      display: 'inline-block',
      minWidth: '20rem',
      maxWidth: '24rem',
    },
  },
});

interface Props {
  id: number;
}

const UserQuickPreviewButton: React.FC<Props> = ({id}) => {
  const [showPanel, setShowPanel] = useState(false);
  const classes = useStyles();

  // Add unknown to fix if field created_at or any other field not exists
  const {data} = useGetOne(RESOURCES.GROUPS, id) as unknown as IGroup;

  const onOpen = () => {
    setShowPanel(true);
  };

  const onClose = () => {
    setShowPanel(false);
  };

  return (
    <>
      <Button onClick={onOpen} label="ra.action.show">
        <IconImageEye />
      </Button>
      <Drawer anchor="right" open={showPanel} onClose={onClose}>
        <CardContent
          style={{
            paddingTop: 0,
            paddingLeft: 16,
          }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            paddingLeft="1rem">
            <GroupNameField record={data} />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <SimpleShowLayout
            record={data}
            basePath={BASEPATHS.GROUPS}
            resource={RESOURCES.GROUPS}>
            <TextField source="id" className={classes.field} />
            <TextField source="description" className={classes.field} />
            <NumberField source="level" className={classes.field} />
            {/* TODO: Fix ReferenceField not fetch right away,
            don't know why it doesn't fetch until we change code why on that page */}
            <ReferenceField reference={RESOURCES.USERS} source="owner_id">
              <FullnameField clickable={false} />
            </ReferenceField>
            <TextField source="privacy" className={classes.field} />
            <NumberField source="user_count" className={classes.field} />
          </SimpleShowLayout>
        </CardContent>
      </Drawer>
    </>
  );
};

UserQuickPreviewButton.propTypes = {
  id: PropTypes.number.isRequired,
};

export default UserQuickPreviewButton;
