/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import {EditorState, RichUtils} from 'draft-js';
import {makeStyles} from '@material-ui/core';

import {
  EDITOR_INLINE_STYLE_MAP,
  EditorStyleMap,
} from '../../constants/draftjsEditor';
import InlineStyleButton from './InlineStyleButton';

const useStyles = makeStyles(theme => ({
  controlsStyle: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '0.375rem',
    borderWidth: '1px',
    alignItems: 'center',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderColor: 'rgb(227, 230, 242)',
  },
}));

function InlineStyleControls({
  editorState,
  setEditorState,
  styleMap = EDITOR_INLINE_STYLE_MAP,
}: {
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
  styleMap?: EditorStyleMap;
}) {
  const classes = useStyles();

  const currentStyle = editorState.getCurrentInlineStyle();

  const onToggle = (inlineStyle: string) => {
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    setEditorState(newState);
  };

  return (
    <div className={classes.controlsStyle}>
      {Object.keys(styleMap).map((style: string) => {
        const editorInlineStyle = styleMap[style];
        if (!editorInlineStyle) return null;
        const {icon} = editorInlineStyle;

        return (
          <InlineStyleButton
            key={style}
            active={currentStyle.has(style)}
            icon={icon}
            onToggle={onToggle}
            style={style}
          />
        );
      })}
    </div>
  );
}

export default InlineStyleControls;
