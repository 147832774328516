import React, {FC} from 'react';
import {FieldProps} from 'react-admin';
import PropTypes from 'prop-types';

import {IUser} from '../interfaces/IUser';
import FullnameField from './FullnameField';

interface Props extends FieldProps {
  record?: IUser;
}

const UserTitle: FC<Props> = ({record}) => {
  if (!record) return <span>User profile</span>;

  return record ? <FullnameField record={record} size={32} /> : null;
};

UserTitle.propTypes = {
  record: PropTypes.any,
};

export default UserTitle;
