import React from 'react';
import {
  Create,
  CreateProps,
  PasswordInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import SectionTitle from '../components/SectionTitle';
import {IObject} from '../interfaces/common';
import {userValidators} from '../common/validation';

const validateUserCreation = (values: unknown) => {
  const errors = {};

  // @ts-ignore
  if (values.password !== values.confirmPassword) {
    // @ts-ignore
    errors.confirmPassword = 'Passwords did not match';
  }

  return errors;
};

const UserCreate: React.FC<CreateProps> = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccess = (response: IObject<any>) => {
    const message = `Create new user "${response.data.fullname}" successfully`;
    notify(message, 'success');

    //just to make sure data is fully created on server
    setTimeout(() => {
      redirect(`/users/${response.data.id}/show`);
    }, 100);
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm validate={validateUserCreation}>
        <SectionTitle label="New user information" />
        <TextInput
          source="fullname"
          autoFocus
          validate={userValidators.fullname}
          inputProps={{autoComplete: 'off'}}
        />
        <TextInput
          source="username"
          validate={userValidators.username}
          inputProps={{autoComplete: 'off'}}
        />
        <TextInput
          source="email"
          validate={userValidators.email}
          inputProps={{autoComplete: 'off'}}
        />
        <PasswordInput
          source="password"
          validate={userValidators.password}
          inputProps={{autoComplete: 'new-password'}}
        />
        <PasswordInput
          source="confirmPassword"
          validate={userValidators.password}
          inputProps={{autoComplete: 'off'}}
        />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
