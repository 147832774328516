import * as types from './types';
import {AnyAction} from 'redux';
import {Identifier} from 'react-admin';
import {ToastProps} from '../../components/ToastUndoController';

export const setShowAddToGroupDrawer = (payload: boolean): AnyAction => ({
  type: types.SET_SHOW_ADD_TO_GROUP_DRAWER,
  payload,
});

export const openAddToGroupDrawer = (): AnyAction => ({
  type: types.OPEN_SHOW_ADD_TO_GROUP_DRAWER,
});

export const closeAddToGroupDrawer = (): AnyAction => ({
  type: types.CLOSE_SHOW_ADD_TO_GROUP_DRAWER,
});

export const setPreviousSelectedGroupIds = (
  payload: Identifier[],
): AnyAction => ({
  type: types.SET_PREVIOUS_SELECTED_GROUP_IDS,
  payload,
});

export const openToastUndo = (payload: ToastProps): AnyAction => ({
  type: types.OPEN_SHOW_TOAST_UNDO,
  payload: payload,
});

export const setClosePopupWindowToastUndo = (): AnyAction => ({
  type: types.SET_CLOSE_POPUP_WINDOW_TOAST_UNDO,
});

export const closeToastUndo = (): AnyAction => ({
  type: types.CLOSE_SHOW_TOAST_UNDO,
});
