import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  GetOneResult,
  Record,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';

import GroupNameField from './GroupNameField';
import GroupTree from './GroupTree';
import { IGroupTree } from '../interfaces/IGroup';
import { useGroups } from '../hooks/groups';
import { setReloadGroupTree } from './redux/actions';
import { RESOURCES } from '../common/constants';
import CommunityNameField from '../communities/CommunityNameField';
import HistoryCard from '../components/HistoryCard';

const useAsideStyles = makeStyles(theme => ({
  root: {
    width: 500,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Aside: React.FC = () => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const classes = useAsideStyles();
  const record = useRecordContext();
  const { reloadGroupTree } = useGroups();
  const dataProvider = useDataProvider();
  const [error, setError] = useState<string>('');
  const [treeData, setTreeData] = useState<IGroupTree[]>([]);

  const [loadingInnerGroups, setLoadingInnerGroups] = useState(true);
  const [communityData, setCommunityData] = useState<Record>({ id: -1 });
  const [parentData, setParentData] = useState<Record>({ id: -1 });
  const isRecordUndefined = !record || !record.id;
  const DEBOUNCE_TIME = 300;

  const loadInnerGroup = debounce(() => {
    if (isRecordUndefined) return;

    setLoadingInnerGroups(true);
    dataProvider
      .getInnerGroups(record.id)
      .then((res: Record) => {
        if (!res) {
          setError(`Couldn't fetch inner groups, please reload page.`);
          return;
        }
        const rootTreeData = toggleExpandedForAll({
          treeData: [{ ...res.data }],
          expanded: true,
        }) as unknown as IGroupTree[];
        setTreeData(rootTreeData);
      })
      .catch(() => {
        notify('Error when fetching inner groups', 'error');
        setTreeData([]);
      })
      .finally(() => setLoadingInnerGroups(false));
  }, DEBOUNCE_TIME);

  const loadCommunityData = debounce(() => {
    dataProvider
      .getCommunityOfGroup(record.id)
      .then((value: GetOneResult<Record>) => setCommunityData(value.data))
      .catch(() => {
        notify('Error when fetching community', 'error');
        setCommunityData({ id: -1 });
      });
  }, DEBOUNCE_TIME);

  const loadParentData = debounce(() => {
    dataProvider
      .getOne(RESOURCES.GROUPS, { id: record.parent_id })
      .then((value: GetOneResult<Record>) => setParentData(value.data))
      .catch(() => {
        notify('Error when fetching direct outer group', 'error');
        setParentData({ id: -1 });
      });
  }, DEBOUNCE_TIME);

  useEffect(() => {
    if (isRecordUndefined) return;
    if (record.parent_id) loadParentData();
    loadCommunityData();
    loadInnerGroup();

    return () => {
      setTreeData([]);
      setCommunityData({ id: -1 });
      setParentData({ id: -1 });
      setLoadingInnerGroups(false);
    };
  }, [record]);

  useEffect(() => {
    if (reloadGroupTree) {
      loadInnerGroup();
      dispatch(setReloadGroupTree(false));
    }
  }, [reloadGroupTree]);

  if (isRecordUndefined) return <></>;

  const renderInnerGroups = () => {
    if (loadingInnerGroups)
      return (
        <>
          <Typography variant="body2">Getting inner groups</Typography>
          <CircularProgress color="inherit" size="1rem" />
        </>
      );

    const currentGroup = treeData[0];
    const hasNoChildren =
      !currentGroup?.children || currentGroup.children?.length === 0;
    if (hasNoChildren)
      return <Typography variant="body2">There is no inner group.</Typography>;

    if (error)
      return (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      );

    return (
      <Box height="50vh">
        <GroupTree
          treeData={treeData}
          canDrag={() => false}
          onChange={(treeData: IGroupTree[]) => setTreeData(treeData)}
        />
      </Box>
    );
  };

  const communitySection = (
    <CardContent>
      <Typography variant="h6" gutterBottom>
        Community
      </Typography>
      <CommunityNameField record={communityData} />
    </CardContent>
  );

  let directOuterGroupSection = <></>;
  const shouldShowDirectOuterGroup =
    parentData.id !== -1 && parentData.id !== communityData.group_id;
  if (shouldShowDirectOuterGroup) {
    directOuterGroupSection = (
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Direct outer group
        </Typography>
        <GroupNameField record={parentData} />
      </CardContent>
    );
  }

  return (
    <Box className={classes.root}>
      <Box m="0 0 1em 1em">
        <HistoryCard record={record} />
      </Box>
      <Box m="0 0 0 1em" minWidth={400}>
        <Card>
          {communitySection}
          {directOuterGroupSection}
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between">
              <Typography variant="h6" gutterBottom>
                Inner groups
              </Typography>
            </Box>
            {renderInnerGroups()}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Aside;
