import React, {useState} from 'react';
import {
  Button,
  EmailField,
  ImageField,
  SimpleShowLayout,
  TextField,
  useGetOne,
} from 'react-admin';
import {Box, CardContent, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import FullnameField from './FullnameField';
import {IUser} from '../interfaces/IUser';
import {BASEPATHS, RESOURCES} from '../common/constants';

const useStyles = makeStyles({
  field: {
    // These styles will ensure our drawer don't fully cover our
    // application when teaser or title are very long
    '& span': {
      display: 'inline-block',
      minWidth: '20rem',
      maxWidth: '24rem',
    },
  },
});

interface Props {
  id: number;
}

const UserQuickPreviewButton: React.FC<Props> = ({id}) => {
  const [showPanel, setShowPanel] = useState(false);
  const classes = useStyles();

  // Add unknown to fix if field created_at or any other field not exists
  const {data} = useGetOne(RESOURCES.USERS, id) as unknown as IUser;

  const onOpen = () => {
    setShowPanel(true);
  };

  const onClose = () => {
    setShowPanel(false);
  };

  return (
    <>
      <Button onClick={onOpen} label="ra.action.show">
        <IconImageEye />
      </Button>
      <Drawer anchor="right" open={showPanel} onClose={onClose}>
        <CardContent
          style={{
            paddingTop: 0,
            paddingLeft: 16,
          }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            paddingLeft="1rem">
            <FullnameField record={data} />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <SimpleShowLayout
            record={data}
            basePath={BASEPATHS.USERS}
            resource={RESOURCES.USERS}>
            <TextField source="id" className={classes.field} />
            <TextField source="fullname" className={classes.field} />
            <TextField source="teaser" className={classes.field} />
            <EmailField source="email" className={classes.field} />
            <TextField source="username" className={classes.field} />
            <TextField source="gender" className={classes.field} />
            <TextField source="birthday" className={classes.field} />
            <ImageField
              source="background_img_url"
              label="Background image"
              className={classes.field}
            />
            <TextField source="bein_staff_role" className={classes.field} />
            <TextField source="phone" className={classes.field} />
            <TextField source="address" className={classes.field} />
            <TextField source="language" className={classes.field} />
            <TextField source="relationship_status" className={classes.field} />
            <TextField source="description" className={classes.field} />
          </SimpleShowLayout>
        </CardContent>
      </Drawer>
    </>
  );
};

UserQuickPreviewButton.propTypes = {
  id: PropTypes.number.isRequired,
};

export default UserQuickPreviewButton;
