import React from 'react';
import {ReduxState} from 'react-admin';
import {useSelector} from 'react-redux';
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  icon: {minWidth: theme.spacing(4)},
  sidebarIsOpen: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(4),
    },
  },
  sidebarIsClosed: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(2),
    },
  },
}));

interface SubMenuProps {
  dense: boolean;
  handleToggle: () => void;
  icon: React.ReactElement;
  isOpen: boolean;
  name: string;
  children: React.ReactNode;
}

const SubMenu: React.FC<SubMenuProps> = (props: SubMenuProps) => {
  const {handleToggle, isOpen, name, icon, children, dense} = props;
  const classes = useStyles();
  const sidebarIsOpen = useSelector<ReduxState, boolean>(
    state => state.admin.ui.sidebarOpen,
  );

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  );

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default SubMenu;
