import {CognitoUser} from '@aws-amplify/auth';
import {Auth} from 'aws-amplify';
import {API_ADMIN_URL} from '../constants';
import httpClient from './httpClient';

export const getCurrentUser = (): Promise<
  CognitoUser & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
> => {
  return Auth.currentAuthenticatedUser();
};

/**
 * To check if user is bein staff
 * Which means the field custom:bein_staff_role must be in the decoded data
 */
export const isBicStaff = async (): Promise<boolean> => {
  const urlToGetAdminAuthorization = API_ADMIN_URL;
  const isBicStaff = await httpClient(urlToGetAdminAuthorization, {
    method: 'GET',
  }).then(json => {
    const hasPermissionToAccess = JSON.parse(json.body).data ?? false;
    return Promise.resolve(hasPermissionToAccess);
  });
  return isBicStaff;
};

export const getToken = async (): Promise<string> => {
  const currentUser = await getCurrentUser();
  return currentUser.getSignInUserSession()?.getIdToken().getJwtToken() ?? '';
};
