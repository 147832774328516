export enum CsvProcessStatus {
  STARTED = 'STARTED',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
}

export interface CsvUploadError {
  row_id: string;
  message: string;
}

export interface CsvUploadMessageResponse {
  upload_id: string;
  status: CsvProcessStatus;
  total_row: number;
  processed_row: number;
  processed_percent: number;
  error?: CsvUploadError;
  info?: {row_id: string; message: string};
}
