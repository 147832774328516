import GroupList from './GroupList';
import GroupShow from './GroupShow';
import GroupCreate from './GroupCreate';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

const resource = {
  list: GroupList,
  show: GroupShow,
  create: GroupCreate,
  icon: GroupIcon,
  iconAdd: GroupAddIcon,
};

export default resource;
