import React, { FC } from 'react';
import {
  EditButton,
  EmailField,
  ImageField,
  Show,
  ShowActionsProps,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useResourceDefinition,
  useShowContext,
} from 'react-admin';
import UserTitle from './UserTitle';
import Aside from './Aside';
import PhoneField from './PhoneField';
import ToggleDeactivateButton from '../components/Buttons/ToggleDeactivateButton';
import { makeStyles } from '@material-ui/core';
import { SwitchVerifyUser } from './SwitchVerifyUser';

const useStyles = makeStyles(() => {
  return {
    toolBar: {
      alignItems: 'center',
    },
  };
});

const ShowActions = (props: ShowActionsProps) => {
  const { basePath, record } = useShowContext(props);
  const { hasEdit } = useResourceDefinition(props);
  const classes = useStyles();

  return (
    <TopToolbar className={classes.toolBar}>
      <SwitchVerifyUser />
      <ToggleDeactivateButton />
      {hasEdit && <EditButton basePath={basePath} record={record} />}
    </TopToolbar>
  );
};

const UserShow: FC<ShowProps> = props => {
  return (
    <Show
      {...props}
      title={<UserTitle />}
      aside={<Aside />}
      actions={<ShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="rocket_chat_id" />
        <TextField source="cognito_uuid" />
        <TextField source="stream_uuid" />
        <EmailField source="email" />
        <TextField source="username" />
        <TextField source="fullname" />
        <TextField source="gender" />
        <TextField source="birthday" />
        <ImageField source="background_img_url" label="Background image" />
        <TextField source="bein_staff_role" />
        <PhoneField />
        <TextField source="address" />
        <TextField source="language" />
        <TextField source="relationship_status" />
        <TextField source="description" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
