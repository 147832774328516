import React, {useState} from 'react';
import {Button} from 'react-admin';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import CloseIcon from '@material-ui/icons/Close';
import {importType} from './common';
import ImportCard from './ImportCard';

const useStyles = makeStyles(() => {
  return {
    title: {
      m: 0,
      p: 2,
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
    },
  };
});

interface Props {
  type: importType;
}

const ButtonImportUsers = ({type}: Props): JSX.Element => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const dialogTitle = `Import ${type}`;

  const openImportDialog = () => {
    setShowDialog(true);
  };

  const closeImportDialog = () => {
    setShowDialog(false);
  };

  return (
    <React.Fragment>
      <Button label="import" onClick={openImportDialog}>
        <LibraryAddIcon />
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={showDialog}
        onClose={closeImportDialog}>
        <DialogTitle className={classes.title}>
          {dialogTitle}
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={closeImportDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ImportCard type={type} onSuccess={closeImportDialog} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ButtonImportUsers;
