import React from 'react';
import {FieldProps} from 'react-admin';
import PropTypes from 'prop-types';

import {IGroup} from '../interfaces/IGroup';
import CommunityNameField from './CommunityNameField';

const CommunityTitle: React.FC<FieldProps<IGroup>> = ({record}) => {
  if (!record) return null;

  return record ? <CommunityNameField record={record} size={32} /> : null;
};

CommunityTitle.propTypes = {
  record: PropTypes.any,
};

export default CommunityTitle;
