import React from 'react';
import {
  Datagrid,
  DateField,
  CreateButton,
  FunctionField,
  List,
  ListProps,
  Record,
  TextField,
  TopToolbar,
  TextInput,
} from 'react-admin';
import {Box, Typography} from '@material-ui/core';

import CommunityNameField from './CommunityNameField';
import {SortOrder} from '../common/constants';

const communityFilters = [
  <TextInput
    key="search"
    label="Search"
    placeholder="Enter community name"
    source="key"
    alwaysOn
    style={{width: 400}}
  />,
];

const CommunityListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const Empty = () => (
  <>
    <Box marginTop={2} marginBottom={2}>
      <Typography>
        No community in the database, to create new one, please press
        <span style={{marginRight: 8, marginLeft: 8}}>
          <CreateButton />
        </span>
      </Typography>
    </Box>
  </>
);

const CommunityList: React.FC<ListProps> = props => {
  const renderDescription = (record?: Record) => {
    if (!record?.description) return null;
    const desc = record.description;
    const limitChar = 50;

    return desc.length < limitChar ? desc : desc.substr(0, 50) + '...';
  };

  return (
    <List
      {...props}
      title="Community list"
      filters={communityFilters}
      empty={<Empty />}
      sort={{
        field: 'name',
        order: SortOrder.ASC,
      }}
      actions={<CommunityListActions />}>
      <Datagrid rowClick="show" size="medium">
        <CommunityNameField />
        <FunctionField label="Description" render={renderDescription} />
        <TextField source="user_count" sortable={false} />
        <TextField source="privacy" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Datagrid>
    </List>
  );
};

export default CommunityList;
