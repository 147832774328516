import React from 'react';
import {
  Button,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  required,
} from 'react-admin';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {
  Box,
  Card,
  CardContent,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {useCommon} from '../../hooks/common';
import actions from '../../store/common/actions';
import {useDispatch} from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import {BASE_DRAWER_WIDTH_HAVING_LIST} from '../../common/constants';
import JoinedGroupsTree from '../../users/JoinedGroupsTree';
import {dataProvider} from '../../providers';
import {Form} from 'react-final-form';

const useStyles = makeStyles(() => {
  return {
    root: {
      marginTop: '1em',
    },
  };
});

const ToggleDeactivateButton: React.FC = () => {
  const {showDeactivateUserDrawer} = useCommon();
  const record = useRecordContext();
  const dispatch = useDispatch();
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  if (!record) {
    return <></>;
  }

  const isDeactivatedUSer = record.is_deactivated;
  const title = `${record.is_deactivated ? 'Activate' : 'Deactivate'} Account`;

  const openDeactivateUserDrawer = () => {
    dispatch(actions.setShowDeactivateUserDrawer(true));
  };

  const closeDeactivateUserDrawer = () => {
    dispatch(actions.setShowDeactivateUserDrawer(false));
  };

  const handleSubmitDeactivate = async () => {
    await dataProvider
      .toggleDeactivateUser({
        userId: record.id,
        is_deactivated: !isDeactivatedUSer,
      })
      .then(data => {
        notify(data.meta.message, 'success');
        closeDeactivateUserDrawer();
        refresh();
      })
      .catch((err: Error) => {
        const errorMessage = `Error: ${err.message}`;
        notify(errorMessage, 'error');
      });
  };

  const validateUsername = [
    required(),
    (value: string) => {
      return value !== record.username
        ? 'The username is incorrect'
        : undefined;
    },
  ];

  return (
    <React.Fragment>
      <Button label={title} onClick={openDeactivateUserDrawer}>
        {isDeactivatedUSer ? <PersonAddIcon /> : <PersonAddDisabledIcon />}
      </Button>
      <Drawer
        anchor="right"
        open={showDeactivateUserDrawer}
        onClose={closeDeactivateUserDrawer}>
        <CardContent
          style={{
            minWidth: BASE_DRAWER_WIDTH_HAVING_LIST,
            padding: 16,
            paddingLeft: 24,
          }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h5">{`${title} User Account: ${record.fullname}`}</Typography>
            <IconButton onClick={closeDeactivateUserDrawer}>
              <CloseIcon />
            </IconButton>
          </Box>
          <JoinedGroupsTree />
          <Card className={classes.root}>
            <CardContent>
              {/**
               * WARNING: Do not use <SimpleForm>
               * It will reload the page on submitting the form, even if the `redirect` prop
               * is set to `false`
               */}
              <Form
                onSubmit={handleSubmitDeactivate}
                render={({handleSubmit, invalid}) => (
                  <form onSubmit={handleSubmit}>
                    <Box padding={1}>
                      <Typography gutterBottom>
                        Please confirm by typing the username to deactivate the
                        account
                      </Typography>
                      <TextInput
                        source="username"
                        validate={validateUsername}
                      />
                    </Box>
                    <Box padding={1}>
                      <Button
                        label={title}
                        variant="contained"
                        type="submit"
                        color={isDeactivatedUSer ? 'primary' : 'secondary'}
                        disabled={invalid}
                      />
                    </Box>
                  </form>
                )}
              />
            </CardContent>
          </Card>
        </CardContent>
      </Drawer>
    </React.Fragment>
  );
};

export default ToggleDeactivateButton;
