import {IReactAdminFile} from '../../interfaces/common';
import {httpClient} from '../../utils';
import {API_GROUP_URL} from '../../constants';

export type UploadPhotoType =
  | 'default'
  | 'user-avatar'
  | 'user-cover'
  | 'group-avatar'
  | 'group-cover';

export type UploadFileProvider = {
  uploadPhoto: (
    photo: IReactAdminFile,
    type?: UploadPhotoType,
  ) => Promise<string>;
};

export const getPhotoUrl = async (
  photo: IReactAdminFile | string | null,
  fallbackUrl = '',
  type: UploadPhotoType = 'default',
): Promise<string> => {
  if (typeof photo === 'string' || !photo) {
    return fallbackUrl;
  }

  return await uploadFileProvider.uploadPhoto(photo, type);
};

const uploadFileProvider: UploadFileProvider = {
  uploadPhoto: (file: IReactAdminFile, type: UploadPhotoType = 'default') => {
    let url = `${API_GROUP_URL}files/upload-photos`;
    if (type !== 'default') url = `${API_GROUP_URL}upload/${type}`;

    if (!file.rawFile) return Promise.reject('No file to upload');

    const formData = new FormData();
    formData.append('file', file.rawFile, file.src);

    /**
     * Not put new header {Content-Type': 'multipart/form-data'}
     * as the browser auto generate it with the formData
     */
    return httpClient(url, {
      method: 'POST',
      body: formData,
    })
      .then(({json}) => {
        return Promise.resolve(json.data.src);
      })
      .catch(err => {
        return Promise.reject(err.body);
      });
  },
};

export default uploadFileProvider;
